import axiosInstance, {setupAxiosInterceptors} from "../services/axiosInstance";
import {createContext, useContext, useEffect, useMemo, useState} from "react";
import Loading from "../components/utility/Loading";

const AuthContext = createContext();

const AuthProvider = ({children}) => {
    const [token, setToken_] = useState(null);
    const [loading, setLoading] = useState(true);
    const [refreshTokenExpired, setRefreshTokenExpired] = useState(() => {
        return JSON.parse(localStorage.getItem('refreshTokenExpired') || 'false');
    });

    const setToken = (newToken) => {
        setToken_(newToken);
        if (newToken) {
            axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + newToken;
        } else {
            delete axiosInstance.defaults.headers.common["Authorization"];
        }
    };

    const setRefreshTokenExpiredWithStorage = (value) => {
        setRefreshTokenExpired(value);
        localStorage.setItem('refreshTokenExpired', JSON.stringify(value));
    };

    const refreshAccessToken = async () => {
        try {
            const response = await axiosInstance.post("/auth/refresh/", {}, {withCredentials: true});
            const newAccessToken = response.data.access;
            setToken(newAccessToken);
            setRefreshTokenExpiredWithStorage(false);
            return newAccessToken;
        } catch (error) {
            console.error("Error while refreshing token: ", error.message);
            setToken(null);
            setRefreshTokenExpiredWithStorage(true);
            return null;
        }
    };

    useEffect(() => {
        setupAxiosInterceptors(token, refreshAccessToken, setToken, setRefreshTokenExpiredWithStorage);
    }, [token]);

    useEffect(() => {
        const initializeAuth = async () => {
            if (!token && !refreshTokenExpired) {
                await refreshAccessToken();
            }
            setLoading(false);
        };

        initializeAuth();
    }, []);

    const contextValue = useMemo(
        () => ({
            token,
            setToken,
            refreshAccessToken,
            refreshTokenExpired,
            setRefreshTokenExpiredWithStorage
        }),
        [token, refreshTokenExpired]
    );

    if (loading) {
        return <Loading/>;
    }

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;
