import Layout from "../../components/layout/Tagesreport/Tagesreport";
import { Box, Container } from "@mui/material";
import { TitleBar } from "../../components/layout/TitleBar";
import React, { useEffect, useState } from "react";
import VerkaufsgebietFilterSelection from "../../components/tagesreport/VerkaufsgebietFilterSelection";
import CircularProgress from "@mui/material/CircularProgress";
import dayjs from "dayjs";
import axiosInstance from "../../services/axiosInstance";
import VariableTable from "../../components/table/VariableTable";
import { useTheme } from '@mui/material/styles';
import PageHeader from '../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';
import BigTableCard from "../../components/card/BigTableCard";

const Kassendifferenzen = () => {
    const theme = useTheme();
    const [datum, setDatum] = useState(dayjs().subtract(1, 'day').format('YYYY-MM-DD'));  // Aktuelles Datum
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [verkaufsgebietOptions, setVerkaufsgebietOptions] = useState([]);
    const [verkaufsgebietFilter, setVerkaufsgebietFilter] = useState(null);

    // Die letzten 3 Tage ermitteln
const getLastThreeDays = () => {
    return [0, 1, 2]
        .map(offset => dayjs(datum).subtract(offset, 'day').format('YYYY-MM-DD'))
        .reverse();  // Umkehren, damit das älteste Datum links ist
};


    const lastThreeDays = getLastThreeDays();

    useEffect(() => {
        fetchVerkaufsgebietOptions();
    }, []);

    useEffect(() => {
        fetchData();
    }, [datum, verkaufsgebietFilter]);

    const fetchVerkaufsgebietOptions = async () => {
        try {
            const response = await axiosInstance.get('/stammdaten/unique-verkaufsgebiet/');
            setVerkaufsgebietOptions(response.data.map(item => ({ label: item, value: item })));
        } catch (error) {
            console.error('Error while fetching VerkaufsgebietOptions', error);
        }
    };

    const fetchData = async () => {
        try {
            setLoading(true);
    
            // Hole Kassendifferenzen für die letzten 20 Tage
            const kassendifferenzenResponses = await Promise.all(
                lastThreeDays.map(date => 
                    axiosInstance.get("/facts_filiale/kassendifferenzen/", {
                        params: {
                            datum: date,
                            verkaufsgebiet: verkaufsgebietFilter?.value,
                        },
                    })
                )
            );
    
            // Verarbeite die Daten in einem einzigen Array
            const filialeMap = {};
    
            kassendifferenzenResponses.forEach((response, index) => {
                const day = lastThreeDays[index];
    
                // console.log(`Response für ${day}:`, response.data); // Überprüfe, was zurückkommt
    
                if (!Array.isArray(response.data.results)) {
                    console.warn(`Keine Daten für den Tag ${day} erhalten`);
                    return;  // Wenn response.data.results kein Array ist, ignoriere diesen Tag
                }
    
                response.data.results.forEach(item => {
                    const filiale = item.filiale;
                    const differenz = (item.positivBetrag || 0) - (item.negativBetrag || 0);
    
                    if (!filialeMap[filiale]) {
                        filialeMap[filiale] = { Filiale: filiale, Gesamt: 0 };
                    }
    
                    filialeMap[filiale][day] = differenz;
                    filialeMap[filiale].Gesamt += differenz; // Zeilensumme (Gesamtwert pro Filiale)
                });
            });
    
            setData(Object.values(filialeMap));  // Konvertiere Map zu Array
        } catch (error) {
            console.error('Error while fetching Kassendifferenzen:', error);
        } finally {
            setLoading(false);
        }
    };
    
    const columns = [
        {
            accessorKey: 'Filiale',
            header: 'Filiale',
            Footer: 'Gesamt',
            size: 200,
        },
        ...lastThreeDays.map(day => ({
            accessorKey: day,
            header: dayjs(day).format('dddd, D. MMMM YYYY'),
            size: 150,
            Footer: ({ column, table }) => {
                // Berechnen der Summe für diese Spalte
                const total = table.getFilteredRowModel().rows.reduce(
                    (sum, row) => sum + (parseFloat(row.getValue(column.id)) || 0),
                    0
                );
                return total.toFixed(2); // Formatierung auf zwei Dezimalstellen
            },
        })),
        {
            accessorKey: 'Gesamt',
            header: 'Gesamt',
            size: 150,
            Cell: ({ cell }) => {
                return parseFloat(cell.getValue()).toFixed(2);
            },
            Footer: ({ column, table }) => {
                const total = table.getFilteredRowModel().rows.reduce(
                    (sum, row) => sum + (parseFloat(row.getValue(column.id)) || 0),
                    0
                );
                return total.toFixed(2);
            },
        }
        
    ];
    
    
    
    

    return (
        <Layout>
            <Container maxWidth={false} sx={{ padding: '0 16px', width: '100%', display: 'flex', flexDirection: 'column' }}>
                <PageHeader
                    title={`Kassendifferenzen vom ${dayjs(datum).format('dddd, D. MMMM YYYY')}`}
                    subtitle="Übersicht über die Kassendifferenzen der letzten 3 Tage."
                    Icon={BarChartIcon}
                />
                <br />
                <VerkaufsgebietFilterSelection
                    verkaufsgebietFilter={verkaufsgebietFilter}
                    setVerkaufsgebietFilter={setVerkaufsgebietFilter}
                    verkaufsgebietOptions={verkaufsgebietOptions}
                />
                <br />
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <CircularProgress size={80} />
                    </div>
                ) : (
                    <Box sx={{ flexGrow: 1, width: '100%' }}>
                        <BigTableCard 
                            columns={columns} 
                            data={data} 
                            sx={{ width: '100%' }} 
                        />
                    </Box>
                )}
            </Container>
        </Layout>
    );
};

export default Kassendifferenzen;
