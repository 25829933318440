import * as React from 'react';
import {Container, Typography, Box, AppBar, Toolbar} from '@mui/material';
import Layout from '../../../../components/layout/Scorecards/FilialeMonat';

const WareA = () => {
    return (
        <Layout>
            <div>
                Woche
            </div>
            <Container maxWidth="sm">
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                            Ware Retoure A
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mt: 4,
                    }}
                >
                    <Typography variant="h3" component="div" gutterBottom>
                        Performance Kunden
                    </Typography>
                </Box>
            </Container>
        </Layout>
    );
}

export default WareA;
