import React from 'react';
import { Box, Typography, Grid, Card, CardActionArea, Badge } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BarChartIcon from '@mui/icons-material/BarChart';
import ChecklistIcon from '@mui/icons-material/Checklist';
import CalendarTodayIcon from '@mui/icons-material/Today';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import logoHalf from '../../assets/images/logo_half.png'; // Importiere das Bild
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';

const Module = () => {
  const navigate = useNavigate();

  const apps = [
    { icon: <AnalyticsOutlinedIcon fontSize="large" sx={{ color: 'primary.main' }} />, label: 'Scorecards', path: '/scorecards/filiale/monat/overview', category: 'Analytics' },
    { icon: <BarChartIcon fontSize="large" sx={{ color: 'primary.main' }} />, label: 'Unternehmen', path: '/scorecardsunternehmen/monat/cockpit', category: 'Analytics' },
    { icon: <SummarizeOutlinedIcon fontSize="large" sx={{ color: 'primary.main' }} />, label: 'Dokumentation', path: '/scorecardsdocumentation/scorecardsdocumentation', category: 'Quality' },
    { icon: <CalendarTodayIcon fontSize="large" sx={{ color: 'primary.main' }} />, label: 'Tagesreport', path: '/tagesreport/main', category: 'Analytics' },
    { icon: <ChecklistIcon fontSize="large" sx={{ color: 'primary.main' }} />, label: 'Filialcheck', path: '/filialcheck/filialcheckübersicht', category: 'Quality' },
    { icon: <StorefrontOutlinedIcon fontSize="large" sx={{ color: 'primary.main' }} />, label: 'Sortiment', path: '/sortiment/theke', category: 'Quality' }

  ];

  // Gruppiere die Apps nach Kategorien
  const categorizedApps = apps.reduce((acc, app) => {
    const { category } = app;
    if (!acc[category]) acc[category] = [];
    acc[category].push(app);
    return acc;
  }, {});

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100%'
      }}
    >
      {/* Füge das Bild über dem Titel hinzu */}
      <Box component="img" src={logoHalf} alt="Logo" sx={{ mb: 2, height: 100 }} />

      <Typography variant="h1" gutterBottom>
        Insights
      </Typography>

      {/* Zeige jede Kategorie mit ihren Apps an */}
      {Object.keys(categorizedApps).map((category) => (
        <Box key={category} sx={{ width: '100%', mt: 4 }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ textAlign: 'center' }} // Überschrift zentrieren
          >
            {category}
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {categorizedApps[category].map((app) => (
              <Grid item key={app.label}>
                <Card sx={{ width: 150, height: 150 }}>
                  <CardActionArea
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%'
                    }}
                    onClick={() => navigate(app.path)}
                  >
                    <Badge badgeContent={app.badge} sx={{ color: 'primary.main' }} invisible={!app.badge}>
                      {app.icon}
                    </Badge>
                    <Typography variant="subtitle1" sx={{ marginTop: 1 }}>
                      {app.label}
                    </Typography>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default Module;
