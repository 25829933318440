import {useEffect, useRef, useState} from "react";
import {List, ListItem, Typography} from "@mui/material";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import DraggableText from "./DraggableText";


const ArtikelListe = ({ artikel }) => {
    const [displayedArtikel, setDisplayedArtikel] = useState([]);
    const [page, setPage] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const listRef = useRef(null);

    // TODO this does not work correctly yet, no more are loaded in
    const artikelPerPage = 20;

    useEffect(() => {
        const filteredArtikel = artikel.filter(item =>
            item.artikelbezeichnung.toLowerCase().includes(searchTerm.toLowerCase())
        );
        const sortedArtikel = [...filteredArtikel].sort((a, b) => b.umsatz - a.umsatz);
        setDisplayedArtikel(sortedArtikel.slice(0, artikelPerPage));
        setPage(0);
    }, [artikel, searchTerm]);

    const loadMoreArtikel = () => {
        const nextPage = page + 1;
        const startIndex = nextPage * artikelPerPage;
        const endIndex = startIndex + artikelPerPage;
        const filteredArtikel = artikel.filter(item =>
            item.artikelbezeichnung.toLowerCase().includes(searchTerm.toLowerCase())
        );
        const sortedArtikel = [...filteredArtikel].sort((a, b) => b.umsatz - a.umsatz);
        const newArtikel = sortedArtikel.slice(startIndex, endIndex);

        setDisplayedArtikel(prevArtikel => [...prevArtikel, ...newArtikel]);
        setPage(nextPage);
    };

    const handleScroll = () => {
        if (listRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                loadMoreArtikel();
            }
        }
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <Paper
            elevation={3}
            sx={{ maxHeight: 800, overflow: 'auto', padding: 2, margin: 2 }}
            ref={listRef}
            onScroll={handleScroll}
        >
            <Typography variant="h5" gutterBottom>
                Artikel
            </Typography>
            <TextField
                fullWidth
                variant="outlined"
                placeholder="Artikel suchen"
                value={searchTerm}
                onChange={handleSearchChange}
                sx={{ mb: 1, width: '95%', height: '40px' }}
                InputProps={{ sx: { height: '100%' } }}
            />
            {displayedArtikel.length > 0 ? (
                <List>
                    {displayedArtikel.map((artikel, index) => (
                        <ListItem key={`${artikel.artikelbezeichnung || artikel}-${index}`}>
                            <DraggableText
                                key={artikel.artikelbezeichnung || artikel}
                                artikel={artikel}
                            />
                        </ListItem>
                    ))}
                </List>
            ) : (
                <Typography variant="body1" align="center">
                    keine Artikel gefunden
                </Typography>
            )}
        </Paper>
    );
};

export default ArtikelListe;