import React, { useState, useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { TextField, Box, Dialog, DialogTitle, DialogContent, IconButton, Divider } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';

const VariableTable = ({ columns, data, onRowClick, muiTableBodyCellProps }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [columnVisibility, setColumnVisibility] = useState(
        columns.reduce((acc, column) => {
            acc[column.accessorKey] = true; // All columns are visible by default
            return acc;
        }, {})
    );

    const filteredData = useMemo(() => {
        return data.filter(row => 
            columns.some(column => 
                String(row[column.accessorKey])
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
            )
        );
    }, [data, columns, searchTerm]);

    const memoizedColumns = useMemo(() => 
        columns.map(column => ({
            ...column,
            isVisible: columnVisibility[column.accessorKey],
        }))
    , [columns, columnVisibility]);

    const toggleFullscreen = () => {
        setIsFullscreen(prev => !prev);
    };

    const handleColumnToggle = (accessorKey) => {
        setColumnVisibility(prev => ({
            ...prev,
            [accessorKey]: !prev[accessorKey],
        }));
    };

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div className="db_styled_table">
            {/* Search Field and Controls */}
            <Box    >
                <Box 
                    display="flex" 
                    justifyContent="space-between" 
                    alignItems="center" 
                    mb={0.1} // Reduced margin to decrease space
                >
<Box sx={{ width: '200px' }}>
    <TextField
        fullWidth
        label="Suchen"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        size="small" // Set the size to small for a more compact look
        sx={{
            '& .MuiInputBase-root': {
                height: '30px', // Adjust this value for a lower height
            },
            '& .MuiInputLabel-root': {
                fontSize: '0.875rem', // Optional: Adjust the label font size
                top: '-6px', // Optional: Adjust the label position if needed
            },
        }}
    />
</Box>


                    <IconButton onClick={toggleFullscreen}>
                        <FullscreenIcon />
                    </IconButton>
                </Box>
            </Box>






            <MaterialReactTable
                columns={memoizedColumns}
                data={filteredData} // Use filteredData instead of undefined memoizedData
                enableColumnResizing
                enableStickyHeader
                enableStickyFooter
                enableColumnFooter={true}
                layoutMode="grid-no-grow"
                muiTableContainerProps={{ sx: { maxHeight: 500 } }}
                initialState={{
                    density: 'compact',
                    pagination: { 
                        pageIndex: 0, 
                        pageSize: 75 
                    },
                }}
                manualPagination={filteredData.length > 75}
                enablePagination={filteredData.length > 75}
                muiTableBodyProps={{
                    sx: {
                        '& > tr:first-of-type': {
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            fontWeight: 'bold',
                        },
                    },
                }}
                muiTableBodyCellProps={muiTableBodyCellProps}
                muiTableBodyRowProps={({ row }) => ({
                    onClick: () => onRowClick(row.original),
                    sx: {
                        cursor: 'pointer',
                    },
                })}
                enableToolbarInternalActions={false} // Disable internal toolbar actions like column visibility, density, etc.
                enableGlobalFilter={false} // Disable global search filter
                enableSorting // Enable sorting toolbar
                enableColumnActions={false} // Disable column-related actions
                enableColumnFilters={false} // Disable column filters
                enableTopToolbar={false} // Disable the top toolbar entirely
                enableBottomToolbar={false} // Disable the bottom toolbar entirely
            />

            {/* Fullscreen Dialog */}
            <Dialog open={isFullscreen} onClose={toggleFullscreen} maxWidth="xl" fullWidth>
                <DialogTitle>
                    {/* Vollbild */}
                    <IconButton
                        aria-label="close"
                        onClick={toggleFullscreen}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                {/* <DialogContent dividers> */}
                    {/* Search Field in Dialog */}

                        <Box 
                            display="flex" 
                            justifyContent="space-between" 
                            alignItems="center" 
                            mb={1} // Reduced margin to decrease space
                        >
                            <Box sx={{ width: '150px' }}>
                                <TextField
                                    fullWidth
                                    label="Suchen"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    size="small" // Match the size of the search field with the original one
                                />
                            </Box>
                        </Box>

                    <MaterialReactTable
                        columns={memoizedColumns.filter(column => column.isVisible)}
                        data={filteredData}
                        enableColumnResizing
                        enableStickyHeader
                        enableStickyFooter
                        enableColumnFooter={true}
                        layoutMode="grid-no-grow"
                        muiTableContainerProps={{ sx: { maxHeight: '80vh' } }}
                        initialState={{
                            density: 'compact',
                            pagination: { 
                                pageIndex: 0, 
                                pageSize: 75 
                            },
                        }}
                        enableToolbarInternalActions={false} // Disable internal toolbar actions like column visibility, density, etc.
                        enableGlobalFilter={false} // Disable global search filter
                        enableSorting // Enable sorting toolbar
                        enableColumnActions={false} // Disable column-related actions
                        enableColumnFilters={false} // Disable column filters
                        enableTopToolbar={false} // Disable the top toolbar entirely
                        enableBottomToolbar={false} // Disable the bottom toolbar entirely
                        muiTableBodyProps={{
                            sx: {
                                '& > tr:first-of-type': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                    fontWeight: 'bold',
                                },
                            },
                        }}
                        muiTableBodyCellProps={muiTableBodyCellProps}
                        muiTableBodyRowProps={({ row }) => ({
                            onClick: () => onRowClick(row.original),
                            sx: {
                                cursor: 'pointer',
                            },
                        })}
                    />
                {/* </DialogContent> */}
            </Dialog>
        </div>
    );
};

export default VariableTable;
