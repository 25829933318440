import {Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import Button from "@mui/material/Button";

const ArtikelDetailPopup = ({ open, onClose, artikel }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Artikel Details</DialogTitle>
            <DialogContent>
                {artikel ?
                <Typography>{artikel.artikelbezeichnung}</Typography>
                : null}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ArtikelDetailPopup;