import {Box} from "@mui/material";
import ThekeGroup from "./ThekeGroup";


const ThekeOverview = ({ warengruppen = [], sectionCounts, handleDrop, selectedArtikel = [] }) => {
    // console.log("thekover warengruppe", warengruppen)
    // console.log("theke overview selectedArtikel", selectedArtikel)
    // console.log("theke overview sectionCounts", sectionCounts)
    // Check if warengruppen is defined and has items
    if (!warengruppen.length) {
        return <Box>No items available.</Box>; // Optional: Display a message if no items
    }

    return (
        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 3, width: '100%' }}>
            {warengruppen.map((warengruppe) => {
                const sectionCount = sectionCounts[warengruppe.warengruppennummer] || 3; // use slot_count from the sample data
                // console.log("overview warengruppe", warengruppe)
                return (
                    <Box
                        key={warengruppe.warengruppennummer}
                        sx={{
                            padding: 0,
                        }}
                    >
                        <ThekeGroup
                            title={warengruppe.warengruppe}
                            sectionCount={sectionCount}
                            onDrop={handleDrop ? artikelData => handleDrop(warengruppe.warengruppennummer, artikelData) : undefined}
                            selectedArtikel={selectedArtikel.find(
                                wg => wg.id === warengruppe.warengruppennummer,
                            )?.artikel || []}
                        />
                    </Box>
                );
            })}
        </Box>
    );
};

export default ThekeOverview;
