import ProtectedRoute from "./ProtectedRoute";

const importAll = (r, prefix) => r.keys().map((key) => ({key, module: r(key).default, prefix}));

// Import all components from specific subfolders
// importAll and require.context are webpack specific functions
const scorecardsComponents = importAll(require.context('../pages/scorecards', true, /\.jsx$/), 'scorecards');
const scorecardsunternehmenComponents = importAll(require.context('../pages/scorecardsunternehmen', true, /\.jsx$/), 'scorecardsunternehmen');
const experimentalComponents = importAll(require.context('../pages/development', true, /\.jsx$/),);
const tagesreportComponents = importAll(require.context('../pages/tagesreport', true, /\.jsx$/), 'tagesreport');
const filialcheckComponents = importAll(require.context('../pages/filialcheck', true, /\.jsx$/), 'filialcheck');
const scorecardsdocumentationComponents = importAll(require.context('../pages/scorecardsdocumentation', true, /\.jsx$/), 'scorecardsdocumentation');
const sortimentComponents = importAll(require.context('../pages/sortiment', true, /\.jsx$/), 'sortiment');




// Combine all imported components
const components = [...scorecardsComponents, ...experimentalComponents, ...tagesreportComponents, ...filialcheckComponents, ...sortimentComponents,...scorecardsunternehmenComponents, ...scorecardsdocumentationComponents];

const generateRoutes = (components) => {
    return components.map(({key, module: Component, prefix}) => {
        const path = key
            .replace('../pages', '') // Remove the base path
            .replace(/^\./, '')
            .replace(/\.jsx$/, '') // Remove the file extension
            .replace(/\[([^\]]+)]/g, ':$1') // Handle dynamic segments
            .replace(/([^\/])([A-Z])/g, '$1-$2')
            .toLowerCase(); // Convert to lowercase

        // if prefix provide, add it. e.g. /prefix/...
        const fullPath = prefix ? `/${prefix}${path}` : path;

        // Wrap the component in a ProtectedRoute module for permission is the prefix
        const protectedComponent = () => {
            return (
                <ProtectedRoute module={prefix}>
                    <Component/>
                </ProtectedRoute>
            );
        }

        return {
            path: fullPath,
            component: protectedComponent,
        }
    });
};

// Generate and export routes
export const routes = generateRoutes(components);
