import React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material"; // Importieren für leere Datenanzeige

const PieChartFilialtyp = ({ data, metric, height, width }) => {
  const theme = useTheme();

  const COLORS = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.chart.yellow,
    theme.palette.chart.orange,
    theme.palette.chart.red,
    theme.palette.chart.pink,
    theme.palette.chart.purpleLight,
    theme.palette.chart.purpleDark,
  ];

  const sumField = `${metric}Summe`;

  // Loggen der Daten
  console.log('PieChartFilialtyp - data:', data);
  console.log('PieChartFilialtyp - metric:', metric);

  const totalValue = data.reduce((acc, item) => acc + (item[sumField] || 0), 0);

  console.log('PieChartFilialtyp - totalValue:', totalValue);

  if (totalValue <= 0) {
    console.warn('PieChartFilialtyp - Gesamtwert ist 0 oder negativ. Das Diagramm wird nicht angezeigt.');
    return (
      <Box sx={{ textAlign: 'center', padding: 2 }}>
        <Typography variant="body1">Keine Daten zum Anzeigen verfügbar.</Typography>
      </Box>
    );
  }

  const chartData = data.map((item, index) => {
    const value = item[sumField] || 0; // Korrigiert von item[metric] zu item[sumField]
    const percentage = ((value / totalValue) * 100).toFixed(2);

    return {
      id: `Filialtyp ${item.filialtyp}`,
      value: value,
      label: `${item.filialtyp}: ${percentage}%`,
      color: COLORS[index % COLORS.length],
    };
  });

  console.log('PieChartFilialtyp - chartData:', chartData);

  return (
    <PieChart
      series={[
        {
          data: chartData,
          innerRadius: 60,
          outerRadius: 100,
          label: {
            visible: true,
            position: 'outside',
            connector: true,
            connectorOffset: 10,
            fontSize: 10,
            fontWeight: 'normal',
          },
        },
      ]}
      width={width || 250}
      height={height || 220}
      margin={{ top: 10, bottom: 10, left: 10, right: 10 }}
      slotProps={{ legend: { hidden: true } }}
    />
  );
};

export default PieChartFilialtyp;
