import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Box, Grid, Typography, Stack, Card, Paper, CardHeader, CardContent } from '@mui/material';
import Layout from '../../../components/layout/Scorecards/Unternehmen/UnternehmenMonat';
import DataCard from '../../../components/card/scorecardsmonat/DataCardMonatPage';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import VariableTableTree from '../../../components/table/VariableTableTree';
import BigChartCard from "../../../components/card/scorecardsmonat/BigChartCardUnternehmen";
import { useTheme } from '@mui/material/styles'; // Importiere das Thema

const Krankenquote = () => {
    const { filterState } = useContext(FilterContext);
    const [datumOptions, setDatumOptions] = useState([]);
    const theme = useTheme(); // Zugriff auf das Theme
    const [personalData, setPersonalData] = useState({
        krankenquote: [],
        fluktuation: [],
    });
    const [tableData, setTableData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [salesAreaData, setSalesAreaData] = useState([]); // Neuer State für die Krankenquote pro Verkaufsgebiet
    const [lineChartData, setLineChartData] = useState([]); // Daten für das Liniendiagramm der Verkaufsgebiete

    useEffect(() => {
        fetchDatumOptions();
    }, []);

    useEffect(() => {
        if (filterState.datum) {
            fetchPersonalData(); // Krankenquote- und Fluktuationsdaten abrufen
            fetchTableData(); // Daten für die Tabelle abrufen
        }
    }, [filterState]);

    const fetchDatumOptions = async () => {
        try {
            const response = await axiosInstance.get('/stammdaten/unique-jahr-monat/');
            setDatumOptions(response.data.map(item => ({ label: item, value: item })));
        } catch (error) {
            console.error('Error while fetching datum options:', error);
        }
    };

    // Helper function to calculate the last 6 months based on the selected date
    const getLast6Months = (currentMonth) => {
        const last6Months = [];
        const formattedMonth = dayjs(currentMonth, 'YYYY.MM'); // Das gefilterte Datum korrekt formatieren

        for (let i = 0; i < 6; i++) {
            last6Months.push(formattedMonth.subtract(i, 'month').format('YYYY.MM')); // Rückwirkend vom gefilterten Monat die letzten 6 Monate berechnen
        }

        return last6Months.reverse(); // Optional, um die Monate in chronologischer Reihenfolge anzuzeigen (älteste zuerst)
    };

    const fetchPersonalData = async () => {
        const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM'); // Verwende das gefilterte Datum
        const last6Months = getLast6Months(currentMonth); // Letzten 6 Monate berechnen

        try {
            const responses = await Promise.all(
                last6Months.map(month => axiosInstance.get('/scorecards_month/personal-kennzahl/', {
                    params: { jahrMonat: month },
                }))
            );

            // Kumulieren der Krankenquote-Daten
            const krankenquoteData = responses.map((response, index) => {
                const data = response.data.results;

                const arbeitszeitKrankenqoute = data.reduce((sum, item) => sum + (item.arbeitszeitKrankenqoute || 0), 0);
                const krankenzeit = data.reduce((sum, item) => sum + (item.krankenzeit || 0), 0);
                const arbeitszeitKrankenqouteVM = data.reduce((sum, item) => sum + (item.arbeitszeitKrankenqouteVM || 0), 0);
                const krankenzeitVM = data.reduce((sum, item) => sum + (item.krankenzeitVM || 0), 0);
                const arbeitszeitKrankenqouteVJ = data.reduce((sum, item) => sum + (item.arbeitszeitKrankenqouteVJ || 0), 0);
                const krankenzeitVJ = data.reduce((sum, item) => sum + (item.krankenzeitVJ || 0), 0);

                // Berechne die Krankenquote
                const krankenquote = arbeitszeitKrankenqoute > 0 ? (krankenzeit / arbeitszeitKrankenqoute) * 100 : 0;
                const krankenquoteVM = arbeitszeitKrankenqouteVM > 0 ? (krankenzeitVM / arbeitszeitKrankenqouteVM) * 100 : 0;
                const krankenquoteVJ = arbeitszeitKrankenqouteVJ > 0 ? (krankenzeitVJ / arbeitszeitKrankenqouteVJ) * 100 : 0;

                return {
                    month: last6Months[index],
                    arbeitszeitKrankenqoute,
                    krankenzeit,
                    krankenquote,
                    krankenquoteVM,
                    krankenquoteVJ,
                };
            });

            // Kumulieren der Fluktuation-Daten (wie in der funktionierenden Seite)
            const fluktuationData = responses.map((response, index) => {
                const data = response.data.results;

                const austritte6M = data.reduce((sum, item) => sum + (item.austritte6M || 0), 0);
                const mitarbeiterbestand6M = data.reduce((sum, item) => sum + (item.mitarbeiterbestand6M || 0), 0);
                const eintritte6M = data.reduce((sum, item) => sum + (item.eintritte6M || 0), 0);
                const köpfeDifferenz = data.reduce((sum, item) => sum + (item.köpfeDifferenz || 0), 0);
                const stundenDifferenz = data.reduce((sum, item) => sum + (item.stundenDifferenz || 0), 0);
                const überstundenAbs = data.reduce((sum, item) => sum + (item.überstundenAbs || 0), 0);

                // Berechne die Fluktuation
                const fluktuation = (mitarbeiterbestand6M + eintritte6M) > 0
                    ? (austritte6M / (mitarbeiterbestand6M + eintritte6M)) * 100
                    : 0;

                // Eintritte und Austritte im gefilterten Monat
                const eintritteM = data.reduce((sum, item) => sum + (item.eintritteM || 0), 0);
                const austritteM = data.reduce((sum, item) => sum + (item.austritteM || 0), 0);

                return {
                    month: last6Months[index],
                    fluktuation,
                    eintritteM,
                    austritteM,
                    köpfeDifferenz,
                    stundenDifferenz,
                    überstundenAbs,
                };
            });

            // Berechnung der Krankenquote pro Verkaufsgebiet
            const salesAreaData = last6Months.map((month, index) => {
                const data = responses[index].data.results;

                // Gruppiere die Daten nach Verkaufsgebiet
                const salesAreaGroup = {};

                data.forEach(item => {
                    const salesArea = item.verkaufsgebiet;
                    if (!salesAreaGroup[salesArea]) {
                        salesAreaGroup[salesArea] = {
                            arbeitszeitKrankenqoute: 0,
                            krankenzeit: 0,
                        };
                    }
                    salesAreaGroup[salesArea].arbeitszeitKrankenqoute += item.arbeitszeitKrankenqoute || 0;
                    salesAreaGroup[salesArea].krankenzeit += item.krankenzeit || 0;
                });

                // Berechne die Krankenquote pro Verkaufsgebiet
                const salesAreaRates = {};
                Object.keys(salesAreaGroup).forEach(salesArea => {
                    const arbeitszeitKrankenqoute = salesAreaGroup[salesArea].arbeitszeitKrankenqoute;
                    const krankenzeit = salesAreaGroup[salesArea].krankenzeit;
                    const krankenquote = arbeitszeitKrankenqoute > 0 ? (krankenzeit / arbeitszeitKrankenqoute) * 100 : 0;
                    salesAreaRates[salesArea] = {
                        arbeitszeitKrankenqoute,
                        krankenzeit,
                        krankenquote,
                    };
                });

                return {
                    month,
                    salesAreaRates,
                };
            });

            setPersonalData({
                krankenquote: krankenquoteData,
                fluktuation: fluktuationData,
            });

            setSalesAreaData(salesAreaData); // Setze die Daten für die Krankenquote pro Verkaufsgebiet

            // Daten für das Liniendiagramm vorbereiten
            // Erstelle für jedes Verkaufsgebiet eine Datenserie
            const salesAreas = new Set();
            salesAreaData.forEach(monthData => {
                Object.keys(monthData.salesAreaRates).forEach(salesArea => {
                    salesAreas.add(salesArea);
                });
            });

            const lineChartData = Array.from(salesAreas).map(salesArea => {
                return {
                    id: salesArea,
                    data: salesAreaData.map(monthData => {
                        const krankenquote = monthData.salesAreaRates[salesArea]
                            ? monthData.salesAreaRates[salesArea].krankenquote
                            : null; // Falls keine Daten vorhanden sind
                        return {
                            x: monthData.month,
                            y: krankenquote,
                        };
                    }),
                };
            });

            setLineChartData(lineChartData); // Setze die Daten für das Liniendiagramm

            // Daten für das Chart vorbereiten (gesamt)
            const chartData = krankenquoteData.filter(item => item.month).flatMap(item => [
                { x: item.month, y: item.krankenquote, label: 'Krankenquote' },
                { x: item.month, y: item.krankenquoteVJ, label: 'Vorjahr' },
            ]);

            setChartData(chartData);

        } catch (error) {
            console.error('Error fetching personal data:', error);
        }
    };

    const fetchTableData = async () => {
        const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM');

        try {
            const response = await axiosInstance.get('/scorecards_month/personal-kennzahl/', {
                params: { jahrMonat: currentMonth },
            });

            const data = response.data.results;

            // Tabelle formatieren
            const formattedTableData = data.map((item) => {
                const arbeitszeitKrankenqoute = item.arbeitszeitKrankenqoute || 0;
                const krankenzeit = item.krankenzeit || 0;
                const arbeitszeitKrankenqouteVM = item.arbeitszeitKrankenqouteVM || 0;
                const krankenzeitVM = item.krankenzeitVM || 0;
                const arbeitszeitKrankenqouteVJ = item.arbeitszeitKrankenqouteVJ || 0;
                const krankenzeitVJ = item.krankenzeitVJ || 0;

                // Berechne die Krankenquote
                const krankenquote = arbeitszeitKrankenqoute > 0 ? (krankenzeit / arbeitszeitKrankenqoute) * 100 : 0;
                const krankenquoteVM = arbeitszeitKrankenqouteVM > 0 ? (krankenzeitVM / arbeitszeitKrankenqouteVM) * 100 : 0;
                const krankenquoteVJ = arbeitszeitKrankenqouteVJ > 0 ? (krankenzeitVJ / arbeitszeitKrankenqouteVJ) * 100 : 0;

                return {
                    verkaufsgebiet: item.verkaufsgebiet,
                    filiale: item.filiale,
                    scoreKrankenquote: item.scoreKrankenquote || 0,
                    krankenquote: krankenquote,
                    krankenquoteVM: krankenquoteVM,
                    krankenquoteVJ: krankenquoteVJ,
                    krankenzeit: krankenzeit,
                    krankenzeitVM: krankenzeitVM,
                    krankenzeitVJ: krankenzeitVJ,
                    arbeitszeit: arbeitszeitKrankenqoute,
                    arbeitszeitVM: arbeitszeitKrankenqouteVM,
                    arbeitszeitVJ: arbeitszeitKrankenqouteVJ
                };
            });

            setTableData(formattedTableData);

        } catch (error) {
            console.error('Error fetching table data:', error);
        }
    };

    // Definition der Spalten für die Tabelle
    const columns = useMemo(() => [
        {
            accessorKey: 'verkaufsgebiet',
            header: 'Verkaufsgebiet',
            enableGrouping: true,
            isVisible: true,
        },
        {
            accessorKey: 'filiale',
            header: 'Filiale',
            enableGrouping: false,
            isVisible: true,
        },
        {
            accessorKey: 'scoreKrankenquote',
            header: 'Score',
            enableGrouping: false,
            aggregationFn: 'mean',
            Cell: ({ cell }) => cell.getValue().toFixed(2),
            AggregatedCell: ({ cell }) => cell.getValue().toFixed(2),
            isVisible: true,
        },
        {
            accessorKey: 'krankenquote',
            header: 'Krankenquote',
            enableGrouping: false,
            aggregationFn: 'mean',
            Cell: ({ cell }) => cell.getValue().toFixed(2) + '%',
            AggregatedCell: ({ cell, row }) => {
                const subRows = row.subRows || [];
                const aggregatedKrankenzeit = subRows.reduce((total, subRow) => total + subRow.getValue('krankenzeit'), 0);
                const aggregatedArbeitszeit = subRows.reduce((total, subRow) => total + subRow.getValue('arbeitszeit'), 0);
                const krankenquote = aggregatedArbeitszeit > 0 ? (aggregatedKrankenzeit / aggregatedArbeitszeit) * 100 : 0;
                return (
                    <span>
                        {krankenquote.toFixed(2)}%
                    </span>
                );
            },
            isVisible: true,
        },
        {
            accessorKey: 'krankenquoteVM',
            header: 'Krankenquote VM',
            enableGrouping: false,
            aggregationFn: 'mean',
            Cell: ({ cell }) => cell.getValue().toFixed(2) + '%',
            AggregatedCell: ({ cell, row }) => {
                const subRows = row.subRows || [];
                const aggregatedKrankenzeitVM = subRows.reduce((total, subRow) => total + subRow.original.krankenzeitVM, 0);
                const aggregatedArbeitszeitVM = subRows.reduce((total, subRow) => total + subRow.original.arbeitszeitVM, 0);
                const krankenquoteVM = aggregatedArbeitszeitVM > 0 ? (aggregatedKrankenzeitVM / aggregatedArbeitszeitVM) * 100 : 0;
                return (
                    <span>
                        {krankenquoteVM.toFixed(2)}%
                    </span>
                );
            },
            isVisible: true,
        },
        {
            accessorKey: 'krankenquoteVJ',
            header: 'Krankenquote VJ',
            enableGrouping: false,
            aggregationFn: 'mean',
            Cell: ({ cell }) => cell.getValue().toFixed(2) + '%',
            AggregatedCell: ({ cell, row }) => {
                const subRows = row.subRows || [];
                const aggregatedKrankenzeitVJ = subRows.reduce((total, subRow) => total + subRow.original.krankenzeitVJ, 0);
                const aggregatedArbeitszeitVJ = subRows.reduce((total, subRow) => total + subRow.original.arbeitszeitVJ, 0);
                const krankenquoteVJ = aggregatedArbeitszeitVJ > 0 ? (aggregatedKrankenzeitVJ / aggregatedArbeitszeitVJ) * 100 : 0;
                return (
                    <span>
                        {krankenquoteVJ.toFixed(2)}%
                    </span>
                );
            },
            isVisible: true,
        },
        {
            accessorKey: 'krankenzeit',
            header: 'Krankenzeit',
            enableGrouping: false,
            aggregationFn: 'sum',
            Cell: ({ cell }) => cell.getValue().toFixed(2),
            AggregatedCell: ({ cell }) => cell.getValue().toFixed(2),
            isVisible: true,
        },
        {
            accessorKey: 'arbeitszeit',
            header: 'arbeitszeit',
            enableGrouping: false,
            aggregationFn: 'sum',
            Cell: ({ cell }) => cell.getValue().toFixed(2),
            AggregatedCell: ({ cell }) => cell.getValue().toFixed(2),
            isVisible: true,
        },
    ], []);

    return (
        <Layout>
            <Grid container justifyContent="flex-end" alignItems="stretch">
                <Grid item xs={12}>
                    <Stack direction="row" alignItems="stretch" spacing={2} sx={{ overflowX: 'auto' }}>
                        {/* Krankenquote */}
                        <Grid item xs={12} md={6} lg={3} className="db_datacard_active">
                            <DataCard
                                category="Krankenquote"
                                sector="personal"
                                value={personalData.krankenquote.length > 0 ? personalData.krankenquote[personalData.krankenquote.length - 1].krankenquote.toFixed(2) + '%' : '0.00%'}
                                subHeaders={[
                                    `${personalData.krankenquote.length > 0 ? personalData.krankenquote[personalData.krankenquote.length - 1].krankenquoteVM.toFixed(2) : '0.00'}% zum Vormonat`,
                                    `${personalData.krankenquote.length > 0 ? personalData.krankenquote[personalData.krankenquote.length - 1].krankenquoteVJ.toFixed(2) : '0.00'}% zum Vorjahr`
                                ]}
                                chartData={personalData.krankenquote.map(item => item.krankenquote)} // Nutze die Krankenquote-Daten für den Sparkline-Chart
                            />
                        </Grid>

                        {/* Fluktuation */}
                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Fluktuation"
                                sector="personal"
                                value={personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].fluktuation.toFixed(2) + '%' : '0.00%'}
                                subHeaders={[
                                    `${personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].eintritteM : '0'} Eintritte im gefilterten Monat`,
                                    `${personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].austritteM : '0'} Austritte im gefilterten Monat`
                                ]}
                                chartData={personalData.fluktuation.map(item => item.fluktuation)} // Nutze die Fluktuation-Daten für den Sparkline-Chart
                            />
                        </Grid>

                        {/* Bedarf */}
                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Bedarf"
                                sector="personal"
                                value={`${personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].köpfeDifferenz : '0'} Köpfe und ${personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].stundenDifferenz : '0'} Stunden`}
                                chartData={personalData.fluktuation.map(item => item.köpfeDifferenz)}
                            />
                        </Grid>

                        {/* Auslastung */}
                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Auslastung"
                                sector="personal"
                                value={`${personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].überstundenAbs : '0'} Überstunden`}
                                subHeaders={[]}
                                chartData={personalData.fluktuation.map(item => item.überstundenAbs)}
                            />
                        </Grid>
                    </Stack>
                </Grid>

                {/* Liniendiagramm */}
                <Grid item xs={12} md={12} mt={3}>
                    <Paper>
                        <BigChartCard
                            data={chartData}
                            title="Krankenquote und Krankenquote Vorjahr der letzten 6 Monate"
                            height={400}
                            xKey="month"
                            yKeys={['krankenquote', 'krankenquoteVJ']}
                            labels={['Krankenquote', 'Krankenquote VJ']}
                        />
                    </Paper>
                </Grid>

                {/* Tabelle */}
                <Grid item xs={12} md={12} mt={3}>
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            padding: '0px',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            backdropFilter: 'blur(10px)',
                        }}
                    >
                        <CardHeader
                            title={
                                <Typography variant="h6" component="div">
                                    Krankenquote Übersicht
                                </Typography>
                            }
                        />
                        <CardContent sx={{ flexGrow: 1 }}>
                            <VariableTableTree
                                columns={columns}
                                data={tableData}
                                initialGrouping={['verkaufsgebiet']}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                {/* Neue Liste der Krankenquote pro Verkaufsgebiet für die letzten 6 Monate */}
                {/* Dieser Abschnitt bleibt unverändert */}

                {/* Liniendiagramm für Krankenquote pro Verkaufsgebiet */}
                <Grid item xs={12} md={12} mt={3}>
                    <Paper>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                padding: '0px',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                backdropFilter: 'blur(10px)',
                            }}
                        >
                            <CardHeader
                                title={
                                    <Typography variant="h6" component="div">
                                        Krankenquote pro Verkaufsgebiet über die letzten 6 Monate
                                    </Typography>
                                }
                            />
                            <CardContent sx={{ flexGrow: 1 }}>
                                {/* Hier verwenden wir ein Liniendiagramm */}
                                {/* Du kannst eine Chart-Bibliothek wie recharts oder nivo verwenden */}
                                {/* Beispiel mit recharts */}
                                {/* Installiere recharts mit npm install recharts */}
                                <LineChartComponent data={lineChartData} />
                            </CardContent>
                        </Card>
                    </Paper>
                </Grid>

            </Grid>
        </Layout>
    );
};

// Beispielimplementierung des LineChartComponent mit recharts
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const LineChartComponent = ({ data }) => {
    // Transformiere die Daten in ein Format, das recharts versteht
    // Jede Datenreihe ist eine Linie, und die x-Achse sind die Monate
    const chartData = [];
    if (data.length > 0) {
        const months = data[0].data.map(d => d.x); // Hole die Monate
        months.forEach((month, index) => {
            const dataPoint = { month };
            data.forEach(series => {
                dataPoint[series.id] = series.data[index].y;
            });
            chartData.push(dataPoint);
        });
    }

    return (
        <ResponsiveContainer width="100%" height={400}>
            <LineChart data={chartData}>
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                {data.map(series => (
                    <Line
                        key={series.id}
                        type="monotone"
                        dataKey={series.id}
                        stroke={`#${Math.floor(Math.random()*16777215).toString(16)}`} // Zufällige Farbe
                        connectNulls
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default Krankenquote;
