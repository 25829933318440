import React from 'react';
import {RouterProvider, createBrowserRouter} from "react-router-dom";
import {routes as dynamicRoutes} from './dynamicRoutes';
import {ProtectedRoute} from "./ProtectedRoute";
import loginAction from "../hooks/useLogin";
import LoginPage from "../pages/login/LoginPage";
import LogoutPage from "../pages/login/LogoutPage";
import Module from '../pages/system/Module';
import Settings from "../pages/system/Settings";
import NotFoundPage from "../pages/system/NotFoundPage";
import AccessDenied from "../pages/system/AccessDenied";
import Experimental from "../pages/development/Experimental";


const Routes = () => {
    // Define public routes accessible to all users
    const routesForPublic = [{
        path: "/login", element: <LoginPage/>, action: loginAction
    }, {
        path: "/access-denied", element: <AccessDenied/>,
    }, {
        path: "/experimental", element: <Experimental/>,
    }, {
        path: "/logout", element:
                <LogoutPage/>,
    }

    ];

    const routesForAuthenticatedOnly = [{
        path: "/settings", element: (
            <ProtectedRoute>
                <Settings/>
            </ProtectedRoute>)
    }, {
        path: "/module", element: (
            <ProtectedRoute>
                <Module/>
            </ProtectedRoute>)
    }, {
        path: "/", element: (
            <ProtectedRoute>
                <Module/>
            </ProtectedRoute>)
    }, {
       
    }];

    const combinedRoutes = [
        ...routesForPublic,
        ...routesForAuthenticatedOnly,
        ...dynamicRoutes.map((route) => ({
            path: route.path,
            element: (
                <route.component/>
            ),
        })),
        {path: "*", element: <NotFoundPage/>}  // Catch-all route for 404
    ];

    // Combine and conditionally include routes based on authentication status
    const router = createBrowserRouter(combinedRoutes);

    // Provide the router configuration using RouterProvider
    return <RouterProvider router={router}/>;
};

export default Routes;
