import {AppBar, Box, ButtonGroup, IconButton, Toolbar} from "@mui/material";
import Button from "@mui/material/Button";
import SettingsIcon from "@mui/icons-material/Settings";

const ThekeHeader = ({ warengruppen, setFilter, filter, setShowOverview, onOpenSettings }) => {
    const handleCategoryClick = (category) => {
        setFilter(category);
        setShowOverview(false);
    };

    return (
            <Toolbar variant="dense">
                <Box display="flex" justifyContent="center" width="100%">
                    {/* Button Group for Overview and Warengruppen */}
                    <ButtonGroup variant="outlined" color="inherit">
                        <Button
                            onClick={() => {
                                setShowOverview(true);
                                setFilter(null);
                            }}
                            size="small"

                        >
                            Overview
                        </Button>
                        {warengruppen.map((wg, index) => (
                            <Button
                                key={index}
                                onClick={() => handleCategoryClick(wg.warengruppennummer)}
                                size="small"
                                sx={{
                                    backgroundColor: filter === wg.warengruppennummer ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: filter === wg.warengruppennummer ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.1)',
                                    },
                                }}
                            >
                                {wg.warengruppe}
                            </Button>
                        ))}
                    </ButtonGroup>

                    {/* Settings Icon Button */}
                    <IconButton color="inherit" onClick={onOpenSettings} size="small">
                        <SettingsIcon />
                    </IconButton>
                </Box>
            </Toolbar>
    );
};

export default ThekeHeader;
