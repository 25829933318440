import React, {useState} from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    TableFooter
} from '@mui/material';

const TableBasic = ({columns, data, fieldsWithPercent = [], footerValues = {}, fixFirstColumn = false}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [orderBy, setOrderBy] = useState('');
    const [orderDirection, setOrderDirection] = useState('asc');
    const [clickCount, setClickCount] = useState({});

    // Handle page change in the pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle rows per page change in the pagination
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Handle sorting when a column header is clicked
    const handleRequestSort = (columnField) => {
        const currentCount = clickCount[columnField] || 0;
        const newCount = currentCount + 1;

        if (newCount === 3) {
            // Reset sorting on the third click
            setOrderBy(columns[0].field);
            setOrderDirection('asc');
            setClickCount({...clickCount, [columnField]: 0});
        } else {
            // Toggle sort direction
            const isAsc = orderBy === columnField && orderDirection === 'asc';
            setOrderDirection(isAsc ? 'desc' : 'asc');
            setOrderBy(columnField);
            setClickCount({...clickCount, [columnField]: newCount});
        }
    };

    // Sort the data based on the current sort settings
    const sortedData = orderBy
        ? data.sort((a, b) => {
            if (a[orderBy] < b[orderBy]) {
                return orderDirection === 'asc' ? -1 : 1;
            }
            if (a[orderBy] > b[orderBy]) {
                return orderDirection === 'asc' ? 1 : -1;
            }
            return 0;
        })
        : data;

    // Paginate the sorted data
    const paginatedData = sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    // Add percentage sign to specific columns
    const addPercentSign = (field, value) => {
        if (fieldsWithPercent.includes(field) && value !== null) {
            return `${value}%`;
        }
        return value;
    };

    // Format values to deal with null, NaN, and undefined values
    const formatValue = (value) => {
        if (value === null || value === undefined) {
            return '';
        }
        if (typeof value === 'number' && isNaN(value)) {
            return 'N/A';
        }
        return value;
    };

    // Get color style for values, red for negative and green for positive
    const getColorStyle = (value) => {
        if (typeof value === 'number') {
            return value < 0 ? {color: 'red'} : {color: 'green'};
        }
        return {};
    };

    return (
        <TableContainer component={Paper}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {columns.map((column, index) => (
                            <TableCell
                                key={column.field}
                                onClick={() => handleRequestSort(column.field)}
                                style={{cursor: 'pointer'}}
                                sx={index === 0 && fixFirstColumn ? {width: '250px'} : {}} // Set fixed width for the first column
                            >
                                {column.headerName}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {paginatedData.map((row, index) => (
                        <TableRow key={index}>
                            {columns.map((column) => (
                                <TableCell key={column.field}
                                           style={fieldsWithPercent.includes(column.field) ? getColorStyle(row[column.field]) : {}}>
                                    {formatValue(addPercentSign(column.field, row[column.field]))}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell key={column.field} style={{fontWeight: 'bold', fontSize: '1em'}}>
                                {footerValues[column.field] !== undefined ? formatValue(addPercentSign(column.field, footerValues[column.field])) : ''}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableFooter>
            </Table>
            <TablePagination
                rowsPerPageOptions={[10, 200, 500]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
    );
};

export default TableBasic;
