import {useAuth} from "../../provider/authProvider";
import Paper from "@mui/material/Paper";
import React, {useState} from 'react';
import {Form, useNavigate} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import axiosInstance from "../../services/axiosInstance";


const useStyles = makeStyles((theme) => ({
    '@global': {
        body: {
            backgroundColor: '#e0f7fa',
            fontFamily: 'Roboto, sans-serif',
        },
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    paper: {
        padding: theme.spacing(6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '400px',
        width: '100%',
        backgroundColor: '#ffffff',
        borderRadius: '12px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        padding: theme.spacing(1.5),
        backgroundColor: '#00796b',
        '&:hover': {
            backgroundColor: '#004d40',
        },
    },
    error: {
        color: 'red',
        marginTop: theme.spacing(2),
    },
    title: {
        color: '#00796b',
        fontSize: '2rem',
        fontWeight: 'bold',
    },
}));

const LogoutPage = () => {
    const classes = useStyles();
    const {setToken} = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loggedOut, setLoggedOut] = useState(false);

    const handleLogout = async () => {
        setLoading(true)
        setToken(null);
        try {
            await axiosInstance.post('/auth/logout/', {}, {withCredentials: true});
            setLoggedOut(true);
        } catch (error) {
            console.log('Error logging out:', error);
        }
        setLoading(false)
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={3}>
                <h1 className={classes.title}>Logout</h1>
                {loading ?
                    <CircularProgress size={24}/> :
                    loggedOut ?
                    <div>
                        <div>Successfully logged out</div>
                        <Button
                            onClick={() => navigate('/login')}
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}>
                            Back to login
                        </Button>
                    </div> :
                    <Form className={classes.form} onSubmit={handleLogout}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            {loading ? <CircularProgress size={24}/> : 'Logout'}
                        </Button>
                    </Form>}
            </Paper>
        </div>
    );
};

export default LogoutPage;
