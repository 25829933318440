import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, List, ListItem, ListItemText, CircularProgress, Button, Alert, MenuItem, Select, Divider, Paper } from '@mui/material';
import axiosInstance from '../../services/axiosInstance';
import { FilterContext } from '../../provider/FilterProvider';

const CategoryCheckpointsForm = ({ checkId }) => {
    const { filterState, handleFilialeChange } = useContext(FilterContext);
    const [categories, setCategories] = useState([]);
    const [ratings, setRatings] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [filialeOptions, setFilialeOptions] = useState([]);

    const formatDate = (date) => {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    };

    const currentDate = formatDate(new Date());

    useEffect(() => {
        const fetchCategoriesAndCheckpoints = async () => {
            try {
                const categoryResponse = await axiosInstance.get('/filialcheck/categories/');
                const filteredCategories = categoryResponse.data.results.filter(category => category.check_related === checkId);
                const checkpointResponse = await axiosInstance.get('/filialcheck/checkpoints/');
                const categorizedCheckpoints = filteredCategories.map(category => ({
                    ...category,
                    checkpoints: checkpointResponse.data.results.filter(checkpoint => checkpoint.category === category.id)
                }));
                setCategories(categorizedCheckpoints);
            } catch (error) {
                setError('Fehler beim Laden der Daten.');
            } finally {
                setLoading(false);
            }
        };

        fetchCategoriesAndCheckpoints();
    }, [checkId]);

    useEffect(() => {
        const fetchFilialeOptions = async () => {
            try {
                const filialeResponse = await axiosInstance.get('stammdaten/unique-filiale/');
                const filialeOpts = filialeResponse.data.map(item => ({ label: item.filialnummer, value: item.filiale }));
                setFilialeOptions(filialeOpts);
                if (!filterState.filiale && filialeOpts.length > 0) {
                    handleFilialeChange(filialeOpts[0].value);
                }
            } catch (error) {
                setError('Fehler beim Laden der Filialen.');
            }
        };

        fetchFilialeOptions();
    }, [handleFilialeChange, filterState.filiale]);

    const handleRatingChange = (checkpointId, value) => {
        setRatings(prevRatings => ({
            ...prevRatings,
            [checkpointId]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const ratingEntries = Object.entries(ratings);
        if (ratingEntries.length === 0) {
            setError('Bitte bewerten Sie mindestens einen Checkpoint.');
            return;
        }

        try {
            // Erstelle die Liste der Ratings für die API
            const payload = ratingEntries.map(([checkpointId, value]) => ({
                value: value,
                datum: currentDate,
                user: "databites",
                filialnummer: parseInt(filterState.filiale),
                checkpoint: checkpointId,
                check_related: checkId,
            }));

            // Sende die gesamte Liste in einer Anfrage
            await axiosInstance.post('/filialcheck/ratings/', payload);
            
            setRatings({});
            setSubmitSuccess(true);
            setError(null);
        } catch (error) {
            setError('Fehler beim Speichern der Bewertungen.');
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography variant="body1" color="error">{error}</Typography>;
    }

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ maxWidth: 800, mx: 'auto', mt: 4 }}>
            {/* Oberer Bereich mit Titel */}
            <Box sx={{ mb: 4 }}>
            <Select
                    value={filterState.filiale || ""}
                    onChange={(e) => handleFilialeChange(e.target.value)}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Filiale auswählen' }}
                >
                    <MenuItem value="" disabled>
                        Filiale auswählen
                    </MenuItem>
                    {filialeOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
                <Typography variant="h5" gutterBottom>
                   Filiale:                     {filterState.filiale && filialeOptions.find(option => option.value === filterState.filiale)?.label}    
                   Datum: {currentDate}
                </Typography>
               
            </Box>



            {/* Checkpoints Bewertung */}
            <Typography variant="h6" gutterBottom>
                Checkpoints bewerten
            </Typography>

            {submitSuccess && (
                <Alert severity="success" sx={{ mb: 2 }}>
                    Bewertungen erfolgreich gespeichert!
                </Alert>
            )}

            {categories.length > 0 ? (
                <List>
                    {categories.map((category) => (
                        <Paper key={category.id} sx={{ mb: 4, p: 2, border: '1px solid #ccc' }}> {/* Rahmen um die Kategorie */}
                            <Typography variant="h5" gutterBottom>
                                {category.name}
                            </Typography>
                            <List>
                                {category.checkpoints.map((checkpoint, index) => (
                                    <React.Fragment key={checkpoint.id}>
                                        <ListItem>
                                            <ListItemText primary={checkpoint.name} />
                                            <Select
                                                value={ratings[checkpoint.id] || 5}
                                                onChange={(e) => handleRatingChange(checkpoint.id, e.target.value)}
                                                sx={{ width: 100, ml: 2 }}
                                            >
                                                {[...Array(10).keys()].map((num) => (
                                                    <MenuItem key={num + 1} value={num + 1}>
                                                        {num + 1}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </ListItem>
                                        {index < category.checkpoints.length - 1 && <Divider />} {/* Linie zwischen den Checkpoints */}
                                    </React.Fragment>
                                ))}
                            </List>
                        </Paper>
                    ))}
                </List>
            ) : (
                <Typography variant="body1">
                    Keine Kategorien und Checkpoints gefunden.
                </Typography>
            )}

            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                Speichern
            </Button>
        </Box>
    );
};

export default CategoryCheckpointsForm;
