import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../services/axiosInstance';  // Verwende deine angepasste axiosInstance

const FilialcheckHinzufuegen = ({ open, handleClose }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [isActive, setIsActive] = useState(true);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axiosInstance.post('/filialcheck/checks/', {
                name,
                description,
                is_active: isActive
            });
            handleClose();  // Schließt den Dialog nach dem erfolgreichen Hinzufügen
            navigate('/filialcheck/filialcheckübersicht');  // Zurück zur Liste der Filialchecks
        } catch (error) {
            console.error('Fehler beim Hinzufügen des Filialchecks:', error);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>Neuen Filialcheck hinzufügen</DialogTitle>
            <DialogContent>
                <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%', mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Name des Filialchecks"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        id="description"
                        label="Beschreibung"
                        name="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        multiline
                        rows={4}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">Abbrechen</Button>
                <Button onClick={handleSubmit} variant="contained" color="primary">Hinzufügen</Button>
            </DialogActions>
        </Dialog>
    );
};

export default FilialcheckHinzufuegen;
