import { useDrop } from "react-dnd";
import { Box, Typography } from "@mui/material";

const DropZone = ({ onDrop, artikel }) => {
    // Determine if drag-and-drop functionality should be enabled
    const [{ isOver, canDrop }, drop] = onDrop ? useDrop(() => ({
        accept: 'text',
        drop: (item) => {
            onDrop(item);
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    })) : [{}, null]; // No drop logic if onDrop is not provided

    return (
        <Box
            ref={onDrop ? drop : null} // Only set ref if onDrop is provided
            sx={{
                width: 180,
                height: 75,
                padding: 2,
                border: '2px dashed grey',
                borderColor: isOver ? 'primary.main' : (canDrop ? 'success.main' : 'grey.500'),
                borderRadius: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: artikel ? 'success.light' : (isOver ? 'action.hover' : 'background.paper'),
            }}
        >
            {artikel ? (
                <Typography>
                    {artikel.artikelbezeichnung}
                </Typography>
            ) : (
                <Typography color="text.secondary">Slot</Typography>
            )}
        </Box>
    );
};

export default DropZone;