import axios from "axios";

let BASE_URL = process.env.REACT_APP_BASE_URL
let BASE_URL_TEMPLATE = process.env.REACT_APP_BASE_URL_TEMPLATE

// manually set Urls if env missing
if (!process.env.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL_TEMPLATE === '') {
    console.log("manullay set urls if missing")
    BASE_URL="https://databites.de/api"
    BASE_URL_TEMPLATE="https://{subdomain}.databites.de/api"
}

function getSubdomain() {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    // hello there
    if (parts.length > 1) {
        return parts[0];
    }
    return null;
}

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
    },
});

export const setupAxiosInterceptors = (token, refreshAccessToken, setToken, setRefreshTokenExpired) => {
    axiosInstance.interceptors.request.use(config => {
        const subdomain = getSubdomain();

        // If a subdomain is detected, use it to set the base URL dynamically
        if (subdomain) {
            config.baseURL = BASE_URL_TEMPLATE.replace("{subdomain}", subdomain);
        }

        // Attach the token if available
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    }, error => {
        return Promise.reject(error.messa);
    });

    // Add a response interceptor to handle token refresh
    axiosInstance.interceptors.response.use(
        response => response,
        async error => {
            const originalRequest = error.config;

            if (error.response?.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;

                if (error.response?.data?.code === "token_not_valid") {
                    setRefreshTokenExpired(true);
                    setToken(null);
                    alert("Your session has expired. Please log in again.");
                    window.location.href = "/login";
                } else {

                    // Attempt to refresh the access token
                    const newAccessToken = await refreshAccessToken();
                    if (newAccessToken) {
                        // Update the Authorization header with the new token
                        axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + newAccessToken;
                        originalRequest.headers["Authorization"] = "Bearer " + newAccessToken;

                        // Retry the original request with the new token
                        return axiosInstance(originalRequest);
                    } else {
                        setToken(null);

                        // Show an error message to the user
                        alert("Your session has expired. Please log in again.");

                        // Redirect to login page
                        window.location.href = "/login";
                    }
                }
            }
            return Promise.reject(error);
        }
    );
}

export default axiosInstance;
