import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import React from "react";

const NotFoundPage = () => {
    const navigate = useNavigate();

    return (
        <div className="flex items-start justify-center min-h-screen text-center" style={{paddingTop: '20vh'}}>
            <div>
                <h1 className="text-4xl text-red-500 underline">404 - Not Found</h1>
                <br/>
                <br/>
                <p className="text-xl">The page you are looking for does not exist</p>
                <br/>
                <p className="text-xl">Die Seite nach der du gesucht hast gibt es nicht</p>
                <br/>
                <br/>
                <Button
                    variant="contained"
                    color="primary"
                    href="/"
                    sx={{fontSize: '1.25rem', padding: '10px 20px', minWidth: '150px'}}
                >
                    Back Home
                </Button>
            </div>
        </div>
    )
}


export default NotFoundPage;
