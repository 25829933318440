import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Grid, Stack, Paper, Card, CardHeader, Typography, CardContent, Box } from '@mui/material';
import Layout from '../../../components/layout/Scorecards/Unternehmen/UnternehmenMonat';
import DataCard from '../../../components/card/scorecardsmonat/DataCardMonatPage';
import VariableTableTree from '../../../components/table/VariableTableTree';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import { calculatePerformanceData } from '../../../utils/calculationPerformance';
import BigChartCard from "../../../components/card/scorecardsmonat/BigChartCardUnternehmen";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import StoreIcon from '@mui/icons-material/Store';
import BigPieChartCardUnternehmen from '../../../components/card/scorecardsmonat/BigPieChartCardUnternehmen';

const PerformanceKundenanzahl = () => {
    const { filterState } = useContext(FilterContext);
    const [performanceData, setPerformanceData] = useState({
        umsatz: 0,
        umsatzVJ: 0,
        umsatzAbweichungVJ: 0,
        kundenanzahl: 0,
        kundenanzahlVJ: 0,
        kundenanzahlAbweichungVJ: 0,
        kundenbon: 0,
        kundenbonVJ: 0,
        leistung: 0,
        leistungVJ: 0,
        chartData: [],
        verkaufsgebieteResult: [],
        filialenMitKundenanzahl: 0,
        filialtypenResult: [],
        durchschnittKundenanzahl: 0,
    });
    const [tableData, setTableData] = useState([]);
    const [performanceLast6Months, setPerformanceLast6Months] = useState([]);

    useEffect(() => {
        if (filterState.datum) {
            fetchPerformanceData();
            fetchPerformanceDataLast6Months();
        }
    }, [filterState]);

    const formatNumberWithCommas = (number) => {
        return number.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    };

    const formatPercentage = (value) => {
        return `${value.toFixed(2)}%`;
    };

    const fetchPerformanceData = async () => {
        try {
            // Abrufen der Daten von der API
            const response = await axiosInstance.get('/scorecards_month/performance-kennzahl-gesamt/', {
                params: {
                    jahrMonat: filterState.datum?.value,
                },
            });

            const allFilialenData = response.data.results;

            // Berechnungen durchführen
            const calculatedData = calculatePerformanceData(allFilialenData);

            // Anzahl der Filialen mit Kundenanzahl > 0 berechnen
            const filialenMitKundenanzahl = allFilialenData.filter((filiale) => filiale.kundenanzahl > 0).length;

            // Durchschnittliche Kundenanzahl pro Filiale berechnen
            const durchschnittKundenanzahl = filialenMitKundenanzahl > 0 ? calculatedData.kundenanzahlSumme / filialenMitKundenanzahl : 0;

            // Berechnung der filialtypenResult für das PieChart
            const filialtypenResult = allFilialenData.reduce((acc, filiale) => {
                const existing = acc.find(item => item.filialtyp === filiale.filialtyp);
                if (existing) {
                    existing.kundenanzahlSumme += filiale.kundenanzahl || 0;
                    existing.kundenanzahlVJSumme += filiale.kundenanzahlVJ || 0;
                } else {
                    acc.push({
                        filialtyp: filiale.filialtyp,
                        kundenanzahlSumme: filiale.kundenanzahl || 0,
                        kundenanzahlVJSumme: filiale.kundenanzahlVJ || 0,
                    });
                }
                return acc;
            }, []);

            // Berechnung der Kundenanzahlabweichung für jeden Filialtyp
            filialtypenResult.forEach(item => {
                item.kundenanzahlAbweichung = item.kundenanzahlVJSumme > 0
                    ? ((item.kundenanzahlSumme - item.kundenanzahlVJSumme) / item.kundenanzahlVJSumme) * 100
                    : 0;
            });

            // Setzen der Performance-Daten
            setPerformanceData({
                umsatz: calculatedData.umsatzSumme,
                umsatzVJ: calculatedData.umsatzVJSumme,
                umsatzAbweichungVJ: calculatedData.umsatzAbweichungVJ,
                kundenanzahl: calculatedData.kundenanzahlSumme,
                kundenanzahlVJ: calculatedData.kundenanzahlVJSumme,
                kundenanzahlAbweichungVJ: calculatedData.kundenanzahlAbweichungVJ,
                kundenbon: calculatedData.kundenbon,
                kundenbonVJ: calculatedData.kundenbonVJ,
                leistung: calculatedData.leistung,
                leistungVJ: calculatedData.leistungVJ,
                chartData: allFilialenData.map((item) => ({
                    kundenanzahl: item.kundenanzahl || 0,
                })),
                verkaufsgebieteResult: calculatedData.verkaufsgebieteResult,
                filialenMitKundenanzahl,
                durchschnittKundenanzahl,
                filialtypenResult, // Hinzufügen von filialtypenResult für das Chart
            });

            // Tabelle formatieren
            const formattedTableData = allFilialenData
                .filter(filiale => filiale.kundenanzahl > 0)
                .map((filiale) => ({
                    filialnummer: filiale.filialnummer,
                    filiale: filiale.filiale,
                    verkaufsgebiet: filiale.verkaufsgebiet,
                    filialtyp: filiale.filialtyp,
                    kundenanzahl: filiale.kundenanzahl,
                    kundenanzahlVM: filiale.kundenanzahlVM,
                    kundenanzahlVJ: filiale.kundenanzahlVJ,
                    kundenanzahl6M: filiale.kundenanzahl6M,
                    kundenanzahlAbweichungVM: filiale.kundenanzahlVM > 0 ? ((filiale.kundenanzahl - filiale.kundenanzahlVM) / filiale.kundenanzahlVM) * 100 : 0,
                    kundenanzahlAbweichung6M: filiale.kundenanzahl6M > 0 ? ((filiale.kundenanzahl - filiale.kundenanzahl6M) / filiale.kundenanzahl6M) * 100 : 0,
                    kundenanzahlAbweichungVJ: filiale.kundenanzahlVJ > 0 ? ((filiale.kundenanzahl - filiale.kundenanzahlVJ) / filiale.kundenanzahlVJ) * 100 : 0,
                }));

            setTableData(formattedTableData);

        } catch (error) {
            console.error('Error fetching performance data:', error);
        }
    };

    const getLast6Months = (currentMonth) => {
        const last6Months = [];
        const formattedMonth = dayjs(currentMonth, 'YYYY.MM');
        for (let i = 0; i < 6; i++) {
            last6Months.push(formattedMonth.subtract(i, 'month').format('YYYY.MM'));
        }
        return last6Months.reverse();
    };

    const fetchPerformanceDataLast6Months = async () => {
        const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM');
        const last6Months = getLast6Months(currentMonth);

        try {
            const responses = await Promise.all(
                last6Months.map(month => axiosInstance.get('/scorecards_month/performance-kennzahl-gesamt/', {
                    params: { jahrMonat: month },
                }))
            );

            const last6MonthsData = responses.map((response, index) => {
                const kundenanzahlSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.kundenanzahl || 0), 0);
                const kundenanzahlVJSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.kundenanzahlVJ || 0), 0);

                const umsatzSumme = response.data.results
                    .filter(filiale => filiale.umsatz > 1)
                    .reduce((sum, filiale) => sum + (filiale.umsatz || 0), 0);

                const umsatzVJSumme = response.data.results
                    .filter(filiale => filiale.umsatzVJ > 1)
                    .reduce((sum, filiale) => sum + (filiale.umsatzVJ || 0), 0);

                const produktivstundenSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.produktivstunden || 0), 0);

                const kundenbon = kundenanzahlSumme > 0 ? umsatzSumme / kundenanzahlSumme : 0;
                const leistung = produktivstundenSumme > 0 ? umsatzSumme / produktivstundenSumme : 0;

                return {
                    month: last6Months[index],
                    kundenanzahl: kundenanzahlSumme,
                    kundenanzahlVJ: kundenanzahlVJSumme,
                    umsatz: umsatzSumme,
                    umsatzVJ: umsatzVJSumme,
                    kundenbon: kundenbon,
                    leistung: leistung,
                };
            });

            setPerformanceLast6Months(last6MonthsData);
        } catch (error) {
            console.error('Error fetching data for last 6 months:', error);
        }
    };

    // Daten für das Liniendiagramm vorbereiten
    const chartData = performanceLast6Months.flatMap((monthData) => [
        { x: monthData.month, y: monthData.kundenanzahl, label: 'aktuelles Jahr' },
        { x: monthData.month, y: monthData.kundenanzahlVJ, label: 'Vorjahr' }
    ]);

    const columns = useMemo(() => [
        {
            accessorKey: 'verkaufsgebiet',
            header: 'Verkaufsgebiet',
            enableGrouping: true,
            isVisible: true,
        },
        {
            accessorKey: 'filiale',
            header: 'Filiale',
            enableGrouping: false,
            isVisible: true,
        },
        {
            accessorKey: 'kundenanzahl',
            header: 'Kundenanzahl',
            enableGrouping: false,
            aggregationFn: 'sum',
            Cell: ({ cell }) => formatNumberWithCommas(cell.getValue()),
            AggregatedCell: ({ cell }) => (
                <span>{formatNumberWithCommas(cell.getValue())}</span>
            ),
            isVisible: true,
        },
        {
            accessorKey: 'kundenanzahlAbweichungVM',
            header: 'Kundenanzahl Abw. VM',
            enableGrouping: false,
            Cell: ({ cell }) => (
                <span>{cell.getValue().toFixed(2)}%</span>
            ),
            AggregatedCell: ({ cell, row }) => {
                const subRows = row.subRows || [];
                const aggregatedKundenanzahl = subRows.reduce((total, subRow) => total + subRow.getValue('kundenanzahl'), 0);
                const aggregatedKundenanzahlVM = subRows.reduce((total, subRow) => total + subRow.original.kundenanzahlVM, 0);

                const kundenanzahlAbweichungVM = aggregatedKundenanzahlVM > 0 ? ((aggregatedKundenanzahl - aggregatedKundenanzahlVM) / aggregatedKundenanzahlVM) * 100 : 0;

                return (
                    <span>
                        {kundenanzahlAbweichungVM.toFixed(2)}%
                    </span>
                );
            },
            isVisible: true,
        },
        {
            accessorKey: 'kundenanzahlAbweichung6M',
            header: 'Kundenanzahl Abw. 6M',
            enableGrouping: false,
            Cell: ({ cell }) => (
                <span>{cell.getValue().toFixed(2)}%</span>
            ),
            AggregatedCell: ({ cell, row }) => {
                const subRows = row.subRows || [];
                const aggregatedKundenanzahl = subRows.reduce((total, subRow) => total + subRow.getValue('kundenanzahl'), 0);
                const aggregatedKundenanzahl6M = subRows.reduce((total, subRow) => total + subRow.original.kundenanzahl6M, 0);

                const kundenanzahlAbweichung6M = aggregatedKundenanzahl6M > 0 ? ((aggregatedKundenanzahl - aggregatedKundenanzahl6M) / aggregatedKundenanzahl6M) * 100 : 0;

                return (
                    <span>
                        {kundenanzahlAbweichung6M.toFixed(2)}%
                    </span>
                );
            },
            isVisible: true,
        },
        {
            accessorKey: 'kundenanzahlAbweichungVJ',
            header: 'Kundenanzahl Abw. VJ',
            enableGrouping: false,
            Cell: ({ cell }) => (
                <span>{cell.getValue().toFixed(2)}%</span>
            ),
            AggregatedCell: ({ cell, row }) => {
                const subRows = row.subRows || [];
                const aggregatedKundenanzahl = subRows.reduce((total, subRow) => total + subRow.getValue('kundenanzahl'), 0);
                const aggregatedKundenanzahlVJ = subRows.reduce((total, subRow) => total + subRow.original.kundenanzahlVJ, 0);

                const kundenanzahlAbweichungVJ = aggregatedKundenanzahlVJ > 0 ? ((aggregatedKundenanzahl - aggregatedKundenanzahlVJ) / aggregatedKundenanzahlVJ) * 100 : 0;

                return (
                    <span>
                        {kundenanzahlAbweichungVJ.toFixed(2)}%
                    </span>
                );
            },
            isVisible: true,
        },
    ], [performanceData]);

    return (
        <Layout>
            <Grid container justifyContent="flex-end" alignItems="stretch">
                <Grid item xs={12}>
                    <Stack direction="row" alignItems="stretch" spacing={2} sx={{ overflowX: 'auto' }}>
                        {/* DataCards */}
                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Umsatz"
                                sector="performance"
                                value={performanceData.umsatz ? formatNumberWithCommas(performanceData.umsatz) : '0.00'}
                                subHeaders={[
                                    `${performanceData.umsatzAbweichungVJ.toFixed(2)}% zum Vorjahr`
                                ]}
                                chartData={performanceLast6Months.map(item => item.umsatz)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3} className="db_datacard_active">
                            <DataCard
                                category="Kundenanzahl"
                                sector="performance"
                                value={performanceData.kundenanzahl ? formatNumberWithCommas(performanceData.kundenanzahl) : '0'}
                                subHeaders={[
                                    `${performanceData.kundenanzahlAbweichungVJ.toFixed(2)}% zum Vorjahr`
                                ]}
                                chartData={performanceLast6Months.map(item => item.kundenanzahl)}
                            />
                        </Grid>

                        {/* Weitere DataCards bleiben unverändert */}
                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Kundenbon"
                                sector="performance"
                                value={performanceData.kundenbon ? performanceData.kundenbon.toFixed(2) : '0.00'}
                                subHeaders={[
                                    `${((performanceData.kundenbon - performanceData.kundenbonVJ) / performanceData.kundenbonVJ * 100).toFixed(2)}% zum Vorjahr`
                                ]}
                                chartData={performanceLast6Months.map(item => item.kundenbon)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Leistung"
                                sector="performance"
                                value={performanceData.leistung ? performanceData.leistung.toFixed(2) : '0.00'}
                                subHeaders={[
                                    `${((performanceData.leistung - performanceData.leistungVJ) / performanceData.leistungVJ * 100).toFixed(2)}% zum Vorjahr`
                                ]}
                                chartData={performanceLast6Months.map(item => item.leistung)}
                            />
                        </Grid>
                    </Stack>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Paper className="db_datacard_active_content">
                        <Stack direction="row" spacing={2} sx={{ alignItems: 'stretch' }}>
                            <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                {/* DataCard für Filialen mit Kundenanzahl */}
                                <Box>
                                    <Paper elevation={3} sx={{ padding: 3 }}>
                                        <Stack spacing={3.5}>
                                            {/* Anzahl der Filialen mit Kundenanzahl */}
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                <StoreIcon fontSize="large" color="primary" />
                                                <Box>
                                                    <Typography variant="h6">
                                                        Anzahl Filialen mit Kunden im gefilterten Monat
                                                    </Typography>
                                                    <Typography variant="h4">
                                                        {performanceData.filialenMitKundenanzahl ? performanceData.filialenMitKundenanzahl : '0'}
                                                    </Typography>
                                                </Box>
                                            </Stack>

                                            {/* Durchschnittliche Kundenanzahl */}
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                <MonetizationOnIcon fontSize="large" color="primary" />
                                                <Box>
                                                    <Typography variant="h6">Durchschnittliche Kundenanzahl pro Filiale</Typography>
                                                    <Typography variant="h4">
                                                        {performanceData.durchschnittKundenanzahl ? formatNumberWithCommas(performanceData.durchschnittKundenanzahl) : '0'}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                        </Stack>
                                    </Paper>
                                </Box>

                                {/* PieChart für Filialtypen */}
                                <Box sx={{ flexGrow: 1 }}>
                                    <BigPieChartCardUnternehmen
                                        data={performanceData.filialtypenResult}
                                        title="Kundenanzahlanteil nach Filialtyp"
                                        metric="kundenanzahl"
                                    />
                                </Box>
                            </Grid>

                            <BigChartCard
                                data={chartData}
                                title="Kundenanzahl und Kundenanzahl Vorjahr der letzten 6 Monate"
                            />
                        </Stack>

                        <Grid item xs={12} md={12} mt={3} sx={{ mt: 2 }}>
                            <Card
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                    padding: '0px',
                                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                    backdropFilter: 'blur(10px)',
                                }}
                            >
                                <CardHeader
                                    title={
                                        <Typography variant="h6" component="div">
                                            Kundenanzahl
                                        </Typography>
                                    }
                                />
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <VariableTableTree
                                        columns={columns}
                                        data={tableData}
                                        initialGrouping={['verkaufsgebiet']}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default PerformanceKundenanzahl;
