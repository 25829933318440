import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axiosInstance from '../../services/axiosInstance';
import VariableTable from '../table/VariableTable';
import dayjs from 'dayjs';

const ArticleTablePopup = ({ open, handleClose, warengruppe, filiale, jahrMonat, warengruppeName }) => {
    const [articleData, setArticleData] = useState([]);
    const [articleColumns, setArticleColumns] = useState([]);

    useEffect(() => {
        if (open && warengruppe && filiale && jahrMonat) {
            fetchArticleData();
        }
    }, [open, warengruppe, filiale, jahrMonat]);

    const fetchArticleData = async () => {
        try {
            // Berechne das Vorjahr
            const lastYearJahrMonat = dayjs(jahrMonat).subtract(1, 'year').format('YYYY.MM');

            // Abrufen der aktuellen Daten
            const response = await axiosInstance.get('/fact/facts/', {
                params: {
                    warengruppe: warengruppe,
                    filiale: filiale,
                    jahrMonat: jahrMonat
                }
            });

            // Abrufen der Vorjahresdaten
            const responseLastYear = await axiosInstance.get('/fact/facts/', {
                params: {
                    warengruppe: warengruppe,
                    filiale: filiale,
                    jahrMonat: lastYearJahrMonat
                }
            });

            // Artikel-Daten aggregieren
            const aggregatedData = response.data.results.reduce((acc, item) => {
                const existingArticle = acc.find(article => article.Artikel === item.artikel);

                if (existingArticle) {
                    existingArticle.Verkaufsmenge += item.verkaufsmenge;
                } else {
                    acc.push({
                        Artikel: item.artikel,
                        Verkaufsmenge: item.verkaufsmenge,
                        VerkaufsmengeVorjahr: 0,  // Vorjahreswert zunächst auf 0 setzen
                        Differenz: 0,  // Differenz wird später berechnet
                        Prozent: 0     // Prozentuale Veränderung wird später berechnet
                    });
                }

                return acc;
            }, []);

            // Vorjahresdaten hinzufügen
            responseLastYear.data.results.forEach(item => {
                const existingArticle = aggregatedData.find(article => article.Artikel === item.artikel);
                if (existingArticle) {
                    existingArticle.VerkaufsmengeVorjahr += item.verkaufsmenge;
                }
            });

            // Berechne Differenz und Prozent
            aggregatedData.forEach(article => {
                article.Differenz = article.Verkaufsmenge - article.VerkaufsmengeVorjahr;
                article.Prozent = article.VerkaufsmengeVorjahr !== 0
                    ? (((article.Verkaufsmenge / article.VerkaufsmengeVorjahr) - 1) * 100).toFixed(2)+ ' %'
                    : 0; // Prozentwert auf 0 setzen, wenn der Vorjahreswert 0 ist
            });

            const newArticleColumns = [
                { id: 'Artikel', accessorKey: 'Artikel', header: 'Artikel' },
                { id: 'Verkaufsmenge', accessorKey: 'Verkaufsmenge', header: 'Verkaufsmenge' },
                { id: 'VerkaufsmengeVorjahr', accessorKey: 'VerkaufsmengeVorjahr', header: 'Verkaufsmenge Vorjahr' },
                { id: 'Prozent', accessorKey: 'Prozent', header: '%' },
                { id: 'DiffVJ', accessorKey: 'Differenz', header: 'Diff VJ' }
            ];

            setArticleColumns(newArticleColumns);
            setArticleData(aggregatedData);

        } catch (error) {
            console.error('An error occurred while fetching article data:', error);
        }
    };

    const muiTableBodyCellProps = ({ cell }) => ({
        sx: {
            backgroundColor: cell.column.id === 'DiffVJ'
                ? cell.getValue() > 0
                    ? 'rgba(0, 255, 0, 0.1)' 
                    : 'rgba(255, 0, 0, 0.1)' 
                : 'inherit',
        },
    });

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>
                Artikel in Warengruppe {warengruppeName}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <VariableTable
                    data={articleData}
                    columns={articleColumns}
                    muiTableBodyCellProps={muiTableBodyCellProps}
                />
            </DialogContent>
        </Dialog>
    );
};

export default ArticleTablePopup;
