import React, { useEffect, useState } from 'react';
import Layout from "../../components/layout/Tagesreport/Tagesreport";
import { Box, Container, Grid, Typography, TableContainer, TableCell, Paper, Table,TableHead, TableRow, TableBody  } from "@mui/material";
import dayjs from "dayjs";
import CircularProgress from "@mui/material/CircularProgress";
import { getLast6WeeksSameWeekday, getSameWeekdayOneYearBack } from "../../utils/DateUtils";
import { calculateTotal, calculateTotalAverage, roundToDecimals, calculatePercentageDifference } from "../../utils/mathStuff";
import VerkaufsgebietFilterSelection from "../../components/tagesreport/VerkaufsgebietFilterSelection";
import DataCard from "../../components/card/TagesreportDataCard";
import { useTheme } from '@mui/material/styles';
import BigTableCard from "../../components/card/BigTableCard";
import TagesreportDetailsPopup from "../../components/popup/TagesreportDetailsPopup";
import ButtonGroupFilter from "../../components/filter/ButtonGroupFilter";
import axiosInstance from "../../services/axiosInstance";
import PageHeader from '../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';
import { formatPercentageWithoutMultiplier } from "../../utils/mathStuff";
import { formatCurrency, tausenderTrennung } from "../../utils/mathStuff";

const Main = () => {
    const theme = useTheme();
    const [datum, setDatum] = useState(dayjs().subtract(1, 'day').format('YYYY-MM-DD'));
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [verkaufsgebietOptions, setVerkaufsgebietOptions] = useState([]);
    const [verkaufsgebietFilter, setVerkaufsgebietFilter] = useState(null);
    const [last6WeeksData, setLast6WeeksData] = useState([]);
    const [selectedFiliale, setSelectedFiliale] = useState(null);
    const [popupOpen, setPopupOpen] = useState(false);
    const [greeting, setGreeting] = useState('');
    const [userName, setUserName] = useState('');
    const [datumOptions, setDatumOptions] = useState([]);
    
    
    const letzten6WochenDatum = getLast6WeeksSameWeekday(datum);

    const handleRowClick = (row) => {
        const { Datum, Filiale } = row;
        setSelectedFiliale({ Datum, Filiale });
        setPopupOpen(true);
    };

    const setGreetingMessage = () => {
        const currentHour = dayjs().hour();
        if (currentHour < 12) {
            setGreeting('Guten Morgen');
        } else if (currentHour < 18) {
            setGreeting('Hallo');
        } else {
            setGreeting('Guten Abend');
        }
    };

    const generateLast4DaysOptions = () => {
        const options = [];
        for (let i = 1; i <= 4; i++) {
            const date = dayjs().subtract(i, 'day').format('YYYY-MM-DD');
            options.push({ label: dayjs(date).format('DD.MM'), value: date });
        }
        return options;
    };
    

    useEffect(() => {
        setGreetingMessage(); // Set greeting message based on the time of day

        // Fetch the current user when the component mounts
        const fetchCurrentUser = async () => {
            try {
                const response = await axiosInstance.get("auth/current_user/");
                if (response.data && response.data.username) {
                    setUserName(response.data.username);
                }
            } catch (error) {
                console.error("Fehler beim Laden des Benutzers:", error);
            }
        };

        fetchCurrentUser();
        fetchVerkaufsgebietOptions();
        setDatumOptions(generateLast4DaysOptions());
        fetchData(); // Fetch data for the default date
    }, [datum]);

    useEffect(() => {
        fetchVerkaufsgebietOptions();
    }, []);

    useEffect(() => {
        fetchData();
    }, [datum, verkaufsgebietFilter]);

    const fetchVerkaufsgebietOptions = async () => {
        try {
            const response = await axiosInstance.get('/stammdaten/unique-verkaufsgebiet/');
            setVerkaufsgebietOptions(response.data.map(item => ({ label: item, value: item })));
        } catch (error) {
            console.error('Error while fetching VerkaufsgebietOptions', error);
        }
    };

    const fetchData = async () => {
        const formattedDatum = dayjs(datum).format('YYYY-MM-DD');
        const vorjahresDatum = getSameWeekdayOneYearBack(datum);
    
        try {
            setLoading(true);
    
            // API Calls für den aktuellen Tag, Vorjahr und die letzten 6 Wochen
            const [response, responseVorjahr, ...last6WeeksResponses] = await Promise.all([
                axiosInstance.get("/fact/kundenstatistik/", {
                    params: {
                        startdatum: formattedDatum,
                        enddatum: formattedDatum,
                        mindestKunden: 1,
                        verkaufsgebiet: verkaufsgebietFilter?.value,
                    },
                }),
                axiosInstance.get("/fact/kundenstatistik/", {
                    params: {
                        startdatum: vorjahresDatum,
                        enddatum: vorjahresDatum,
                        mindestKunden: 1,
                        verkaufsgebiet: verkaufsgebietFilter?.value,
                    },
                }),
                ...letzten6WochenDatum.map(date => axiosInstance.get("/fact/kundenstatistik/", {
                    params: {
                        startdatum: date,
                        enddatum: date,
                        mindestKunden: 1,
                        verkaufsgebiet: verkaufsgebietFilter?.value,
                    },
                })),
            ]);
    
            let vorjahresData = [];
            if (Array.isArray(responseVorjahr.data.results)) {
                vorjahresData = responseVorjahr.data.results.map(item => ({
                    Filiale: item.filiale,
                    VJ_Umsatz: item.umsatz,
                    VJ_Kunden: item.kundenanzahl,
                    VJ_Retourquote: calculateRetourquote(item),
                }));
            }
    
            const last6WeeksResult = last6WeeksResponses.flatMap(res => res.data.results);
            console.log(last6WeeksResult); // Hier prüfen, ob die Anlieferwerte vorhanden sind
            setLast6WeeksData(last6WeeksResult);
    
            const averageUmsatzLast6Weeks = (filiale) => {
                const filteredData = last6WeeksResult.filter(item => item.filialnummer.filiale === filiale && item.umsatz > 0);
                const totalUmsatz = filteredData.reduce((acc, item) => acc + item.umsatz, 0);
                return filteredData.length ? totalUmsatz / filteredData.length : 0;
            };
    
            if (Array.isArray(response.data.results)) {

                response.data.results.forEach(item => {
                    console.log('Current Item:', item); // Überprüfen der aktuellen Datenstruktur
                });


                const mappedData = response.data.results.map(item => {
                    const vorjahresItem = vorjahresData.find(vorjahresItem => {
                        console.log('Matching VorjahresItem:', vorjahresItem, 'Filiale:', item.filiale, vorjahresItem.Filiale); // Überprüfen der Vorjahresdatenstruktur
                        return vorjahresItem.Filiale === item.filiale;
                    });
                
                    const VJ_Umsatz = vorjahresItem?.VJ_Umsatz ?? 0;
                    const VJ_Kunden = vorjahresItem?.VJ_Kunden ?? 0;
                    const UmsatzDiff = calculatePercentageDifference(item.umsatz, VJ_Umsatz);
                    const KundenDiff = calculatePercentageDifference(item.kundenanzahl, VJ_Kunden);
                
                    const avgUmsatz6W = averageUmsatzLast6Weeks(item.filialnummer.filiale);
                    const Umsatz6WDiff = calculatePercentageDifference(item.umsatz, avgUmsatz6W);
                
                    // Neue Vorjahreswerte und Differenzen für Retourquote und Stundenleistung
                    const VJ_Retourquote = vorjahresItem?.VJ_Retourquote ?? 0;
                    const RetourquoteDiff = calculatePercentageDifference(calculateRetourquote(item), VJ_Retourquote);
                
                    const VJ_Stundenleistung = roundToDecimals(VJ_Umsatz / (item.produktivstunden || 1), 2);
                    const Stundenleistung = roundToDecimals(item.umsatz / (item.produktivstunden || 1), 2);
                    const StundenleistungDiff = calculatePercentageDifference(Stundenleistung, VJ_Stundenleistung);

                    console.log('Output', vorjahresItem);
                
                    return {
                        Datum: item.datum,
                        Filiale: item.filiale,
                        Umsatz:item.umsatz,
                        formatiertUmsatz: formatCurrency(item.umsatz),
                        DurchschnittUmsatz6W: avgUmsatz6W,
                        DurchschnittUmsatz6W_Diff: `${roundToDecimals(Umsatz6WDiff, 2)} %`,
                        VJ_Umsatz: roundToDecimals(VJ_Umsatz, 2),
                        VJ_Umsatz_Diff: `${roundToDecimals(UmsatzDiff, 2)} %`,
                        Kunden: item.kundenanzahl,
                        VJ_Kunden: roundToDecimals(VJ_Kunden, 2),
                        VJ_Kunden_Diff: `${roundToDecimals(KundenDiff, 2)} %`,
                        Euro_h: roundToDecimals(Stundenleistung, 2),
                        h: roundToDecimals(item.produktivstunden, 0),
                        Euro_Kunde: roundToDecimals(item.umsatz / item.kundenanzahl, 2),
                        Retourquote: `${roundToDecimals(calculateRetourquote(item) * 100, 2)} %`,
                        Retourquote_Diff: `${roundToDecimals(RetourquoteDiff, 2)} %`,
                        VJ_Retourquote: `${roundToDecimals(VJ_Retourquote * 100, 2)} %`,
                        VJ_Stundenleistung: roundToDecimals(VJ_Stundenleistung, 2),
                        Stundenleistung_Diff: roundToDecimals(StundenleistungDiff, 2),
                        Anlieferwert: item.anlieferwert,
                        Retourwert: item.retourwert,
                        Korrekturwert: item.korrekturwert,
                        
                    };
                    
                });
                
                setData(mappedData);
            } else {
                console.error('Unexpected response structure for current data:', response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const calculateRetourquote = (item) => {
        const { retourwert = 0, anlieferwert = 0, korrekturwert = 0 } = item;
        const totalWert = anlieferwert + korrekturwert;
        return totalWert > 0 ? retourwert / totalWert : 0;
    };
    

    const totalUmsatz = roundToDecimals(calculateTotal(data, 'Umsatz'), 0);
    const totalVorjahresUmsatz = roundToDecimals(calculateTotal(data, 'VJ_Umsatz'), 0);
    const totalVorjahresUmsatzDiff = calculatePercentageDifference(totalUmsatz, totalVorjahresUmsatz);
    const totalKunden = roundToDecimals(calculateTotal(data, 'Kunden'), 0);
    const totalVorjahresKundenDiff = calculatePercentageDifference(totalKunden, calculateTotal(data, 'VJ_Kunden'));
    const totalAverageUmsatz6W = roundToDecimals(calculateTotal(data, 'DurchschnittUmsatz6W'), 0);
    const totalAverageUmsatz6WDiff = calculatePercentageDifference(totalUmsatz, totalAverageUmsatz6W);
    const totalStunden= roundToDecimals(calculateTotal(data, 'h'), 0);
    const totalStundenleistung = roundToDecimals(totalUmsatz/totalStunden);
    const totalAnlieferwert = roundToDecimals(calculateTotal(data, 'Anlieferwert'), 0);
    const totalRetourwert = roundToDecimals(calculateTotal(data, 'Retourwert'), 0);
    const totalKorrekturwert = roundToDecimals(calculateTotal(data, 'Korrekturwert'), 0);
    const totalRetourquote = totalRetourwert/(totalKorrekturwert+totalAnlieferwert);
    // Vorjahreswerte müssen schon vorhanden sein, z. B. in vorjahresData
const totalVJStundenleistung = calculateTotal(data, 'VJ_Stundenleistung');
const totalStundenleistungDiff = calculatePercentageDifference(totalStundenleistung, totalVJStundenleistung);
const totalVJRetourquote = calculateTotal(data, 'VJ_Retourquote');
const totalRetourquoteDiff = calculatePercentageDifference(totalRetourquote, totalVJRetourquote);

console.log("UmsatzVJ", calculateTotal(data, 'VJ_Umsatz'), "RetourquoteVJ", totalVJRetourquote );
console.log("UmsatzVJneu", totalVorjahresUmsatz );


    const calculateTotalsForLast6Weeks = (data, metrics) => {
        const totals = metrics.reduce((acc, metric) => {
            acc[metric] = [];
            return acc;
        }, {});
    
        letzten6WochenDatum.forEach((date) => {
            metrics.forEach((metric) => {
                const total = data.reduce((acc, item) => {
                    if (dayjs(item.datum).startOf('day').isSame(dayjs(date).startOf('day'))) {
                        return acc + (item[metric] || 0);
                    }
                    return acc;
                }, 0);
    
                totals[metric].push({ total });
            });
        });
    
        totals['umsatz'].push({ total: totalUmsatz });
        totals['kundenanzahl'].push({ total: totalKunden });
        totals['korrekturwert'].push({ total: totalKorrekturwert });
        totals['anlieferwert'].push({ total: totalAnlieferwert });
        totals['retourwert'].push({ total: totalRetourwert });
        totals['produktivstunden'].push({ total: calculateTotal(data, 'h') });
    
        // Berechnung der Stundenleistung
        totals['stundenleistung'] = totals['umsatz'].map((umsatzItem, index) => {
            const produktivstundenItem = totals['produktivstunden'][index];
            const stundenleistung = produktivstundenItem.total !== 0 ? umsatzItem.total / produktivstundenItem.total : 0;
            return { total: stundenleistung };
        });
    
        // Berechnung der Retourquote
        totals['retourquote'] = totals['retourwert'].map((retourwertItem, index) => {
            const anlieferwertItem = totals['anlieferwert'][index];
            const korrekturwertItem = totals['korrekturwert'][index];
            const retourquote = (anlieferwertItem.total + korrekturwertItem.total) !== 0
                ? retourwertItem.total / (anlieferwertItem.total + korrekturwertItem.total)
                : 0;
            return { total: retourquote };
        });
    
        return totals;
    };
    
    const metrics = ['umsatz', 'kundenanzahl', 'produktivstunden', 'anlieferwert', 'retourwert', 'korrekturwert'];
    const totalsLast6Weeks = calculateTotalsForLast6Weeks(last6WeeksData, metrics);
    // const totalUmsatzLast6Weeks = totalsLast6Weeks.umsatz.reduce((sum, item) => sum + item.total, 0);
    console.log("letzte 6 Wochen", totalsLast6Weeks);
    // const totalProduktivstundenLast6Weeks = totalsLast6Weeks.produktivstunden.reduce((sum, item) => sum + item.total, 0);
    // console.log("Stunden 6 Wochen", totalProduktivstundenLast6Weeks);

    // const chartDataStundenleistung = totalProduktivstundenLast6Weeks !== 0 ? totalUmsatzLast6Weeks / totalProduktivstundenLast6Weeks : 0;


    const columns = [
        { accessorKey: 'Filiale', header: 'Filiale', size: 200 },
        { accessorKey: 'formatiertUmsatz', header: 'Umsatz', size: 200 },
        { accessorKey: 'DurchschnittUmsatz6W_Diff', header: 'ø6W %', size: 150 },
        { accessorKey: 'VJ_Umsatz_Diff', header: 'VJ %', size: 150 },
        { accessorKey: 'Kunden', header: 'Kunden', size: 150 },
        { accessorKey: 'VJ_Kunden_Diff', header: 'VJ %', size: 150 },
        { accessorKey: 'Euro_h', header: '€/h', size: 140 },
        { accessorKey: 'h', header: 'h', size: 100 },
        { accessorKey: 'Euro_Kunde', header: '€/Kunde', size: 140 },
        { accessorKey: 'Retourquote', header: 'Retourquote', size: 140, cell: ({ cell }) => `${roundToDecimals(cell.getValue() * 100, 2)} %` },
    ];
    

    const footerValues = {
        'Filiale': 'Gesamt',
        'Umsatz': roundToDecimals(totalUmsatz, 0),
        'DurchschnittUmsatz6W_Diff': roundToDecimals(totalAverageUmsatz6WDiff, 2),
        'VJ_Umsatz_Diff': roundToDecimals(totalVorjahresUmsatzDiff, 2),
        'Kunden': roundToDecimals(totalKunden, 0),
        'VJ_Kunden_Diff': roundToDecimals(totalVorjahresKundenDiff, 2),
        'Euro_h': roundToDecimals(calculateTotalAverage(data, 'Euro_h'), 2),
        'h': roundToDecimals(calculateTotal(data, 'h'), 0),
        'Euro_Kunde': roundToDecimals(calculateTotalAverage(data, 'Euro_Kunde'), 2),
        'RetourquoteTest': `${roundToDecimals(calculateTotalAverage(data, 'Retourquote') * 100, 2)} %`,
    };
    
    const stundenleistungData = [
        {
            datum: dayjs(datum).format('YYYY-MM-DD'),
            total: roundToDecimals(totalStundenleistung, 2),
        },
        ...letzten6WochenDatum.map((date, index) => ({
            datum: dayjs(date).format('YYYY-MM-DD'),
            total: roundToDecimals(totalsLast6Weeks.stundenleistung[index].total, 2),
        })),
    ].sort((a, b) => new Date(a.datum) - new Date(b.datum));




    return (
        <Layout>
            <Container maxWidth={false} sx={{ padding: '0 16px' }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
                    

                    <PageHeader
    title={`Tagesreport vom ${dayjs(datum).format('dddd')} ${datum}`} // Dynamischer Wert in geschweiften Klammern
    subtitle={`${greeting}, ${userName}! Hier der Tagesreport für den ${datum} Wir wünschen dir einen erfolgreichen Tag!`}
    Icon={BarChartIcon}
/>


                    {/* Datumsauswahl über ButtonGroupFilter */}
                    <ButtonGroupFilter 
                        options={datumOptions} 
                        value={{ value: datum }} 
                        onChange={(option) => setDatum(option ? option.value : datum)} 
                    />
                </Box>


                <Box sx={{ mt: 4 }}>
    <Grid container spacing={2}>
        <Grid item xs={3}>
            <DataCard
                chartData={totalsLast6Weeks.umsatz.map(item => item.total)}
                category="Umsatz"
                value={`${tausenderTrennung(totalUmsatz)}€`}
                subHeader={`${roundToDecimals(totalVorjahresUmsatzDiff, 2)}%`} // Umsatz-Differenz
            />
        </Grid>
        <Grid item xs={3}>
            <DataCard
                chartData={totalsLast6Weeks.kundenanzahl.map(item => item.total)}
                category="Kundenanzahl"
                value={tausenderTrennung(totalKunden)}
                subHeader={`${roundToDecimals(totalVorjahresKundenDiff, 2)}%`} // Kunden-Differenz
            />
        </Grid>
        
        <Grid item xs={3}>
            <DataCard
                chartData={stundenleistungData.map(item => item.total)} // Stundenleistung der letzten 6 Wochen + aktuelle Woche
                category="Stundenleistung"
                value={roundToDecimals(totalStundenleistung, 2)} // Stundenleistung der aktuellen Woche
                
            />
        </Grid>

        <Grid item xs={3}>
            <DataCard
                chartData={totalsLast6Weeks.retourquote.map(item => item.total)}
                category="Retourquote"
                value={`${roundToDecimals(totalRetourquote*100, 2)}%`}
                
            />
        </Grid>
    </Grid>
</Box>


                <br />
                <VerkaufsgebietFilterSelection
                    verkaufsgebietFilter={verkaufsgebietFilter}
                    setVerkaufsgebietFilter={setVerkaufsgebietFilter}
                    verkaufsgebietOptions={verkaufsgebietOptions}
                />
                <br />
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <CircularProgress size={80} />
                    </div>
                ) : (
                    <BigTableCard
                        title='Filialübersicht'
                        data={data}
                        columns={columns}
                        onRowClick={handleRowClick}
                        footerValues={footerValues}
                    />
                )}
                <TagesreportDetailsPopup
                    open={popupOpen}
                    handleClose={() => setPopupOpen(false)}  // Here you pass the function to close the popup
                    filialeData={selectedFiliale}
                />
            </Container>
            



        </Layout>
    );
}

export default Main;
