import {Box, Typography} from "@mui/material";
import ArtikelDetailPopup from "../popup/ArtikelDetailPopup";
import {useDrag} from "react-dnd";
import {useState} from "react";


const DraggableText = ({ artikel }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'text',
        item: { artikelData: artikel },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    const [openPopup, setOpenPopup] = useState(false); // State to control popup visibility

    const handleTextClick = () => {
        setOpenPopup(true); // Open the popup on text click
    };

    const handleClosePopup = () => {
        setOpenPopup(false); // Close the popup
    };

    return (
        <>
            <Box
                ref={drag}
                sx={{
                    opacity: isDragging ? 0.5 : 1,
                    cursor: 'pointer',
                    padding: 0.5,
                    border: '1px solid',
                    borderColor: 'primary.main',
                    borderRadius: 1,
                    display: 'inline-block',
                    marginRight: 1,
                }}
                onClick={handleTextClick}
            >
                <Typography>{artikel.artikelbezeichnung} ({artikel.umsatz}€)</Typography>
            </Box>
            <ArtikelDetailPopup open={openPopup} onClose={handleClosePopup} artikel={artikel} />
        </>
    );
};

export default DraggableText
