import * as React from 'react';
import Typography from '@mui/material/Typography';
import {CardHeader, Stack, Card, Box, CardContent, Paper, CardActionArea} from '@mui/material';
import BasicBars from '../charts/BarChart';
import EuroIcon from '@mui/icons-material/Euro';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import TimerIcon from '@mui/icons-material/Timer';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import {useNavigate} from "react-router-dom";
import {useTheme} from '@mui/material/styles';
import Score from '../score/Score';

const getCategoryIcon = (category) => {
    const iconProps = {sx: {fontSize: '2.0rem', color: 'primary.main', mr: 1}};

    switch (category) {
        case 'Umsatz':
            return <EuroIcon {...iconProps} />;
        case 'Kundenanzahl':
            return <ShoppingCartIcon {...iconProps} />;
        case 'Kundenbon':
            return <AddShoppingCartIcon {...iconProps} />;
        case 'Leistung':
            return <TimerIcon {...iconProps} />;
        case 'Krankenquote':
            return <VaccinesIcon {...iconProps} />;
        case 'Fluktuation':
            return <GroupRemoveIcon {...iconProps} />;
        case 'Bedarf':
            return <ManageSearchIcon {...iconProps} />;
        case 'Auslastung':
            return <Battery3BarIcon {...iconProps} />;
        case 'Wachstum':
            return <AutoGraphIcon {...iconProps} />;
        case 'Verfügbarkeit':
            return <HighlightOffIcon {...iconProps} />;
        case 'RetoureA':
            return <PublishedWithChangesIcon {...iconProps} />;
        case 'RetoureBC':
            return <CurrencyExchangeIcon {...iconProps} />;
        default:
            return null;
    }
};

export default function DataCard({data, category, sector, categoryData, specialTitle}) {
    const navigate = useNavigate();
    const theme = useTheme();

    const handleNavigate = () => {
        navigate(`/scorecards/filiale/monat/${sector}-${category.toLowerCase()}`); // Adjust the path as necessary
    };

    return (
        <Card sx={{
            padding: '2px',
            backgroundColor: 'rgba(255, 255, 255, 0.7)', // Weiß mit 50% Deckkraft
            backdropFilter: 'blur(10px)', // Optional: Unschärfe-Effekt, um den Hintergrund weicher wirken zu lassen
        }}>
            <CardActionArea onClick={handleNavigate}>
                <CardHeader
                    className="db_datacard_cardheader"
                    title={
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Stack direction="row" alignItems="center">
                                {getCategoryIcon(category)}
                                <Typography variant="h2" className="db_datacard_category">
                                    {specialTitle ? specialTitle : category}
                                </Typography>
                            </Stack>
                            <Score score={data.length > 0 ? data.slice(-1)[0][category] : null}></Score>
                        </Stack>
                    }
                />
                <CardContent className="db_datacard_cardcontent">
                    {categoryData && (
                        <Typography className="db_datacard_categorydata">
                            {categoryData}
                        </Typography>
                    )}
                    <Stack direction="row" className="db_datacard_stack" alignItems="center" justifyContent="space-between" spacing={2}>
                        {data.length > 0 && (
                            <Box sx={{flexGrow: 1, ml: 2}}>
                                <BasicBars data={data} category={category}/>
                            </Box>
                        )}
                    </Stack>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
