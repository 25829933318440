// PieChartTotalValues.js
import React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { useTheme } from "@mui/material/styles";

const PieChartTotalValues = ({ data, metric, height, width }) => {
  const theme = useTheme();

  // Definiere die Farben aus dem Theme
  const COLORS = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.chart.yellow,
    theme.palette.chart.orange,
    theme.palette.chart.red,
    theme.palette.chart.pink,
    theme.palette.chart.purpleLight,
    theme.palette.chart.purpleDark,
  ];

  // Bereite die Daten für das Kreisdiagramm vor basierend auf dem Metric
  const chartData = data.map((item, index) => ({
    id: `Filialtyp ${item.filialtyp}`,
    value: item[metric],
    label: `${item.filialtyp}: ${item[metric].toFixed(2)}`,
    color: COLORS[index % COLORS.length],
  }));

  return (
    <PieChart
      series={[
        {
          data: chartData,
          innerRadius: 60,
          outerRadius: 100,
          label: {
            visible: true,
            position: 'outside',
            connector: true,
            connectorOffset: 10,
            fontSize: 10,
            fontWeight: 'normal',
          },
        },
      ]}
      width={width || 250}
      height={height || 220}
      margin={{ top: 10, bottom: 10, left: 10, right: 10 }}
      slotProps={{ legend: { hidden: true } }}
    />
  );
};

export default PieChartTotalValues;
