import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

const Analyse = () => {
    return (
        <Paper sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom>
                Analyse
            </Typography>
            <Typography variant="body1">
                Hier können Analyse-Daten angezeigt werden.
            </Typography>
        </Paper>
    );
};

export default Analyse;
