import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

const DropdownFilter = ({ label, options, value, onChange }) => {
  return (
    <Autocomplete
      disablePortal
      id={`${label.toLowerCase()}-combo-box`}
      options={options}
      getOptionLabel={(option) => option.label || ''}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      sx={{ width: 230, marginBottom: 1 }} // Verkleinert die Breite und den unteren Abstand
      renderInput={(params) => (
        <TextField 
          {...params} 
          label={label} 
          variant="outlined" 
          size="small" // Verkleinert die Höhe des TextField
          sx={{ fontSize: '0.8rem' }} // Verkleinert die Schriftgröße
        />
      )}
    />
  );
};

export default DropdownFilter;
