import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Tabs, Tab, Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VariableTable from '../table/VariableTable';  // Importiere die VariableTable-Komponente
import axiosInstance from '../../services/axiosInstance';

const ABCDialog = ({ open, onClose, filiale, monat }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [warengruppeData, setWarengruppeData] = useState({});
    const [columns, setColumns] = useState([
        { accessorKey: 'artikel', header: 'Artikel' },
        { accessorKey: 'umsatz', header: 'Umsatz' },
        { accessorKey: 'ABCValue', header: 'ABC-Wert' },
        { accessorKey: 'umsatzKummuliert', header: 'Kummuliert' },
        { accessorKey: 'umsatzProzent', header: '%' },
    ]);

    useEffect(() => {
        console.log("ABCDialog props - filiale:", filiale, "monat:", monat, "open:", open);
        if (filiale && monat && open) {
            fetchData();
        }
    }, [filiale, monat, open]);
    
    const fetchData = async () => {
        try {
            console.log("Fetching data for filiale:", filiale, "monat:", monat);
            const response = await axiosInstance.get('scorecard_month_article/abc/', {
                params: {
                    filiale: filiale,
                    jahrMonat: monat,
                }
            });
    
            console.log("API Response:", response.data);
    
            if (response.data && response.data.results) {
                const formattedData = response.data.results.map(item => ({
                    artikel: item.artikelnummer.artikel,
                    umsatz: item.umsatz.toFixed(0),
                    ABCValue: item.ABCValue,
                    umsatzKummuliert: (item.kumUmsatz * 100).toFixed(2) + ' %', // Format as percentage and round to 2 decimal places
                    umsatzProzent: (item.umsatzProzent * 100).toFixed(2) + ' %' // Format as percentage and round to 2 decimal places
                }));
    
                // Group by Warengruppe and count A articles
                const warengruppeData = response.data.results.reduce((acc, item) => {
                    if (item.ABCValue === 'A') {
                        const wgNummer = item.warengruppe|| 'Unbekannt'; // Hier greifen wir auf die warengruppennummer direkt zu
                        acc[wgNummer] = (acc[wgNummer] || 0) + 1;
                    }
                    return acc;
                }, {});
    
                console.log("Formatted Data:", formattedData);
                console.log("Warengruppe Data:", warengruppeData);
                setTableData(formattedData);
                setWarengruppeData(warengruppeData);
            } else {
                setTableData([]);
                setWarengruppeData({});
            }
        } catch (error) {
            console.error('Error fetching ABC data:', error);
            setTableData([]);
            setWarengruppeData({});
        }
    };
    
    const handleChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                ABC Analyse
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Tabs value={tabIndex} onChange={handleChange} aria-label="ABC Analysis Tabs">
                    <Tab label="ABC Daten" />
                    <Tab label="Struktur ABC" />
                </Tabs>
                <TabPanel value={tabIndex} index={0}>
                    <VariableTable
                        columns={columns}
                        data={tableData}
                        muiTableBodyCellProps={{
                            sx: {
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                },
                            },
                        }}
                    />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="h6">A-Artikel pro Warengruppe</Typography>
                        {Object.entries(warengruppeData).map(([wgNummer, count]) => (
                            <Typography key={wgNummer}>Warengruppe {wgNummer}: {count} A-Artikel</Typography>
                        ))}
                    </Box>
                </TabPanel>
            </DialogContent>
        </Dialog>
    );
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

export default ABCDialog;
