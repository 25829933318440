import React from 'react';
import { Paper, Typography, useTheme } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';

const BarChartVerfuegbarkeit = ({ title, data, labels }) => {
    const theme = useTheme();

    return (
        <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h6" gutterBottom>
                {title}
            </Typography>
            <BarChart
                xAxis={[
                    {
                        scaleType: 'band',
                        data: labels, // Labels als x-Achse verwenden
                        show: true, // X-Achse anzeigen
                        tickSize: 5, // Tick-Größe der X-Achse
                        label: null, // X-Achsenbeschriftung (falls nötig anpassbar)
                    },
                ]}
                yAxis={[
                    {
                        show: true, // Y-Achse anzeigen
                        label: null, // Y-Achsenbeschriftung (falls nötig anpassbar)
                    },
                ]}
                series={[
                    {
                        data: data, // Daten für die Y-Achse
                        label: title,
                        color: theme.palette.primary.main, // Farbe des Balkens
                    },
                ]}
                height={200} // Höhe des Charts
                margin={{ top: 10, bottom: 20, left: 30, right: 10 }} // Ränder des Charts
                isAnimationActive={false} // Animation deaktivieren
                width={400}  // Füge eine feste Breite hinzu

            />
        </Paper>
    );
};

export default BarChartVerfuegbarkeit;
