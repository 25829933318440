import React from "react";
import { Box, Typography } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import { useTheme } from "@mui/material/styles";

const PieChartWarengruppe = ({ data }) => {
    const theme = useTheme();

    // Definiere die Farben aus dem Theme
    const COLORS = [
        theme.palette.primary.main,
        theme.palette.secondary.main,
        theme.palette.chart.yellow,
        theme.palette.chart.orange,
        theme.palette.chart.red,
        theme.palette.chart.pink,
        theme.palette.chart.purpleLight,
        theme.palette.chart.purpleDark,
    ];

    // Bereite die Daten für das Kreisdiagramm mit Prozentsätzen vor
    const totalVerkaufsmenge = data.reduce((acc, item) => acc + item.Verkaufsmenge, 0);

    const chartData = data.map((item, index) => ({
        id: `Warengruppe ${item.Warengruppe}`,
        value: item.Verkaufsmenge,
        label: `${item.Warengruppe}: ${((item.Verkaufsmenge / totalVerkaufsmenge) * 100).toFixed(2)}%`,
        color: COLORS[index % COLORS.length],
    }));

    return (
            <PieChart
                series={[
                    {
                        data: chartData,
                        innerRadius: 60,
                        outerRadius: 100,
                        label: {
                            visible: true,
                            position: 'outside',
                            connector: true,
                            connectorOffset: 10,
                            fontSize: 10,
                            fontWeight: 'normal',
                        },
                    },
                ]}
                width={250}
                height={300}
                margin={{ top: 10, bottom: 10, left: 10, right: 10 }}  // Adjusted right margin for space
                slotProps={{ legend: { hidden: true } }}
            />


    );
};

export default PieChartWarengruppe;
