import axiosInstance from "../services/axiosInstance";
import {useAuth} from "../provider/authProvider";

const useLogin = () => {
    const {setToken, setRefreshTokenExpiredWithStorage} = useAuth();

    const login = async (username, password) => {
        try {
            const response = await axiosInstance.post('/auth/login/', {username, password});
            setRefreshTokenExpiredWithStorage(false);
            // Set the access token in React state
            setToken(response.data.access);

            return {success: true};
        } catch (error) {
            console.error('Login failed:', error.message);
            let errorMessage = 'Login failed. ';
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                if (error.response.status === 401) {
                    errorMessage += 'Invalid username or password. Please try again.';
                } else if (error.response.data && error.response.data.detail) {
                    errorMessage += error.response.data.detail;
                } else {
                    errorMessage += 'An unexpected error occurred. Please try again later.';
                }
            } else if (error.request) {
                // The request was made but no response was received
                errorMessage += 'No response received from the server. Please check your internet connection and try again.';
            } else {
                // Something happened in setting up the request that triggered an Error
                errorMessage += 'An unexpected error occurred. Please try again later.';
            }
            return {error: errorMessage};
        }
    };

    return {login};
};

export default useLogin;
