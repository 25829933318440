import Layout from "../../components/layout/Tagesreport/Tagesreport";
import { Box, Container, Grid, Paper} from "@mui/material";
import { TitleBar } from "../../components/layout/TitleBar";
import React, { useEffect, useState } from "react";
import VerkaufsgebietFilterSelection from "../../components/tagesreport/VerkaufsgebietFilterSelection";
import CircularProgress from "@mui/material/CircularProgress";
import dayjs from "dayjs";
import axiosInstance from "../../services/axiosInstance";
import { roundToDecimals } from "../../utils/mathStuff";
import { formatPercentageWithoutMultiplier } from "../../utils/mathStuff";
import { formatCurrency, tausenderTrennung } from "../../utils/mathStuff";
import VariableTable from "../../components/table/VariableTable";
import BigTableCard from "../../components/card/BigTableCard";
import { useTheme } from '@mui/material/styles';
import TagesreportRetoureWarengruppeDetail from '../../components/popup/TagesreportRetoureWarengruppeDetail';
import TagesreportDetailsPopup from "../../components/popup/TagesreportDetailsPopup";
import BarChartHorizontal from '../../components/charts/BarChartHorizontal';
import PageHeader from '../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';
import { getLast6WeeksSameWeekday } from "../../utils/DateUtils"; // Helper für die letzten 6 Wochen



const Retouren = () => {
    const theme = useTheme();
    const [Datum, setDatum] = useState(dayjs().subtract(1, 'day').format('YYYY-MM-DD'));
    const [kundenStatistikData, setKundenStatistikData] = useState([]);
    const [warengruppeData, setWarengruppeData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [verkaufsgebietOptions, setVerkaufsgebietOptions] = useState([]);
    const [selectedFiliale, setSelectedFiliale] = useState(null);
    const [verkaufsgebietFilter, setVerkaufsgebietFilter] = useState(null);
    const [popupOpen, setPopupOpen] = useState(false);
    const date = new Date(Datum);
    const dayName = date.toLocaleDateString('de-DE', { weekday: 'long' });
    const [totals, setTotals] = useState({
        Umsatz: 0,
        Umsatz6WSchnitt: 0,
        Retourquote: 0,
        Retourmengen: 0,
        Retourwert: 0,
    });
    
    const [warengruppePopupOpen, setWarengruppePopupOpen] = useState(false); // Popup state
    const [selectedWarengruppe, setSelectedWarengruppe] = useState(null); // Selected Warengruppe

    useEffect(() => {
        fetchVerkaufsgebietOptions();
    }, []);

    useEffect(() => {
        fetchData();
    }, [Datum, verkaufsgebietFilter]);

    

    const fetchVerkaufsgebietOptions = async () => {
        try {
            const response = await axiosInstance.get('/stammdaten/unique-verkaufsgebiet/');
            setVerkaufsgebietOptions(response.data.map(item => ({ label: item, value: item })));
        } catch (error) {
            console.error('Error while fetching VerkaufsgebietOptions', error);
        }
    };


const fetchData = async () => {
    const formattedDatum = dayjs(Datum).format('YYYY-MM-DD');
    const letzten6WochenDatum = getLast6WeeksSameWeekday(Datum); // Die letzten 6 Wochen mit gleichem Wochentag

    try {
        setLoading(true);

        // Fetch data for the current day and the last 6 weeks
        const [kundenStatistikResponse, ...last6WeeksResponses] = await Promise.all([
            axiosInstance.get("/fact/facts/", {
                params: {
                    startdatum: formattedDatum,
                    enddatum: formattedDatum,
                    verkaufsgebiet: verkaufsgebietFilter?.value,
                },
            }),
            ...letzten6WochenDatum.map((weekDate) =>
                axiosInstance.get("/fact/facts/", {
                    params: {
                        startdatum: dayjs(weekDate).format('YYYY-MM-DD'),
                        enddatum: dayjs(weekDate).format('YYYY-MM-DD'),
                        verkaufsgebiet: verkaufsgebietFilter?.value,
                    },
                })
            ),
        ]);

        // Map data for the current day
        if (Array.isArray(kundenStatistikResponse.data.results)) {
            const last6WeeksFacts = last6WeeksResponses.flatMap(res => res.data.results);

            const groupedData = kundenStatistikResponse.data.results.reduce((acc, item) => {
                const filiale = item.filiale;
                if (!acc[filiale]) {
                    acc[filiale] = {
                        Filiale: filiale,
                        Umsatz: 0,
                        Umsatz6WSchnitt: 0, // Placeholder for the 6-week average
                        Retourwert: 0,
                        Lieferwert: 0,
                        Korrekturwert: 0,
                    };
                }
                acc[filiale].Umsatz += item.umsatz || 0;
                acc[filiale].Retourwert += item.retourwert || 0;
                acc[filiale].Lieferwert += item.lieferwert || 0;
                acc[filiale].Korrekturwert += item.korrekturwert || 0;
                return acc;
            }, {});

            // Calculate 6-week average Umsatz per Filiale
            last6WeeksFacts.forEach(item => {
                const filiale = item.filiale;
                if (groupedData[filiale]) {
                    groupedData[filiale].Umsatz6WSchnitt += item.umsatz || 0;
                }
            });

            // Now calculate the average for the last 6 weeks
            Object.keys(groupedData).forEach(filiale => {
                groupedData[filiale].Umsatz6WSchnitt = roundToDecimals(
                    groupedData[filiale].Umsatz6WSchnitt / letzten6WochenDatum.length, 2
                );
            });

            const mappedKundenStatistikData = Object.values(groupedData).map(item => ({
                Filiale: item.Filiale,
                Umsatz: roundToDecimals(item.Umsatz, 2),
                formatiertUmsatz: formatCurrency(item.Umsatz),
                Umsatz6WSchnitt: roundToDecimals(item.Umsatz6WSchnitt, 2),
                formatiertUmsatz6WSchnitt: formatCurrency(item.Umsatz6WSchnitt),
                Retourquote: `${((item.Retourwert / (item.Lieferwert + item.Korrekturwert)) * 100).toFixed(2)} %`,
            }));

            setKundenStatistikData(mappedKundenStatistikData);
            setTotals({
                Umsatz: roundToDecimals(mappedKundenStatistikData.reduce((acc, item) => acc + item.Umsatz, 0), 2),
                Umsatz6WSchnitt: roundToDecimals(mappedKundenStatistikData.reduce((acc, item) => acc + item.Umsatz6WSchnitt, 0), 2),
                Retourquote: roundToDecimals(mappedKundenStatistikData.reduce((acc, item) => acc + parseFloat(item.Retourquote), 0) / mappedKundenStatistikData.length, 2),
                Retourmengen: 0,
                Retourwert: 0
            });
        }

        // Fetch data for the second table (Warengruppe related data)
        const warengruppeResponse = await axiosInstance.get("/fact/facts/", {
            params: {
                startdatum: formattedDatum,
                enddatum: formattedDatum,
                verkaufsgebiet: verkaufsgebietFilter?.value,
            },
        });

        // Map data for the second table
        if (Array.isArray(warengruppeResponse.data.results)) {
            const groupedWarengruppeData = warengruppeResponse.data.results.reduce((acc, item) => {
                const warengruppe = item.warengruppe;
                if (!acc[warengruppe]) {
                    acc[warengruppe] = {
                        Warengruppe: warengruppe,
                        Retourmenge: 0,
                        Retourwert: 0,
                        Lieferwert: 0,
                        Korrekturwert: 0,
                    };
                }
                acc[warengruppe].Retourmenge += item.retourmenge || 0;
                acc[warengruppe].Retourwert += item.retourwert || 0;
                acc[warengruppe].Lieferwert += item.lieferwert || 0;
                acc[warengruppe].Korrekturwert += item.korrekturwert || 0;
                return acc;
            }, {});

            const mappedWarengruppeData = Object.values(groupedWarengruppeData)
                .filter(item => item.Retourmenge > 0)
                .map(item => ({
                    Warengruppe: item.Warengruppe,
                    Retourmenge: item.Retourmenge,
                    formatiertRetourmenge: tausenderTrennung(item.Retourmenge),
                    Retourwert: roundToDecimals(item.Retourwert, 2),
                    formatiertRetourwert: formatCurrency(item.Retourwert, 2),
                    Retourquote: formatPercentageWithoutMultiplier((item.Retourwert / (item.Lieferwert + item.Korrekturwert)) * 100),
                }));

            setWarengruppeData(mappedWarengruppeData);
            setTotals(prevTotals => ({
                ...prevTotals,
                Retourmengen: roundToDecimals(mappedWarengruppeData.reduce((acc, item) => acc + item.Retourmenge, 0), 2),
                Retourwert: roundToDecimals(mappedWarengruppeData.reduce((acc, item) => acc + item.Retourwert, 0), 2)
            }));
        }

    } catch (error) {
        console.error('Error while fetching data', error);
    } finally {
        setLoading(false);
    }
};


    const kundenStatistikColumns = [
        { accessorKey: 'Filiale', header: 'Filiale', footer: 'Gesamt', size: 200 },
        { accessorKey: 'formatiertUmsatz', header: 'Umsatz', footer: formatCurrency(totals.Umsatz), size: 170 },
        { accessorKey: 'formatiertUmsatz6WSchnitt', header: 'Umsatz 6W Schnitt', footer: formatCurrency(totals.Umsatz6WSchnitt), size: 170 },
        { accessorKey: 'Retourquote', header: 'Retourquote', footer: `${totals.Retourquote}%`, size: 170 },
    ];

    const warengruppeColumns = [
        { accessorKey: 'Warengruppe', header: 'Warengruppe', footer: '', size: 200 },
        { accessorKey: 'formatiertRetourmenge', header: 'Retourmenge', footer: totals.Retourmengen, size: 170 },
        { accessorKey: 'formatiertRetourwert', header: 'Retourwert', footer: formatCurrency(totals.Retourwert), size: 170 },
        { accessorKey: 'Retourquote', header: 'Retourquote', footer: `${totals.Retourquote}%`, size: 170 },
    ];

    const chartData = {
        labels: warengruppeData.map(item => item.Warengruppe), // Warengruppen als Labels
        data: warengruppeData.map(item => parseFloat(item.Retourquote)), // Retourquoten als Daten
    };


    // Open the popup with the selected warengruppe
    const handleWarengruppeClick = (warengruppe) => {
        setSelectedWarengruppe(warengruppe);
        setWarengruppePopupOpen(true);
    };

    const handleRowClick = (row) => {
        const { Filiale } = row;
        setSelectedFiliale({ Datum, Filiale });
        setPopupOpen(true);
    };

    console.log("Übergabe", selectedFiliale);


   return (
        <Layout>
            <Container maxWidth={false} sx={{ padding: '0 16px', width: '100%', display: 'flex', flexDirection: 'column' }}>
                <PageHeader
                    title={`Retoure vom ${dayName} ${Datum}`}
                    subtitle="Übersicht über die Retourwerte für den letzten Tag."
                    Icon={BarChartIcon}
                />

                <br />
                <VerkaufsgebietFilterSelection
                    verkaufsgebietFilter={verkaufsgebietFilter}
                    setVerkaufsgebietFilter={setVerkaufsgebietFilter}
                    verkaufsgebietOptions={verkaufsgebietOptions}
                />
                <br />
                {loading ? (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '50vh'
                    }}>
                        <CircularProgress size={80} />
                    </div>
                ) : (
                    <Box sx={{ flexGrow: 1, width: '100%' }}>
                        <Grid container spacing={2}>
                            {/* Erste Tabelle - nimmt 50% der Breite */}
                            <Grid item xs={12} md={6}>
                                <BigTableCard
                                    columns={warengruppeColumns}
                                    data={warengruppeData}
                                    sx={{ width: '100%' }}
                                    onRowClick={(row) => handleWarengruppeClick(row.Warengruppe)} // Handle click on warengruppe
                                />
                            </Grid>

                            {/* Horizontal Bar Chart in einem Paper mit angepasster Höhe */}
                            <Grid item xs={12} md={6}>
                                <Paper sx={{ padding: 2, height: '100%' }}>
                                    <Box sx={{ height: 400 }}> {/* Chart Höhe angepasst */}
                                        <BarChartHorizontal
                                            title={null}
                                            labels={chartData.labels} // Warengruppen als Y-Achse
                                            data={chartData.data} // Retourquoten als X-Achse
                                        />
                                    </Box>
                                </Paper>
                            </Grid>

                            {/* Zweite Tabelle - nimmt die gesamte Breite */}
                            <Grid item xs={12}>
                                <BigTableCard
                                    columns={kundenStatistikColumns}
                                    data={kundenStatistikData}
                                    onRowClick={handleRowClick}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </Container>

            <TagesreportDetailsPopup
                open={popupOpen}
                handleClose={() => setPopupOpen(false)}
                filialeData={selectedFiliale}
            />
            {selectedWarengruppe && (
                <TagesreportRetoureWarengruppeDetail
                    open={warengruppePopupOpen}
                    handleClose={() => setWarengruppePopupOpen(false)}
                    warengruppe={selectedWarengruppe}
                    datum={Datum}
                    verkaufsgebiet={verkaufsgebietFilter?.value}
                />
            )}
        </Layout>
    );
};

export default Retouren;
