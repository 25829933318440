import {Box} from "@mui/material";
import DropZone from "./DropZone";


const ThekeSection = ({ onDrop, selectedArtikel }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            padding: 1,
            border: '1px solid',
            borderColor: 'primary.main',
            borderRadius: 2,
            width: 'fit-content'
        }}>
            <DropZone onDrop={onDrop} artikel={selectedArtikel[0]?.artikelData} />
            {/* <DropZone onDrop={onDrop} artikelData={selectedArtikel[1]} /> */}
        </Box>
    );
};

export default ThekeSection