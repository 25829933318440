import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Grid, Typography, Card, CardHeader, CardContent,Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axiosInstance from "../../../services/axiosInstance";
import dayjs from "dayjs";
import { FilterContext } from "../../../provider/FilterProvider";
import GroupedBarChart from "../../charts/GroupedBarChart";
import ErrorPopup from "../../utility/ErrorPopup";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VariableTable from "../../table/VariableTable";  // Importiere die VariableTable-Komponente

const TAPersonalKrankenquote = ({ open, setOpen }) => {
    const { filterState } = useContext(FilterContext);
    const [openError, setOpenError] = useState(false);
    const [groupedBarChartData, setGroupedBarChartData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        if (filterState.filiale === null || filterState.datum === null) {
            setOpenError(true);
        }
    }, [filterState.filiale, filterState.datum]);

    useEffect(() => {
        if (open && filterState.filiale && filterState.datum) {
            fetchBarChartData();
        }
    }, [filterState.filiale, filterState.datum, open]);

    const handleCloseError = () => {
        setOpenError(false);
        setOpen(false); // Close the main dialog as well
    };

    if (openError) {
        return (
            <ErrorPopup
                open={openError}
                handleClose={handleCloseError}
                message={"Bitte wählen Sie eine Filiale und ein Datum aus"}
            />
        );
    }

    const handleClose = () => {
        setOpen(false);
    };

    const fetchBarChartData = async () => {
        try {
            const endDate = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
            const startDate = endDate.subtract(18, 'month');

            const response = await axiosInstance.get('scorecards_month/personal-kennzahl/', {
                params: {
                    startdatum: startDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD'),
                    filiale: filterState.filiale?.value,
                }
            });

            const last18MonthsData = response.data.results;

            // Daten für die letzten 6 Monate
            const currentSixMonthsData = last18MonthsData.slice(-6).map(item => ({
                x: dayjs(item.datum).format('MMM'), // Monat formatieren
                currentScore: (item.krankenquote || 0) * 100, // Prozentwert
                previousScore: 0 // Platzhalter
            }));

            // Daten für die gleichen 6 Monate des Vorjahres
            const previousSixMonthsData = last18MonthsData.slice(0, 6).map(item => ({
                x: dayjs(item.datum).format('MMM'),
                previousScore: (item.krankenquote || 0) * 100 // Prozentwert
            }));

            // Kombiniere die Daten für das Diagramm und die Tabelle
            const combinedData = currentSixMonthsData.map((currentData, index) => {
                const previousData = previousSixMonthsData[index];
                return {
                    x: currentData.x,
                    currentScore: currentData.currentScore.toFixed(2),  // Prozentsätze runden
                    previousScore: previousData ? previousData.previousScore.toFixed(2) : 0
                };
            });

            setGroupedBarChartData(combinedData);

            // Tabelle konfigurieren
            setColumns([
                { id: 'year', accessorKey: 'year', header: 'Jahr' },
                ...combinedData.map(item => ({
                    id: item.x,
                    accessorKey: item.x,
                    header: item.x,
                }))
            ]);

            setTableData([
                {
                    year: "Aktuelles Jahr",
                    ...combinedData.reduce((acc, item) => {
                        acc[item.x] = item.currentScore + '%';
                        return acc;
                    }, {})
                },
                {
                    year: "Vorjahr",
                    ...combinedData.reduce((acc, item) => {
                        acc[item.x] = item.previousScore + '%';
                        return acc;
                    }, {})
                }
            ]);

        } catch (error) {
            console.error('An error occurred while fetching data:', error);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>
                Tiefenanalyse Krankenquote
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                padding: '0px',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                backdropFilter: 'blur(10px)',
                            }}>
                            <CardHeader
                                title={
                                    <Typography variant="h6" component="div">
                                        Vergleich zum Vorjahr
                                    </Typography>
                                }
                                action={
                                    <Tooltip
                                        title={
                                            <>
                                                Krankenquotenverlauf - Informationen<br /><br />
                                                Dieses Diagramm zeigt den Verlauf der Krankenqutoe der ausgewählten Filiale im Vergleich zum Vorjahr.<br /><br />
                                                
                                            </>
                                        }
                                    >
                                        <IconButton>
                                            <InfoOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    
                                                                    }
                                
                            />
                            <CardContent sx={{ flexGrow: 1 }}>
                                <div style={{ height: '200px' }}>
                                    <GroupedBarChart data={groupedBarChartData} />
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <VariableTable columns={columns} data={tableData} />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default TAPersonalKrankenquote;
