import React from 'react';
import AuthProvider from "./provider/authProvider";
import Routes from "./routes";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {appTheme} from './theme';
import './App.css';
import {FilterProvider} from "./provider/FilterProvider";
import dayjs from 'dayjs';
import 'dayjs/locale/de';  // Importiere die deutsche Sprachdatei

dayjs.locale('de');  // Setze die Locale global auf Deutsch

function App() {
    return (
        <ThemeProvider theme={appTheme}>
            <CssBaseline/>
            <AuthProvider>
                <FilterProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Routes/>
                    </LocalizationProvider>
                </FilterProvider>
            </AuthProvider>
        </ThemeProvider>
    );
}

export default App;
