import React, {useState, useEffect, useMemo} from 'react';
import axiosInstance from '../../../../services/axiosInstance';
import {Container, Typography, Box, AppBar, Toolbar, Grid} from '@mui/material';
import DropdownFilter from '../../../../components/filter/DropdownFilter';
import Layout from '../../../../components/layout/Scorecards/FilialeMonat';
import {MaterialReactTable, useMaterialReactTable} from 'material-react-table';
import {TitleBar} from "../../../../components/layout/TitleBar";

const WareWachstum = () => {
    const [data, setData] = useState([]);
    const [filialFilter, setFilialFilter] = useState(null);
    const [jahrMonatFilter, setJahrMonatFilter] = useState(null);
    const [filialeOptions, setFilialeOptions] = useState([]);
    const [jahrMonatOptions, setJahrMonatOptions] = useState([]);

    useEffect(() => {
        fetchFilialeOptions();
        fetchJahrMonatOptions();
        fetchData();
    }, [filialFilter, jahrMonatFilter]);

    const fetchFilialeOptions = async () => {
        try {
            const response = await axiosInstance.get('/stammdaten/unique-filiale/');
            setFilialeOptions(response.data.map(item => ({label: item.filiale, value: item.filialnummer})));
        } catch (error) {
            console.error('Fehler beim Abrufen der Filial-Optionen:', error);
        }
    };

    const fetchJahrMonatOptions = async () => {
        try {
            const response = await axiosInstance.get('/stammdaten/unique-jahr-monat/');
            setJahrMonatOptions(response.data.map(item => ({label: item, value: item})));
        } catch (error) {
            console.error('Fehler beim Abrufen der Jahr.Monat-Optionen:', error);
        }
    };

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get('/fact/aggregated-umsatz/', {
                params: {
                    filialnummer: filialFilter?.value,
                    jahrMonat: jahrMonatFilter?.value,
                },
            });
            setData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const columns = useMemo(() => [
        {header: 'Filialnummer', accessorKey: 'filialnummer__filialnummer'},
        {header: 'Filiale', accessorKey: 'filialnummer__filiale'},
        {header: 'Warengruppe', accessorKey: 'warengruppennummer__warengruppe'},
        {header: 'Artikelnummer', accessorKey: 'artikelnummer__artikelnummer'},
        {header: 'Artikelbezeichnung', accessorKey: 'artikelnummer__artikelbezeichnung'},
        {
            header: 'Verkaufsmenge',
            accessorKey: 'total_verkaufsmenge',
            AggregatedCell: ({cell}) => (
                <>
                    Total: <Box sx={{color: 'success.main', fontWeight: 'bold'}}>{cell.getValue()}</Box>
                </>
            ),
        },
    ], []);

    const table = useMaterialReactTable({
        columns,
        data,
        enableGrouping: true,
        enableColumnResizing: true,
        enableStickyHeader: true,
        enableStickyFooter: true,
        initialState: {
            density: 'compact',
            expanded: true,
            grouping: ['filialnummer__filialnummer'],
            pagination: {pageIndex: 0, pageSize: 20},
        },
    });

    return (
        <Layout>
            <Container maxWidth="lg">
                <TitleBar title={`Ware Wachstum`}/>
                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4}}>
                    <form style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <DropdownFilter
                                    label="Filiale"
                                    options={filialeOptions}
                                    value={filialFilter}
                                    onChange={setFilialFilter}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DropdownFilter
                                    label="Jahr Monat"
                                    options={jahrMonatOptions}
                                    value={jahrMonatFilter}
                                    onChange={setJahrMonatFilter}
                                />
                            </Grid>
                        </Grid>
                    </form>
                    <div className="db_styled_table">
                        <MaterialReactTable table={table}/>
                    </div>
                </Box>
            </Container>
        </Layout>
    );
};

export default WareWachstum;
