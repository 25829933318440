// BigPieChartCardTotal.js
import React, { useState } from "react";
import {
    Grid,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Box,
  Typography,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PieChartTotalValues from '../../charts/PieChartTotalValues'; 
import CloseIcon from '@mui/icons-material/Close';

const BigPieChartCardTotal = ({ data, title, height, width, metric }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  // Definiere die Farben aus dem Theme
  const COLORS = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.chart.yellow,
    theme.palette.chart.orange,
    theme.palette.chart.red,
    theme.palette.chart.pink,
    theme.palette.chart.purpleLight,
    theme.palette.chart.purpleDark,
  ];

  // Sortiere die Daten nach dem ausgewählten Metric in absteigender Reihenfolge
  const sortedData = [...data].sort((a, b) => b[metric] - a[metric]);

  // Zeige nur die Top 3 Kategorien an
  const top3Data = sortedData.slice(0, 3);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Paper>
      <Card
        sx={{
          padding: '0px',
          height: height || '100%',
          width: width || '100%',
        }}
      >
        <CardHeader
          title={
            <Typography variant="h6" component="div">
              {title || 'Top 3 Filialtypen'}
            </Typography>
          }
        />
        <CardContent sx={{ flexGrow: 1 }}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            {/* PieChart links */}
            {data && data.length > 0 && (
              <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <PieChartTotalValues
                  data={sortedData}
                  metric={metric}
                  height={height ? height - 150 : undefined}
                />
              </Box>
            )}

            {/* Filialtypen-Liste rechts */}
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                ml: { md: 3 },
                width: '100%',
                maxWidth: '100%',
                boxSizing: 'border-box',
                paddingRight: 2,
              }}
            >
              {/* Top 3 Liste */}
              {top3Data.map((item, index) => (
                <Box key={item.filialtyp} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Box
                    sx={{
                      width: 32,
                      height: 16,
                      backgroundColor: COLORS[index % COLORS.length],
                      mr: 1,
                      borderRadius: '50%',
                    }}
                  />
                  <Typography variant="body2">
                    {item.filialtyp}
                  </Typography>
                  <Typography variant="body2" sx={{ ml: 2, fontWeight: 'normal' }}>
                    Wert: {item[metric].toFixed(2)}
                  </Typography>
                </Box>
              ))}

              {/* Drei Punkte zum Öffnen des Dialogs */}
              <Box sx={{ display: 'flex', justifyContent: 'left', mt: 0 }}>
                <Typography
                  onClick={handleClickOpen}
                  sx={{ cursor: 'pointer', color: theme.palette.primary.main, fontWeight: 'bold' }}
                >
                  ...
                </Typography>
              </Box>
            </Box>
          </Stack>
        </CardContent>
      </Card>

      {/* Dialog zum Anzeigen aller Kategorien */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          Tiefenanalyse
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {/* PieChart mit allen Daten */}
            <Grid item xs={12} md={6}>
              <PieChartTotalValues
                data={sortedData}
                metric={metric}
                height={400}
                width={'100%'}
              />
            </Grid>

            {/* Tabelle mit Werten */}
            <Grid item xs={12} md={6}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Filialtyp</TableCell>
                      <TableCell align="right">Wert</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedData.map((item, index) => (
                      <TableRow key={item.filialtyp}>
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box
                              sx={{
                                width: 16,
                                height: 16,
                                backgroundColor: COLORS[index % COLORS.length],
                                mr: 1,
                                borderRadius: '50%',
                              }}
                            />
                            {item.filialtyp}
                          </Box>
                        </TableCell>
                        <TableCell align="right">{item[metric].toFixed(2)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

export default BigPieChartCardTotal;
