import React, {useState} from 'react';
import {Form, useNavigate} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import useLogin from '../../hooks/useLogin';

const useStyles = makeStyles((theme) => ({
    '@global': {
        body: {
            backgroundColor: '#e0f7fa',
            fontFamily: 'Roboto, sans-serif',
        },
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    },
    paper: {
        padding: theme.spacing(6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '400px',
        width: '100%',
        backgroundColor: '#ffffff',
        borderRadius: '12px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        padding: theme.spacing(1.5),
        backgroundColor: '#00796b',
        '&:hover': {
            backgroundColor: '#004d40',
        },
    },
    error: {
        color: 'red',
        marginTop: theme.spacing(2),
    },
    title: {
        color: '#00796b',
        fontSize: '2rem',
        fontWeight: 'bold',
    },
}));

const LoginPage = () => {
    const classes = useStyles();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const {login} = useLogin();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setLoading(true);

        const result = await login(username, password);
        if (result && result.error) {
            setTimeout(() => {
                setError(result.error);
                setLoading(false);
            }, 1000);
        } else {
            setLoading(false);
            navigate('/module');
        }
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={3}>
                <h1 className={classes.title}>Login</h1>
                {error && <div className={classes.error}>{error}</div>}
                <Form className={classes.form} onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24}/> : 'Login'}
                    </Button>
                </Form>
            </Paper>
        </div>
    );
};

export default LoginPage;
