import React, {useContext, useEffect, useState} from 'react';
import {Box, Grid, Paper, Typography} from '@mui/material';
import Layout from '../../../../components/layout/Scorecards/FilialeMonat';
import axiosInstance from '../../../../services/axiosInstance';
import Stack from '@mui/material/Stack';
import DataCard from '../../../../components/card/DataCard';
import dayjs from 'dayjs';
import ComparisonCard from '../../../../components/card/ComparisonCard';
import {FilterContext} from "../../../../provider/FilterProvider";
import AlertField from "../../../../components/utility/AlertField";
import ScorecardsNotes from "../../../../components/formular/Scorecards/ScorecardsNotes";
import BigChartCard from "../../../../components/card/BigChartCard";
import TAPerformanceUmsatz from "../../../../components/scorecards/tiefenanalyse/TAPerformanceUmsatz";
import {Alert} from "@mui/material";
import {getAlertSeverity} from "../../../../components/utility/AlertPopup";


const PerformanceUmsatz = () => {
    const [comparisonCardData, setComparisonCardData] = useState([]);
    const [dataCardData, setDataCardData] = useState([]);
    const [lineChartData, setLineChartData] = useState([]);
    const {filterState} = useContext(FilterContext);
    const activeCategory = 'Umsatz';

    useEffect(() => {
        if (filterState.filiale && filterState.datum) {
            fetchData();
        }
    }, [filterState.filiale, filterState.datum]);

    const fetchData = async () => {
        try {
            const endDate = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
            const startDate = endDate.subtract(12, 'month');

            const response = await axiosInstance.get('scorecards_month/performance-kennzahl/', {
                params: {
                    startdatum: startDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD'),
                    filiale: filterState.filiale?.value
                }
            });

            const responseUnternehmen = await axiosInstance.get('scorecards_month/performance-kennzahl-unternehmen/', {
                params: {
                    startdatum: startDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD')
                }
            });

            console.log("branchResponse", response);
            console.log("companyResponse", responseUnternehmen);

            const dataCardData = response.data.results
                .slice(-4)
                .map(item => ({
                    x: item.datum,
                    Umsatz: item.scoreUmsatz,
                    Kundenanzahl: item.scoreKundenanzahl,
                    Kundenbon: item.scoreKundenbon,
                    Leistung: item.scoreLeistung,
                }));

            const branchLineChartData = response.data.results.map(item => ({
                x: item.datum,
                y: item.umsatz,
                label: 'Filiale'
            }));

            const companyLineChartData = responseUnternehmen.data.results.map(item => ({
                x: item.datum,
                y: item.umsatz,
                label: 'Unternehmen'
            }));

            const combinedLineChartData = [...branchLineChartData, ...companyLineChartData];

            const additionalComparisonCardData = responseUnternehmen.data.results
                .slice(-1)
                .map(item => ({
                    gesamtUmsatz: item.umsatz,
                    gesamtUmsatzWachstumM: item.umsatzWachstumM,
                    gesamtUmsatzWachstum6M: item.umsatzWachstum6M,
                    gesamtUmsatzWachstumVJ: item.umsatzWachstumJ,
                }));

            console.log("additionalComparisonCardData", additionalComparisonCardData);
            const baseComparisonCardData = response.data.results.slice(-1);

            const comparisonCardData = [...baseComparisonCardData, ...additionalComparisonCardData]


            console.log("comparisoncarddata", comparisonCardData);

            setComparisonCardData(comparisonCardData);
            setDataCardData(dataCardData);
            setLineChartData(combinedLineChartData);
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
        }
    };

    return (
        <Layout>
            <Grid container justifyContent="flex-end" alignItems="stretch">
                <Grid item xs={12}>
                    <Stack direction="row" alignItems="stretch" spacing={2} sx={{overflowX: 'auto'}}>
                        {['Umsatz', 'Kundenanzahl', 'Kundenbon', 'Leistung'].map((category, index) => (
                            <Grid item xs={12} md={6} lg={3} key={index}
                                  className={activeCategory === category ? 'db_datacard_active' : 'db_datacard_not_active'}>
                                <DataCard data={dataCardData} category={category} sector={"performance"}
                                          categoryData={activeCategory === category ? `${comparisonCardData.length > 0 ? comparisonCardData[0].umsatz : null} €` : ''}/>
                            </Grid>
                        ))}
                    </Stack>
                </Grid>
                
                <Grid item xs={12} md={12}>
                    <Paper className="db_datacard_active_content">
                        <Stack direction="row" spacing={2} sx={{alignItems: 'stretch'}}>
                            <Grid item xs={12} md={6} sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                                <Alert
                                    severity={getAlertSeverity(comparisonCardData.length > 0 ? comparisonCardData[0]["scoreUmsatz"] : null)}
                                >
                                    {comparisonCardData.length > 0 ? comparisonCardData[0]["feedbackUmsatz"] : 'Keine Rückmeldung verfügbar'}
                                </Alert>
                                <ComparisonCard
                                    zeitraum="Vorjahr"
                                    data={comparisonCardData}
                                    rangField="rangUmsatzWachstumJ"
                                    percentageField="umsatzWachstumJ"
                                    scoreField="scoreUmsatzWachstumJ"
                                    TiefenanalyseComponent={TAPerformanceUmsatz}
                                />
                                <ComparisonCard
                                    zeitraum="∅ 6. Monate"
                                    data={comparisonCardData}
                                    rangField="rangUmsatzWachstum6M"
                                    percentageField="umsatzWachstum6M"
                                    scoreField="scoreUmsatzWachstum6M"
                                    TiefenanalyseComponent={TAPerformanceUmsatz}
                                />
                                <ComparisonCard
                                    zeitraum="Vormonat"
                                    data={comparisonCardData}
                                    rangField="rangmUmsatzWachstumMonat"
                                    percentageField="umsatzWachstumM"
                                    scoreField="scoreUmsatzWachstumM"
                                    TiefenanalyseComponent={TAPerformanceUmsatz}
                                />
                            </Grid>
                            <BigChartCard
data={lineChartData}
title="Umsatzverlauf im Vergleich zum Gesamtunternehmen"
infoContent={
    <>
        Umsatzverlauf - Informationen<br /><br />
        Dieses Diagramm zeigt den Umsatzverlauf der ausgewählten Filiale im Vergleich zum Gesamtunternehmen.<br /><br />
        <strong>Filiale:</strong> Die dunklere Linie zeigt den Umsatz der Filiale über die Zeit.<br />
        <strong>Unternehmen:</strong> Die hellere Linie repräsentiert den Umsatz des gesamten Unternehmens.<br /><br />
        So kannst du Trends und Unterschiede zwischen der Filiale und dem Unternehmen erkennen.
    </>
}
/>


                        </Stack>
                    </Paper>
                </Grid>
            </Grid>
            <ScorecardsNotes/>
        </Layout>
    );
};

export default PerformanceUmsatz;
