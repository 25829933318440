const Experimental = () => {
    return (
        <div>
            <h1>Experimental page</h1>
            <div>It worked</div>
            <div>IT ACTUALLY did work</div>
        </div>
    );
};

export default Experimental;
