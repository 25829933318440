import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box,Button, Typography, Grid, Card, CardContent, CircularProgress } from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import BakeryDiningIcon from '@mui/icons-material/BakeryDining';
import GroupIcon from '@mui/icons-material/Group';
import VariableTable from '../../components/table/VariableTable'; // Importiere die VariableTable-Komponente
import axiosInstance from '../../services/axiosInstance'; // Passe den Pfad an

const FilialeSummary = ({ open, onClose, filiale, datum }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!open) return;

    const fetchData = async () => {
      try {
        const response = await axiosInstance.get('/scorecard_documentation/documentation/', {
          params: {
            filialnummer: filiale,
            jahrMonat: datum,
          },
        });
        setData(response.data.results);
        setLoading(false);
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [filiale, datum, open]);

  // Funktion, um die Scores und Bemerkungen in Kategorien einzuteilen
  const categorizeData = (entry) => {
    return {
      performance: [
        { label: 'Umsatz', score: entry.scoreUmsatz, remark: entry.bemerkungUmsatz },
        { label: 'Kunden', score: entry.scoreKunden, remark: entry.bemerkungKunden },
        { label: 'Kundenbon', score: entry.scoreKundenbon, remark: entry.bemerkungKundenbon },
        { label: 'Leistung', score: entry.scoreLeistung, remark: entry.bemerkungLeistung },
      ],
      personal: [
        { label: 'Krankenquote', score: entry.scoreKrankenquote, remark: entry.bemerkungKrankenquote },
        { label: 'Fluktuation', score: entry.scoreFluktuation, remark: entry.bemerkungFluktuation },
        { label: 'Bedarf', score: entry.scoreBedarf, remark: entry.bemerkungBedarf },
        { label: 'Auslastung', score: entry.scoreAuslastung, remark: entry.bemerkungAuslastung },
      ],
      waren: [
        { label: 'Retouren A', score: entry.scoreRetoureA, remark: entry.bemerkungRetoureA },
        { label: 'Retouren BC', score: entry.scoreRetoureBC, remark: entry.bemerkungRetoureBC },
        { label: 'Verfügbarkeit', score: entry.scoreVerfügbarkeit, remark: entry.bemerkungVerfügbarkeit },
        { label: 'Wachstum', score: entry.scoreWachstum, remark: entry.bemerkungWachstum },
      ],
    };
  };

  // Funktion zum Umwandeln von Kategorie-Daten in das Format für VariableTable
  const transformToTableData = (categoryData) => {
    return categoryData.map((item) => ({
      label: item.label,
      score: item.score,
      remark: item.remark || "Keine Bemerkung",
    }));
  };

  // Spaltenkonfiguration für VariableTable
  const columns = [
    { accessorKey: 'label', header: 'Kategorie' },
    { accessorKey: 'score', header: 'Score' },
    { accessorKey: 'remark', header: 'Bemerkung' },
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Zusammenfassung für Filiale: {filiale} im Monat: {datum}
      </DialogTitle>
      <DialogContent dividers>
        {loading ? (
          <CircularProgress />
        ) : data.length > 0 ? (
          <Grid container spacing={3}>
            {/* Oberer Teil: Filialinformationen */}
            <Grid item xs={12}>
              <Card
                sx={{
                  border: '1px solid #ccc', // Grauer Rahmen
                  borderRadius: '8px', // Abgerundete Ecken
                  mb: 3, // Abstand nach unten
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body1">
                        <strong>Filiale:</strong> {filiale}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">
                        <strong>Monat:</strong> {datum}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">
                        <strong>Gesprächspartner:</strong> {data[0]?.gespraechspartner || 'Keine Angabe'}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1">
                        <strong>User:</strong> {data[0]?.user || 'Keine Angabe'}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {/* Unterer Teil: Kategorien als drei Karten */}
            {data.map((entry, index) => {
              const categorizedData = categorizeData(entry);

              return (
                <React.Fragment key={index}>
                  <Grid item xs={12}>
                    <Card
                      sx={{
                        border: '1px solid #ccc', // Grauer Rahmen
                        borderRadius: '8px', // Abgerundete Ecken
                        mb: 3, // Abstand nach unten
                      }}
                    >
                      <CardContent>
                        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                          <TimelineIcon color="primary" sx={{ mr: 1 }} />
                          <Typography variant="h6">Performance</Typography>
                        </Box>
                        <VariableTable columns={columns} data={transformToTableData(categorizedData.performance)} />
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card
                      sx={{
                        border: '1px solid #ccc', // Grauer Rahmen
                        borderRadius: '8px', // Abgerundete Ecken
                        mb: 3, // Abstand nach unten
                      }}
                    >
                      <CardContent>
                        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                          <GroupIcon color="primary" sx={{ mr: 1 }} />
                          <Typography variant="h6">Personal</Typography>
                        </Box>
                        <VariableTable columns={columns} data={transformToTableData(categorizedData.personal)} />
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={12}>
                    <Card
                      sx={{
                        border: '1px solid #ccc', // Grauer Rahmen
                        borderRadius: '8px', // Abgerundete Ecken
                        mb: 3, // Abstand nach unten
                      }}
                    >
                      <CardContent>
                        <Box display="flex" alignItems="center" sx={{ mb: 2 }}>
                          <BakeryDiningIcon color="primary" sx={{ mr: 1 }} />
                          <Typography variant="h6">Waren</Typography>
                        </Box>
                        <VariableTable columns={columns} data={transformToTableData(categorizedData.waren)} />
                      </CardContent>
                    </Card>
                  </Grid>
                </React.Fragment>
              );
            })}
          </Grid>
        ) : (
          <Typography variant="body1">Keine Daten verfügbar für diese Filiale in diesem Monat.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilialeSummary;
