import React, { useContext, useEffect, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import Layout from '../../../../components/layout/Scorecards/FilialeMonat';
import axiosInstance from '../../../../services/axiosInstance';
import Stack from '@mui/material/Stack';
import DataCard from '../../../../components/card/DataCard';
import dayjs from 'dayjs';
import ComparisonCard from '../../../../components/card/ComparisonCard';
import { FilterContext } from "../../../../provider/FilterProvider";
import AlertField from "../../../../components/utility/AlertField";
import BigTableCardPersonal from '../../../../components/card/BigTableCardPersonal';
import ScorecardsNotes from '../../../../components/formular/Scorecards/ScorecardsNotes';
import EmployeeDetailsPopup from '../../../../components/popup/EmployeeDetailsPopup';
import { Alert } from "@mui/material";
import { getAlertSeverity } from "../../../../components/utility/AlertPopup";
import TAPersonalKrankenquote from '../../../../components/scorecards/tiefenanalyse/TAPersonalKrankenquote';

const PersonalKrankenquote = () => {
    const [comparisonCardData, setComparisonCardData] = useState([]);
    const [dataCardData, setDataCardData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { filterState } = useContext(FilterContext);
    const activeCategory = 'Krankenquote';

    useEffect(() => {
        if (filterState.filiale && filterState.datum) {
            fetchData();
            fetchTableData();
        }
    }, [filterState.filiale, filterState.datum]);

    const fetchData = async () => {
        try {
            const endDate = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
            const startDate = endDate.subtract(6, 'month');

            const response = await axiosInstance.get('scorecards_month/personal-kennzahl/', {
                params: {
                    startdatum: startDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD'),
                    filiale: filterState.filiale?.value
                }
            });

            const dataCardData = response.data.results
                .slice(-4)
                .map(item => ({
                    x: item.datum,
                    Krankenquote: item.scoreKrankenquote,
                    Fluktuation: item.scoreFluktuation,
                    Bedarf: item.scoreKöpfeStunden,
                    Auslastung: item.scoreÜberstunden,
                }));

            const comparisonCardData = response.data.results.slice(-1).map(item => ({
                ...item,
                krankenquote: `${(item.krankenquote * 100).toFixed(2)}%`,  // Krankenquote in Prozent und auf 2 Dezimalstellen runden
                krankenquoteOriginal: item.krankenquote,
            }));

            setComparisonCardData(comparisonCardData);
            setDataCardData(dataCardData);
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
        }
    };

    const fetchTableData = async () => {
        try {
            const response = await axiosInstance.get('personaldaten/abwesenheiten/', {
                params: {
                    jahrMonat: filterState.datum?.value,
                    filiale: filterState.filiale?.value
                }
            });

            const dataTableData = response.data.results.map(item => ({
                mitarbeiterId: item.mitarbeiterId,  // Mitarbeiter-ID hinzufügen
                Name: item.name,
                Abwesenheitsstunden: Math.round(item.abwesenheitsstunden),  // Abwesenheitsstunden runden
                Abwesenheitstyp: item.abwesenheitstyp,
            }));

            const abwesenheitstypValues = dataTableData.map(item => item.Abwesenheitstyp);
            const uniqueAbwesenheitstyp = [...new Set(abwesenheitstypValues)];

            let aggregatedData = {};

            dataTableData.forEach(item => {
                if (!aggregatedData[item.Name]) {
                    aggregatedData[item.Name] = { Name: item.Name, mitarbeiterId: item.mitarbeiterId };
                    uniqueAbwesenheitstyp.forEach(type => {
                        aggregatedData[item.Name][type] = 0;
                    });
                }
                aggregatedData[item.Name][item.Abwesenheitstyp] += item.Abwesenheitsstunden;
            });

            const newTableData = Object.values(aggregatedData);

            const newColumns = [
                { id: 'name', accessorKey: 'Name', header: 'Name' },
                ...uniqueAbwesenheitstyp.map(type => ({
                    id: `col-${type}`,
                    accessorKey: type,
                    header: type,
                }))
            ];

            setTableColumns(newColumns);
            setTableData(newTableData);

        } catch (error) {
            console.error('An error occurred while fetching data:', error);
        }
    };

    const handleRowClick = (employee) => {
        setSelectedEmployee(employee);
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setSelectedEmployee(null);
    };

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    return (
        <Layout>
            <Grid container justifyContent="flex-end" alignItems="stretch">
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} sx={{ overflowX: 'auto' }}>
                        {['Krankenquote', 'Fluktuation', 'Bedarf', 'Auslastung'].map((category, index) => (
                            <Grid
                                item
                                xs={12}
                                md={6}
                                lg={3}
                                key={index}
                                className={activeCategory === category ? 'db_datacard_active' : 'db_datacard_not_active'}
                            >
                                <DataCard
                                    data={dataCardData}
                                    category={category}
                                    sector="personal"
                                    categoryData={
                                        activeCategory === category
                                            ? `${comparisonCardData.length > 0 ? comparisonCardData[0].krankenquote : null}`
                                            : ''
                                    }
                                />
                            </Grid>
                        ))}
                    </Stack>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Paper className="db_datacard_active_content">
                    <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                    <ComparisonCard
                                    zeitraum="Unternehmen"
                                    data={comparisonCardData}
                                    rangField="rangKrankenquote"
                                    percentageField="krankenquoteOriginal"
                                    scoreField="scoreKrankenquote"
                                    TiefenanalyseComponent={TAPersonalKrankenquote}
                                />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                <Alert
                                    severity={getAlertSeverity(comparisonCardData.length > 0 ? comparisonCardData[0]["scoreKrankenquote"] : null)}
                                >
                                    {comparisonCardData.length > 0 ? comparisonCardData[0]["feedbackKrankenquote"] : 'Keine Rückmeldung verfügbar'}
                                </Alert>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} sx={{ mt: 2 }}>

                            <BigTableCardPersonal
                                title={"Abwesenheiten"}
                                data={tableData}
                                columns={tableColumns}
                                onRowClick={handleRowClick}
                            />
                                                        </Grid>

                    </Paper>
                </Grid>
            </Grid>
            <ScorecardsNotes />
            
            <EmployeeDetailsPopup
                open={isPopupOpen}
                onClose={handleClosePopup}
                employee={selectedEmployee}
                jahrMonat={filterState.datum?.value}
            />

            <TAPersonalKrankenquote
                open={isDialogOpen}
                setOpen={setIsDialogOpen}
            />
        </Layout>
    );
};

export default PersonalKrankenquote;
