import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography,Stack,Card   } from '@mui/material';
import Layout from '../../../components/layout/Scorecards/Unternehmen/UnternehmenMonat';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';
import DataCard from '../../../components/card/scorecardsmonat/DataCardMonatPage';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import DropdownFilter from '../../../components/filter/DropdownFilter';
import VariableTable from '../../../components/table/VariableTable';
import { useTheme } from '@mui/material/styles'; // Importiere das Thema


const Auslastung = () => {
    const { filterState, handleDatumChange } = useContext(FilterContext);
    const [datumOptions, setDatumOptions] = useState([]);
    const theme = useTheme(); // Access the theme
    const [personalData, setPersonalData] = useState({
        krankenquote: [],
        fluktuation: [],
    });
    

    useEffect(() => {
        fetchDatumOptions();
    }, []);

    useEffect(() => {
        if (filterState.datum) {
            fetchPersonalData(); // Fetch Krankenquote and Fluktuation data
        }
    }, [filterState]);
    

    

    const fetchDatumOptions = async () => {
        try {
            const response = await axiosInstance.get('/stammdaten/unique-jahr-monat/');
            setDatumOptions(response.data.map(item => ({ label: item, value: item })));
        } catch (error) {
            console.error('Error while fetching datum options:', error);
        }
    };

    
    // Helper function to calculate the last 6 months based on the selected date
    const getLast6Months = (currentMonth) => {
        const last6Months = [];
        const formattedMonth = dayjs(currentMonth, 'YYYY.MM'); // Das gefilterte Datum korrekt formatieren
    
        for (let i = 0; i < 6; i++) {
            last6Months.push(formattedMonth.subtract(i, 'month').format('YYYY.MM')); // Rückwirkend vom gefilterten Monat die letzten 6 Monate berechnen
        }
        
        return last6Months.reverse(); // Optional, um die Monate in chronologischer Reihenfolge anzuzeigen (älteste zuerst)
    };

    

    const fetchPersonalData = async () => {
        const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM'); // Verwende das gefilterte Datum
        const last6Months = getLast6Months(currentMonth); // Letzten 6 Monate berechnen
    
        try {
            const responses = await Promise.all(
                last6Months.map(month => axiosInstance.get('/scorecards_month/personal-kennzahl/', {
                    params: { jahrMonat: month },
                }))
            );
    
            // Kumulieren der Krankenquote-Daten
            const krankenquoteData = responses.map((response, index) => {
                const data = response.data.results;
    
                const arbeitszeitKrankenqoute = data.reduce((sum, item) => sum + (item.arbeitszeitKrankenqoute || 0), 0);
                const krankenzeit = data.reduce((sum, item) => sum + (item.krankenzeit || 0), 0);
                const arbeitszeitKrankenqouteVM = data.reduce((sum, item) => sum + (item.arbeitszeitKrankenqouteVM || 0), 0);
                const krankenzeitVM = data.reduce((sum, item) => sum + (item.krankenzeitVM || 0), 0);
                const arbeitszeitKrankenqouteVJ = data.reduce((sum, item) => sum + (item.arbeitszeitKrankenqouteVJ || 0), 0);
                const krankenzeitVJ = data.reduce((sum, item) => sum + (item.krankenzeitVJ || 0), 0);
    
                // Berechne die Krankenquote
                const krankenquote = arbeitszeitKrankenqoute > 0 ? (krankenzeit / arbeitszeitKrankenqoute) * 100 : 0;
                const krankenquoteVM = arbeitszeitKrankenqouteVM > 0 ? (krankenzeitVM / arbeitszeitKrankenqouteVM) * 100 : 0;
                const krankenquoteVJ = arbeitszeitKrankenqouteVJ > 0 ? (krankenzeitVJ / arbeitszeitKrankenqouteVJ) * 100 : 0;
    
                return {
                    month: last6Months[index],
                    arbeitszeitKrankenqoute,
                    krankenzeit,
                    krankenquote,
                    krankenquoteVM,
                    krankenquoteVJ,
                };
            });
    
            // Kumulieren der Fluktuation-Daten
            const fluktuationData = responses.map((response, index) => {
                const data = response.data.results;
    
                const austritte6M = data.reduce((sum, item) => sum + (item.austritte6M || 0), 0);
                const mitarbeiterbestand6M = data.reduce((sum, item) => sum + (item.mitarbeiterbestand6M || 0), 0);
                const eintritte6M = data.reduce((sum, item) => sum + (item.eintritte6M || 0), 0);
                const köpfeDifferenz = data.reduce((sum, item) => sum + (item.köpfeDifferenz || 0), 0);
                const stundenDifferenz = data.reduce((sum, item) => sum + (item.stundenDifferenz || 0), 0);
                const überstundenAbs = data.reduce((sum, item) => sum + (item.überstundenAbs || 0), 0);
    
                // Berechne die Fluktuation
                const fluktuation = (mitarbeiterbestand6M + eintritte6M) > 0 
                    ? (austritte6M / (mitarbeiterbestand6M + eintritte6M)) * 100 
                    : 0; // Ergebnis in %
    
                // Nur Eintritte und Austritte des gefilterten Monats
                const eintritteFiltered = data.reduce((sum, item) => sum + (item.eintritte || 0), 0);
                const austritteFiltered = data.reduce((sum, item) => sum + (item.austritte || 0), 0);
    
                // **Kumulieren der EintritteM und AustritteM** für alle Filialen
                const eintritteM = data.reduce((sum, item) => sum + (item.eintritteM || 0), 0); // Kumulierte Eintritte (M)
                const austritteM = data.reduce((sum, item) => sum + (item.austritteM || 0), 0); // Kumulierte Austritte (M)
    
                return {
                    month: last6Months[index],
                    austritte6M,
                    mitarbeiterbestand6M,
                    eintritte6M,
                    fluktuation,
                    eintritteFiltered,
                    austritteFiltered,
                    eintritteM, // Kumulierte Eintritte (M)
                    austritteM,  // Kumulierte Austritte (M)
                    köpfeDifferenz, // Kumulierte Köpfe-Differenz (Bedarf an Mitarbeiter)
                    stundenDifferenz, // Kumulierte Stunden-Differenz (Bedarf an Stunden)
                    überstundenAbs,  // Kumulierte Überstunden (Auslastung)
                };
            });
    
            setPersonalData({
                krankenquote: krankenquoteData,
                fluktuation: fluktuationData,
            });
        } catch (error) {
            console.error('Error fetching personal data:', error);
        }
    };
    

    return (
        <Layout>

<Grid container justifyContent="flex-end" alignItems="stretch">
<Grid item xs={12}>
<Stack direction="row" alignItems="stretch" spacing={2} sx={{ overflowX: 'auto' }}>

    {/* Krankenquote */}
    <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
            <DataCard
                category="Krankenquote"
                sector="personal"
                value={personalData.krankenquote.length > 0 ? personalData.krankenquote[personalData.krankenquote.length - 1].krankenquote.toFixed(2) : '0.00'}
                subHeaders={[
                    `${personalData.krankenquote.length > 0 ? personalData.krankenquote[personalData.krankenquote.length - 1].krankenquoteVM.toFixed(2) : '0.00'}% zum Vormonat`,
                    `${personalData.krankenquote.length > 0 ? personalData.krankenquote[personalData.krankenquote.length - 1].krankenquoteVJ.toFixed(2) : '0.00'}% zum Vorjahr`
                ]}
                chartData={personalData.krankenquote.map(item => item.krankenquote)} // Nutze die Krankenquote-Daten für den Sparkline-Chart
            />
    </Grid>

    {/* Fluktuation */}
    <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
    <DataCard
                category="Fluktuation"
                sector="personal"
                value={personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].fluktuation.toFixed(2) : '0.00'}
                subHeaders={[
                    `${personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].eintritteM.toFixed(2) : '0.00'} Eintritte im gefilterten Monat`,
                    `${personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].austritteM.toFixed(2) : '0.00'} Eintritte im gefilterten Monat`
                ]}
                chartData={personalData.fluktuation.map(item => item.fluktuation)} // Nutze die Fluktuation-Daten für den Sparkline-Chart
            />
    </Grid>

    {/* Bedarf */}
    <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
    <DataCard
                category="Bedarf"
                sector="personal"
                value={`${personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].köpfeDifferenz : '0'} Köpfe und ${personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].stundenDifferenz : '0'} Stunden`}
                chartData={personalData.fluktuation.map(item => item.köpfeDifferenz)} 
            />
    </Grid>

    {/* Auslastung */}
    <Grid item xs={12} md={6} lg={3} className="db_datacard_active">
    <DataCard
                category="Auslastung"
                sector="personal"
                value={`${personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].überstundenAbs : '0'} Überstunden`}
                subHeaders={[]}
                chartData={personalData.fluktuation.map(item => item.überstundenAbs)} 
            />
    </Grid>
    </Stack>

</Grid>
</Grid>


        </Layout>
    );
};

export default Auslastung;
