import {Navigate, Outlet} from "react-router-dom";
import {useEffect, useState} from "react";
import {useAuth} from "../provider/authProvider";
import {validateToken, validatePermission} from "../services/authService";
import CircularIndeterminate from "../components/utility/Loading";

export const ProtectedRoute = ({module, children}) => {
    const {token} = useAuth();
    const [isValid, setIsValid] = useState(null);
    const [hasPermission, setHasPermission] = useState(null);
    const [isCheckingPermission, setIsCheckingPermission] = useState(true); // Flag for permission check

    useEffect(() => {
        const checkToken = async () => {
            if (token) {
                // console.log("Token found:", token);
                const isValidToken = await validateToken(token);
                // console.log("Token is valid:", isValidToken);
                setIsValid(isValidToken);
            } else {
                // console.log("No token found");
                setIsValid(false);
                setHasPermission(false);
            }
        };

        checkToken();
    }, [token]);

    useEffect(() => {
        const checkPermission = async () => {
            setIsCheckingPermission(true); // Start checking permission
            setHasPermission(null); // Reset hasPermission before checking

            if (isValid) {
                try {
                    // console.log("Checking permission for module:", module);
                    const isValidPermission = await validatePermission(module);
                    // console.log("Has permission:", isValidPermission);
                    setHasPermission(isValidPermission);
                } catch (error) {
                    console.log("Error checking permission:", error);
                    setHasPermission(false);
                } finally {
                    setIsCheckingPermission(false); // Ensure check is complete
                }
            } else {
                setIsCheckingPermission(false); // Ensure check is complete if not valid
            }
        };

        if (isValid !== null) {
            if (module) {
                checkPermission();
            } else {
                setHasPermission(true);
                setIsCheckingPermission(false);
            }
        }
    }, [isValid, module]);

    // console.log("isValid:", isValid, "hasPermission:", hasPermission, "isCheckingPermission:", isCheckingPermission);

    if (isValid === null || isCheckingPermission) { // Wait for both checks to complete
        // console.log("Checking permissions...");
        return <CircularIndeterminate/>; // Render
    }

    if (!isValid) {
        console.log("Invalid token or no permission");
        return <Navigate to="/login"/>;
    }

    if (!hasPermission) {
        return <Navigate to="/access-denied"/>;
    }

    return children ? children : <Outlet/>;
};

export default ProtectedRoute;
