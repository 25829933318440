import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Card, CardActionArea, CircularProgress, Badge, CardContent } from '@mui/material';
import axiosInstance from '../../services/axiosInstance';
import RatingForm from './RatingForm'; // Importiere die RatingForm-Komponente

const FilialcheckFiliale = () => {
    const [filialchecks, setFilialchecks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCheck, setSelectedCheck] = useState(null); // Zustand für den ausgewählten Check

    const fetchFilialchecks = async () => {
        try {
            const response = await axiosInstance.get('/filialcheck/checks/');
            if (Array.isArray(response.data)) {
                setFilialchecks(response.data);
            } else if (response.data.results && Array.isArray(response.data.results)) {
                setFilialchecks(response.data.results);
            } else {
                console.error('Unerwartetes Antwortformat:', response.data);
            }
        } catch (error) {
            console.error('Fehler beim Laden der Filialchecks:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFilialchecks();
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    if (selectedCheck) {
        // Wenn ein Check ausgewählt wurde, zeige die RatingForm an
        return <RatingForm checkId={selectedCheck} />;
    }

    // Filtere nur die aktiven Filialchecks
    const activeFilialchecks = filialchecks.filter(check => check.is_active);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 2,
                maxWidth: 1200, // Maximalbreite des Inhalts
                margin: '0 auto', // Zentriert den Inhalt horizontal
            }}
        >
            {activeFilialchecks.length > 0 ? (
                <Grid container spacing={4} justifyContent="center">
                    {activeFilialchecks.map(check => (
                        <Grid item key={check.id}>
                            <Card sx={{ width: 300, height: 150 }}>
                                <CardActionArea
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%'
                                    }}
                                    onClick={() => setSelectedCheck(check.id)}
                                >
                                    <Badge badgeContent={check.badge} sx={{ color: 'primary.main' }} invisible={!check.badge}>
                                        {/* Hier könntest du ein Icon oder ein Logo hinzufügen, falls gewünscht */}
                                    </Badge>
                                    <CardContent sx={{ textAlign: 'left', width: '100%' }}> {/* Links ausgerichtet und volle Breite */}
                                        <Typography variant="subtitle1">
                                            {check.name}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {check.description} {/* Beschreibung unter dem Titel */}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Typography variant="body1">Keine aktiven Filialchecks vorhanden.</Typography>
            )}
        </Box>
    );
};

export default FilialcheckFiliale;
