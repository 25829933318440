import { createTheme } from "@mui/material/styles";
import MontserratMedium from './fonts/Montserrat-Medium.ttf';
import MontserratBold from './fonts/Montserrat-Bold.ttf';
import MontserratItalic from './fonts/Montserrat-Italic.ttf';
import MontserratBoldItalic from './fonts/Montserrat-BoldItalic.ttf';
import MontserratRegular from './fonts/Montserrat-Regular.ttf';

const db_green = '#C3D98C';
const db_green_light = '#D1E69B';
const db_green_dark = '#B6CB83';
const db_olive = '#95A78D';
const db_olive_light = '#A0B79';
const db_olive_dark = '#84967B';
const db_petrol = '#395D67';
const db_petrol_light = '#5E827B';
const db_petrol_dark = '#3A5B5D';
const db_grey = '#737373';
const db_grey_light = '#F5F5F5';
const db_grey_dark = '#575756';
const db_mint = '#E7EBE5';
const db_error = '#D03B56'; // Example error color
const db_warning = '#EFB358'; // Example warning color
const db_success = '#63AC73'; // Example success color
const db_yellow = '#EFB258';
const db_orange = '#D48E3A';
const db_red = '#D05F3B';
const db_pink = '#CA6790';
const db_purple_light = '#74659C';
const db_purple_dark = '#4B568F';

export const appTheme = createTheme({
  palette: {
    primary: {
      main: db_petrol,
      light: db_petrol_light,
      dark: db_petrol_dark,
    },
    secondary: {
      main: db_olive,
      light: db_olive_light,
      dark: db_olive_dark,
    },
    error: {
      main: db_error,
    },
    warning: {
      main: db_warning,
    },
    success: {
      main: db_success,
    },
    chart: {
      yellow: db_yellow,
      orange: db_orange,
      red: db_red,
      pink: db_pink,
      purpleLight: db_purple_light,
      purpleDark: db_purple_dark,
    },
  },
  typography: {
    fontFamily: "Montserrat, Roboto, sans-serif",
    fontSize: 14,
    h1: {
      fontSize: '2.125rem',
      lineHeight: 1.2,
      fontWeight: '500',
    },
    h5: {
      fontSize: '1.5rem',
      lineHeight: 1.2,
      fontWeight: '500',
    },
    h6: {
      fontSize: '1rem',
      lineHeight: 1.2,
      fontWeight: '500',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: '1rem',
          borderRadius: '15px',
          boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          padding: '0',
          border: 'none',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Montserrat';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url("${MontserratRegular}") format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Montserrat';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: url(${MontserratMedium}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Montserrat';
          font-style: normal;
          font-display: swap;
          font-weight: bold;
          src: url(${MontserratBold}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'Montserrat';
          font-style: normal;
          font-display: swap;
          font-weight: italic;
          src: url(${MontserratItalic}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        } 
        @font-face {
          font-family: 'Montserrat';
          font-style: italic;
          font-display: swap;
          font-weight: bold;
          src: url(${MontserratBoldItalic}) format('truetype');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        html {
          scrollbar-width: thin; /* Firefox */
          scrollbar-color: transparent transparent; /* Firefox */
        }
        ::-webkit-scrollbar {
          width: 8px; /* Chrome, Safari, Opera */
        }
        ::-webkit-scrollbar-track {
          background: transparent; /* Chrome, Safari, Opera */
        }
        ::-webkit-scrollbar-thumb {
          background-color: transparent; /* Chrome, Safari, Opera */
          border-radius: 20px;
        }
      `,
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: '#f5f9f7',
          borderRadius: '5px', // Hier wird der border-radius definiert
          '& .MuiAutocomplete-listbox': {
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'transparent',
              borderRadius: '5px',
            },
          },
        },
      },
    },
    
  },
});
