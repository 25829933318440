import React from 'react';
import {Container, Typography, Button} from '@mui/material';

const AccessDenied = () => {
    return (
        <Container maxWidth="sm" style={{textAlign: 'center', marginTop: '50px'}}>
            <Typography variant="h3" component="h1" gutterBottom>
                Access Denied
            </Typography>
            <Typography variant="h6" component="p" gutterBottom>
                You do not have permission to view this page.
            </Typography>
            <Button variant="contained" color="primary" href="/">
                Go to Home
            </Button>
        </Container>
    );
};

export default AccessDenied;
