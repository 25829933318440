import React, { useEffect } from 'react';
import { Card, CardContent, CardHeader, Grid, Paper, Typography } from "@mui/material";
import VariableTable from "../table/VariableTable";

const BigTableCard = ({ title, data, columns, onRowClick, muiTableBodyCellProps }) => {
    return (
        <Grid item xs={12} md={12}>
            <Paper>
                <Card
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        padding: '0px',
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        backdropFilter: 'blur(10px)',
                    }}>
                    <CardHeader
                        title={
                            <Typography variant="h6" component="div">
                                {title}
                            </Typography>
                        }
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                        {data && data.length > 0 && (
                            <VariableTable
                                data={data}
                                columns={columns}
                                onRowClick={onRowClick} 
                                muiTableBodyCellProps={muiTableBodyCellProps} // Übergeben des benutzerdefinierten Stils
                            />
                        )}
                    </CardContent>
                </Card>
            </Paper>
        </Grid>
    );
};

export default BigTableCard;
