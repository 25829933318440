import Layout from "../../components/layout/Scorecards/FilialeMonat";
import {Container, Typography, Box, AppBar, Toolbar} from '@mui/material';

const Settings = () => {
    return (
        <Layout>
            <Container maxWidth="sm">
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h3" component="div" sx={{flexGrow: 1}}>
                            Einstellungen
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box
                    sx={{
                        display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4,
                    }}
                >
                    <Typography variant="h3" component="div" gutterBottom>
                        Hello
                    </Typography>
                </Box>
            </Container>
        </Layout>
    )
}

export default Settings;
