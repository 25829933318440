import React from 'react';
import { Card, CardContent, CardHeader, Grid, Paper, Typography, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/QueryStats';
import BarChartVerfuegbarkeit from '../charts/BarChartVerfuegbarkeit'; // Pfad zur MUI-X-Charts BarChart-Komponente

const BigBarChartVerfuegbarkeitCard = ({ data, title, labels, onSearchClick }) => {
    return (
        <Grid item xs={12} md={4}>
            <Paper>
                <Card
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        padding: '0px',
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        backdropFilter: 'blur(10px)',
                    }}
                >
                    <CardHeader
                        title={
                            <Typography variant="h6" component="div">
                                {title}
                            </Typography>
                        }
                        action={
                            <IconButton onClick={onSearchClick} sx={{ color: 'primary.main' }}>
                                <SearchIcon />
                            </IconButton>
                        }
                    />
                    <CardContent sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <BarChartVerfuegbarkeit
                            data={data}
                            labels={labels}
                        />
                    </CardContent>
                </Card>
            </Paper>
        </Grid>
    );
};

export default BigBarChartVerfuegbarkeitCard;
