import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, CircularProgress, Divider } from '@mui/material';
import axiosInstance from '../../services/axiosInstance';

const AnalyseManagement = () => {
    const [loading, setLoading] = useState(true);
    const [analysisData, setAnalysisData] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAnalysisData = async () => {
            try {
                const response = await axiosInstance.get('/filialcheck/analysis/');
                setAnalysisData(response.data);
                setLoading(false);
            } catch (error) {
                setError('Fehler beim Laden der Analyse-Daten.');
                setLoading(false);
            }
        };

        fetchAnalysisData();
    }, []);

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography variant="body1" color="error">{error}</Typography>;
    }

    return (
        <Paper sx={{ p: 3 }}>
            <Typography variant="h5" gutterBottom>
                Analyse Management
            </Typography>

            {/* Anzahl der Checks pro Tag und Filiale */}
            <Box>
                <Typography variant="h6" gutterBottom>
                    Anzahl der Checks pro Tag und Filiale
                </Typography>
                {analysisData.check_count_data.length > 0 ? (
                    analysisData.check_count_data.map((entry, index) => (
                        <Box key={index} sx={{ mb: 2 }}>
                            <Typography variant="body1">
                                Datum: {entry.datum}, Filialnummer: {entry.filialnummer}, Anzahl Checks: {entry.check_count}
                            </Typography>
                        </Box>
                    ))
                ) : (
                    <Typography variant="body1">Keine Analyse-Daten verfügbar.</Typography>
                )}
            </Box>

            <Divider sx={{ my: 4 }} />

            {/* Durchschnittliche Bewertung pro Kategorie und Check */}
            <Box>
                <Typography variant="h6" gutterBottom>
                    Durchschnittliche Bewertung pro Kategorie und Check
                </Typography>
                {analysisData.avg_rating_per_check.length > 0 ? (
                    analysisData.avg_rating_per_check.map((entry, index) => (
                        <Box key={index} sx={{ mb: 2 }}>
                            <Typography variant="body1">
                                Datum: {entry.datum}, Filialnummer: {entry.filialnummer}, Kategorie: {entry['checkpoint__category__name']}, 
                                Session: {entry.session_id}, Durchschnittsbewertung: {entry.avg_rating.toFixed(2)}
                            </Typography>
                        </Box>
                    ))
                ) : (
                    <Typography variant="body1">Keine Analyse-Daten verfügbar.</Typography>
                )}
            </Box>

            <Divider sx={{ my: 4 }} />

            {/* Durchschnittliche Bewertung pro Kategorie pro Tag und Filiale */}
            <Box>
                <Typography variant="h6" gutterBottom>
                    Durchschnittliche Bewertung pro Kategorie pro Tag und Filiale
                </Typography>
                {analysisData.avg_rating_per_day.length > 0 ? (
                    analysisData.avg_rating_per_day.map((entry, index) => (
                        <Box key={index} sx={{ mb: 2 }}>
                            <Typography variant="body1">
                                Datum: {entry.datum}, Filialnummer: {entry.filialnummer}, Kategorie: {entry['checkpoint__category__name']}, 
                                Durchschnittsbewertung: {entry.avg_rating.toFixed(2)}
                            </Typography>
                        </Box>
                    ))
                ) : (
                    <Typography variant="body1">Keine Analyse-Daten verfügbar.</Typography>
                )}
            </Box>
        </Paper>
    );
};

export default AnalyseManagement;
