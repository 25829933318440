import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography,Stack,Card   } from '@mui/material';
import Layout from '../../../components/layout/Scorecards/Unternehmen/UnternehmenMonat';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';
import DataCard from '../../../components/card/scorecardsmonat/DataCardMonat';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import DropdownFilter from '../../../components/filter/DropdownFilter';
import { calculatePerformanceData } from '../../../utils/calculationPerformance';
import VariableTable from '../../../components/table/VariableTable';
import { useTheme } from '@mui/material/styles'; // Importiere das Thema


const Cockpit = () => {
    const { filterState, handleDatumChange } = useContext(FilterContext);
    const [performanceData, setPerformanceData] = useState({
        umsatz: 0,
        umsatzVJ: 0,
        umsatzAbweichungVM: 0,
        umsatzAbweichung6M: 0,
        umsatzAbweichungVJ: 0,
        kundenanzahl: 0,
        kundenanzahlVJ: 0,
        kundenanzahlAbweichungVM: 0,
        kundenanzahlAbweichung6M: 0,
        kundenanzahlAbweichungVJ: 0,
        leistung: 0,
        leistungVJ: 0,
        kundenbon: 0,
        kundenbonVJ: 0,
        chartData: [],
    });
    const [datumOptions, setDatumOptions] = useState([]);
    const [retourenData, setRetourenData] = useState([]); // State für die Retourenwerte pro Warengruppe
    const [warengruppenData, setWarengruppenData] = useState([]); // State für die Warengruppen-Daten
    const theme = useTheme(); // Access the theme

    const [performanceLast6Months, setPerformanceLast6Months] = useState([]); // State for the last 6 months' data
    const [personalData, setPersonalData] = useState({
        krankenquote: [],
        fluktuation: [],
    });
    

    useEffect(() => {
        fetchDatumOptions();
    }, []);

    useEffect(() => {
        if (filterState.datum) {
            fetchPerformanceData();
            fetchPerformanceDataLast6Months(); // Fetch last 6 months data when the date is filtered
            fetchPersonalData(); // Fetch Krankenquote and Fluktuation data
            fetchWarengruppenData(); // Rufe die Verkaufsdaten ab
            fetchRetourenData(); // Abrufen der Retourenwerte
        }
    }, [filterState]);
    

    

    const fetchDatumOptions = async () => {
        try {
            const response = await axiosInstance.get('/stammdaten/unique-jahr-monat/');
            setDatumOptions(response.data.map(item => ({ label: item, value: item })));
        } catch (error) {
            console.error('Error while fetching datum options:', error);
        }
    };

    const fetchPerformanceData = async () => {
        try {
            const response = await axiosInstance.get('/scorecards_month/performance-kennzahl-gesamt/', {
                params: {
                    jahrMonat: filterState.datum?.value,
                },
            });

            const allFilialenData = response.data.results;

            const {
                umsatzSumme,
                umsatzVJSumme,
                umsatzAbweichungVM,
                umsatzAbweichung6M,
                umsatzAbweichungVJ,
                kundenanzahlSumme,
                kundenanzahlVJSumme,
                kundenanzahlAbweichungVM,
                kundenanzahlAbweichung6M,
                kundenanzahlAbweichungVJ,
                leistung,
                leistungVJ,
                kundenbon,
                kundenbonVJ,
            } = calculatePerformanceData(allFilialenData);

            setPerformanceData({
                umsatz: umsatzSumme,
                umsatzVJ: umsatzVJSumme,
                umsatzAbweichungVM,
                umsatzAbweichung6M,
                umsatzAbweichungVJ,
                kundenanzahl: kundenanzahlSumme,
                kundenanzahlVJ: kundenanzahlVJSumme,
                kundenanzahlAbweichungVM,
                kundenanzahlAbweichung6M,
                kundenanzahlAbweichungVJ,
                leistung,
                leistungVJ,
                kundenbon,
                kundenbonVJ,
                chartData: allFilialenData.map((item) => ({
                    umsatz: item.umsatz || 0,
                    kundenanzahl: item.kundenanzahl || 0,
                    leistung: item.leistung || 0,
                    kundenbon: item.kundenbon || 0,
                })),
            });
        } catch (error) {
            console.error('Error fetching performance data:', error);
        }
    };

    // Helper function to calculate the last 6 months based on the selected date
    const getLast6Months = (currentMonth) => {
        const last6Months = [];
        const formattedMonth = dayjs(currentMonth, 'YYYY.MM'); // Das gefilterte Datum korrekt formatieren
    
        for (let i = 0; i < 6; i++) {
            last6Months.push(formattedMonth.subtract(i, 'month').format('YYYY.MM')); // Rückwirkend vom gefilterten Monat die letzten 6 Monate berechnen
        }
        
        return last6Months.reverse(); // Optional, um die Monate in chronologischer Reihenfolge anzuzeigen (älteste zuerst)
    };

    const fetchPerformanceDataLast6Months = async () => {
        const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM'); // Verwende 'YYYY.MM'
        const last6Months = getLast6Months(currentMonth);
        
        try {
            const responses = await Promise.all(
                last6Months.map(month => axiosInstance.get('/scorecards_month/performance-kennzahl-gesamt/', {
                    params: { jahrMonat: month },
                }))
            );
    
            const last6MonthsData = responses.map((response, index) => {
                const umsatzSumme = response.data.results
                    .filter(filiale => filiale.umsatz > 1) // Nur Umsätze > 1
                    .reduce((sum, filiale) => sum + (filiale.umsatz || 0), 0);
                
                const kundenanzahlSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.kundenanzahl || 0), 0);

                const produktivstundenSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.produktivstunden || 0), 0);
                
                const kundenbon = kundenanzahlSumme > 0 ? umsatzSumme / kundenanzahlSumme : 0;
                const leistung = produktivstundenSumme > 0 ? umsatzSumme / produktivstundenSumme : 0;

                return {
                    month: last6Months[index],
                    umsatz: umsatzSumme,
                    kundenanzahl: kundenanzahlSumme,
                    kundenbon: kundenbon,
                    leistung: leistung
                };
            });

            setPerformanceLast6Months(last6MonthsData); // Setze die Daten für alle Diagramme und Karten
        } catch (error) {
            console.error('Error fetching data for last 6 months:', error);
        }
    };

    const fetchRetourenData = async () => {
        try {
            const response = await axiosInstance.get('/scorecards_month/produkte-warengruppe/', {
                params: {
                    jahrMonat: filterState.datum?.value, // Nur der gefilterte Monat
                },
            });
    
            // Erstelle eine Struktur, um die kumulierten Retourenwerte zu speichern
            const aggregatedRetourenData = response.data.results.reduce((acc, item) => {
                const existingEntry = acc.find(entry => entry.warengruppennummer === item.warengruppennummer);
                const retourwert = item.retourwert || 0;
    
                if (existingEntry) {
                    // Kumulierung der Retourenwerte
                    existingEntry.retourwert += retourwert;
                } else {
                    // Neues Warengruppen-Element hinzufügen
                    acc.push({
                        warengruppennummer: item.warengruppennummer,
                        warengruppe: item.warengruppe,
                        retourwert,
                    });
                }
    
                return acc;
            }, []);
    
            setRetourenData(aggregatedRetourenData); // Setze die aggregierten Retourenwerte
        } catch (error) {
            console.error('Error fetching retouren data:', error);
        }
    };
    


    const fetchPersonalData = async () => {
        const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM'); // Verwende das gefilterte Datum
        const last6Months = getLast6Months(currentMonth); // Letzten 6 Monate berechnen
    
        try {
            const responses = await Promise.all(
                last6Months.map(month => axiosInstance.get('/scorecards_month/personal-kennzahl/', {
                    params: { jahrMonat: month },
                }))
            );
    
            // Kumulieren der Krankenquote-Daten
            const krankenquoteData = responses.map((response, index) => {
                const data = response.data.results;
    
                const arbeitszeitKrankenqoute = data.reduce((sum, item) => sum + (item.arbeitszeitKrankenqoute || 0), 0);
                const krankenzeit = data.reduce((sum, item) => sum + (item.krankenzeit || 0), 0);
                const arbeitszeitKrankenqouteVM = data.reduce((sum, item) => sum + (item.arbeitszeitKrankenqouteVM || 0), 0);
                const krankenzeitVM = data.reduce((sum, item) => sum + (item.krankenzeitVM || 0), 0);
                const arbeitszeitKrankenqouteVJ = data.reduce((sum, item) => sum + (item.arbeitszeitKrankenqouteVJ || 0), 0);
                const krankenzeitVJ = data.reduce((sum, item) => sum + (item.krankenzeitVJ || 0), 0);
    
                // Berechne die Krankenquote
                const krankenquote = arbeitszeitKrankenqoute > 0 ? (krankenzeit / arbeitszeitKrankenqoute) * 100 : 0;
                const krankenquoteVM = arbeitszeitKrankenqouteVM > 0 ? (krankenzeitVM / arbeitszeitKrankenqouteVM) * 100 : 0;
                const krankenquoteVJ = arbeitszeitKrankenqouteVJ > 0 ? (krankenzeitVJ / arbeitszeitKrankenqouteVJ) * 100 : 0;
    
                return {
                    month: last6Months[index],
                    arbeitszeitKrankenqoute,
                    krankenzeit,
                    krankenquote,
                    krankenquoteVM,
                    krankenquoteVJ,
                };
            });
    
            // Kumulieren der Fluktuation-Daten
            const fluktuationData = responses.map((response, index) => {
                const data = response.data.results;
    
                const austritte6M = data.reduce((sum, item) => sum + (item.austritte6M || 0), 0);
                const mitarbeiterbestand6M = data.reduce((sum, item) => sum + (item.mitarbeiterbestand6M || 0), 0);
                const eintritte6M = data.reduce((sum, item) => sum + (item.eintritte6M || 0), 0);
                const köpfeDifferenz = data.reduce((sum, item) => sum + (item.köpfeDifferenz || 0), 0);
                const stundenDifferenz = data.reduce((sum, item) => sum + (item.stundenDifferenz || 0), 0);
                const überstundenAbs = data.reduce((sum, item) => sum + (item.überstundenAbs || 0), 0);
    
                // Berechne die Fluktuation
                const fluktuation = (mitarbeiterbestand6M + eintritte6M) > 0 
                    ? (austritte6M / (mitarbeiterbestand6M + eintritte6M)) * 100 
                    : 0; // Ergebnis in %
    
                // Nur Eintritte und Austritte des gefilterten Monats
                const eintritteFiltered = data.reduce((sum, item) => sum + (item.eintritte || 0), 0);
                const austritteFiltered = data.reduce((sum, item) => sum + (item.austritte || 0), 0);
    
                // **Kumulieren der EintritteM und AustritteM** für alle Filialen
                const eintritteM = data.reduce((sum, item) => sum + (item.eintritteM || 0), 0); // Kumulierte Eintritte (M)
                const austritteM = data.reduce((sum, item) => sum + (item.austritteM || 0), 0); // Kumulierte Austritte (M)
    
                return {
                    month: last6Months[index],
                    austritte6M,
                    mitarbeiterbestand6M,
                    eintritte6M,
                    fluktuation,
                    eintritteFiltered,
                    austritteFiltered,
                    eintritteM, // Kumulierte Eintritte (M)
                    austritteM,  // Kumulierte Austritte (M)
                    köpfeDifferenz, // Kumulierte Köpfe-Differenz (Bedarf an Mitarbeiter)
                    stundenDifferenz, // Kumulierte Stunden-Differenz (Bedarf an Stunden)
                    überstundenAbs,  // Kumulierte Überstunden (Auslastung)
                };
            });
    
            setPersonalData({
                krankenquote: krankenquoteData,
                fluktuation: fluktuationData,
            });
        } catch (error) {
            console.error('Error fetching personal data:', error);
        }
    };
    
    const fetchWarengruppenData = async () => {
        try {
            const response = await axiosInstance.get('/scorecards_month/produkte-warengruppe-gesamt/', {
                params: {
                    jahrMonat: filterState.datum?.value, // Nur der gefilterte Monat
                },
            });
    
            // Erstelle eine Struktur, um die kumulierten Werte zu speichern
            const aggregatedData = response.data.results.reduce((acc, item) => {
                const existingEntry = acc.find(entry => entry.warengruppennummer === item.warengruppennummer);
                const verkaufsmenge = item.verkaufsmenge || 0;
                const verkaufsmengeVJ = item.verkaufsmengeVJ || 0;
    
                if (existingEntry) {
                    // Kumulierung der Verkaufsmenge und Verkaufsmenge des Vorjahres
                    existingEntry.verkaufsmenge += verkaufsmenge;
                    existingEntry.verkaufsmengeVJ += verkaufsmengeVJ;
                } else {
                    // Neues Warengruppen-Element hinzufügen
                    acc.push({
                        warengruppennummer: item.warengruppennummer,
                        warengruppe: item.warengruppe,
                        verkaufsmenge,
                        verkaufsmengeVJ,
                    });
                }
    
                return acc;
            }, []);
    
            // Nach Kumulierung der Verkaufsmenge und Vorjahresverkaufsmenge die Steigerung berechnen
            const finalData = aggregatedData.map((entry) => {
                const { verkaufsmenge, verkaufsmengeVJ } = entry;
    
                // Berechne die prozentuale Steigerung für jede Warengruppe
                const steigerungProzent = verkaufsmengeVJ > 0
                    ? ((verkaufsmenge - verkaufsmengeVJ) / verkaufsmengeVJ) * 100
                    : 0;
    
                return {
                    ...entry,
                    steigerungProzent: steigerungProzent.toFixed(2), // Runde auf 2 Dezimalstellen
                };
            });
    
            setWarengruppenData(finalData); // Setze die aggregierten und berechneten Daten
        } catch (error) {
            console.error('Error fetching warengruppen data:', error);
        }
    };
    
    
    
    
    
    const columns = [
        { accessorKey: 'warengruppe', header: 'Warengruppe' },
        { accessorKey: 'verkaufsmenge', header: 'Verkaufsmenge' },
        { accessorKey: 'verkaufsmengeVJ', header: 'Verkaufsmenge VJ' },
        { accessorKey: 'steigerungProzent', header: 'Steigerung (%)' }, // Neue Spalte für die Steigerung
    ];
    
    const retourenColumns = [
        { accessorKey: 'warengruppe', header: 'Warengruppe' },
        { accessorKey: 'retourwert', header: 'Retourenwert' },
    ];
    
    


    return (
        <Layout>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', mt: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PageHeader
                        title="Monats Scorecards Unternehmen"
                        subtitle="Monatliche Übersicht der Kernkennzahlen für das gesamte Unternehmen, um relevante Fokus-Themen abzuleiten und in die strategische Planung zu integrieren."
                        Icon={BarChartIcon}
                    />
                </Box>

                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <DropdownFilter
                        label="Datum"
                        options={datumOptions}
                        value={filterState.datum}
                        onChange={handleDatumChange}
                    />
                </Box>
            </Box>

            <Grid container spacing={3} mt={4}>
    {/* Umsatz */}
    <Grid item xs={12} md={4}>
        <Stack direction="column" spacing={2}>
            <DataCard
                category="Umsatz"
                value={performanceData.umsatz ? performanceData.umsatz.toFixed(2) : '0.00'}
                sector="performance"
                subHeaders={[
                    `${performanceData.umsatzAbweichungVM.toFixed(2)}% zum Vormonat`,
                    `${performanceData.umsatzAbweichung6M.toFixed(2)}% zum 6 Monats Schnitt`,
                    `${performanceData.umsatzAbweichungVJ.toFixed(2)}% zum Vorjahr`
                ]}
                chartData={performanceLast6Months.map(item => item.umsatz)} // Nutze Daten der letzten 6 Monate für das Diagramm
            />
        </Stack>
    </Grid>

    {/* Kundenanzahl */}
    <Grid item xs={12} md={4}>
        <Stack direction="column" spacing={2}>
            <DataCard
                category="Kundenanzahl"
                sector="performance"
                value={performanceData.kundenanzahl ? performanceData.kundenanzahl.toFixed(2) : '0.00'}
                subHeaders={[
                    `${performanceData.kundenanzahlAbweichungVM.toFixed(2)}% zum Vormonat`,
                    `${performanceData.kundenanzahlAbweichung6M.toFixed(2)}% zum 6 Monats Schnitt`,
                    `${performanceData.kundenanzahlAbweichungVJ.toFixed(2)}% zum Vorjahr`
                ]}
                chartData={performanceLast6Months.map(item => item.kundenanzahl)} // Nutze Daten der letzten 6 Monate für das Diagramm
            />
        </Stack>
    </Grid>

    {/* Kundenbon */}
    <Grid item xs={12} md={4}>
        <Stack direction="column" spacing={2}>
            <DataCard
                category="Kundenbon"
                sector="performance"
                value={performanceData.kundenbon ? performanceData.kundenbon.toFixed(2) : '0.00'}
                subHeaders={[
                    `${performanceData.kundenbonVJ.toFixed(2)}€ im letzten Jahr`,
                    `${((performanceData.kundenbon - performanceData.kundenbonVJ) / performanceData.kundenbonVJ * 100).toFixed(2)}% zum Vorjahr`
                ]}
                chartData={performanceLast6Months.map(item => item.kundenbon)} // Nutze Daten der letzten 6 Monate für das Diagramm
            />
        </Stack>
    </Grid>

    {/* Leistung */}
    <Grid item xs={12} md={4}>
        <Stack direction="column" spacing={2}>
            <DataCard
                category="Leistung"
                sector="performance"
                value={performanceData.leistung ? performanceData.leistung.toFixed(2) : '0.00'}
                subHeaders={[
                    `${performanceData.leistungVJ.toFixed(2)} € im letzten Jahr`,
                    `${((performanceData.leistung - performanceData.leistungVJ) / performanceData.leistungVJ * 100).toFixed(2)}% zum Vorjahr`
                ]}
                chartData={performanceLast6Months.map(item => item.leistung)} // Nutze Daten der letzten 6 Monate für das Diagramm
            />
        </Stack>
    </Grid>

    {/* Krankenquote */}
    <Grid item xs={12} md={4}>
        <Stack direction="column" spacing={2}>
            <DataCard
                category="Krankenquote"
                sector="personal"
                value={personalData.krankenquote.length > 0 ? personalData.krankenquote[personalData.krankenquote.length - 1].krankenquote.toFixed(2) : '0.00'}
                subHeaders={[
                    `${personalData.krankenquote.length > 0 ? personalData.krankenquote[personalData.krankenquote.length - 1].krankenquoteVM.toFixed(2) : '0.00'}% zum Vormonat`,
                    `${personalData.krankenquote.length > 0 ? personalData.krankenquote[personalData.krankenquote.length - 1].krankenquoteVJ.toFixed(2) : '0.00'}% zum Vorjahr`
                ]}
                chartData={personalData.krankenquote.map(item => item.krankenquote)} // Nutze die Krankenquote-Daten für den Sparkline-Chart
            />
        </Stack>
    </Grid>

    {/* Fluktuation */}
    <Grid item xs={12} md={4}>
        <Stack direction="column" spacing={2}>
            <DataCard
                category="Fluktuation"
                sector="personal"
                value={personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].fluktuation.toFixed(2) : '0.00'}
                subHeaders={[
                    `${personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].eintritteM.toFixed(2) : '0.00'} Eintritte im gefilterten Monat`,
                    `${personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].austritteM.toFixed(2) : '0.00'} Eintritte im gefilterten Monat`
                ]}
                chartData={personalData.fluktuation.map(item => item.fluktuation)} // Nutze die Fluktuation-Daten für den Sparkline-Chart
            />
        </Stack>
    </Grid>

    {/* Bedarf */}
    <Grid item xs={12} md={4}>
        <Stack direction="column" spacing={2}>
            <DataCard
                category="Bedarf"
                sector="personal"
                value={`${personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].köpfeDifferenz : '0'} Köpfe und ${personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].stundenDifferenz : '0'} Stunden`}
                chartData={personalData.fluktuation.map(item => item.köpfeDifferenz)} 
            />
        </Stack>
    </Grid>

    {/* Auslastung */}
    <Grid item xs={12} md={4}>
        <Stack direction="column" spacing={2}>
            <DataCard
                category="Auslastung"
                sector="personal"
                value={`${personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].überstundenAbs : '0'} Überstunden`}
                subHeaders={[]}
                chartData={personalData.fluktuation.map(item => item.überstundenAbs)} 
            />
        </Stack>
    </Grid>
</Grid>

<Grid container spacing={3} mt={4}>
    {/* Umsatz, Kundenanzahl, etc. (deine bestehenden DataCards) */}
    {/* ... */}

    {/* Tabelle mit den Verkaufsdaten nach Warengruppe */}
    <Grid item xs={12}>
    <Card
            sx={{
                padding: theme.spacing(2), // Padding für besseren Abstand
                backgroundColor: 'rgba(255, 255, 255, 0.7)', // Weißer, leicht transparenter Hintergrund
                backdropFilter: 'blur(10px)', // Blur-Effekt für den Hintergrund
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                {/* Überschrift der Card */}
                <Stack direction="row" alignItems="center" spacing={1}>
                    <BarChartIcon /> {/* Beispiel-Icon, kann durch ein passendes Icon ersetzt werden */}
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: theme.typography.h5.fontWeight,
                            fontSize: theme.typography.h6.fontSize,
                            color: theme.palette.primary.main,
                        }}
                    >
                        Verkaufsmenge nach Warengruppe
                    </Typography>
                </Stack>
            </Box>

            {/* Tabelle mit den Daten */}
            <Box>
                <VariableTable
                    columns={columns} // Spalten der Tabelle
                    data={warengruppenData} // Daten für die Tabelle
                    onRowClick={(row) => console.log('Clicked row:', row)} // Optional: Click-Handler für die Zeilen
                />
            </Box>
        </Card>
    </Grid>
</Grid>

<Grid container spacing={3} mt={4}>
    {/* Retourenwerte nach Warengruppe */}
    <Grid item xs={12}>
        <Card
            sx={{
                padding: theme.spacing(2),
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                backdropFilter: 'blur(10px)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <BarChartIcon />
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: theme.typography.h5.fontWeight,
                            fontSize: theme.typography.h6.fontSize,
                            color: theme.palette.primary.main,
                        }}
                    >
                        Retourenwerte nach Warengruppe
                    </Typography>
                </Stack>
            </Box>

            {/* Tabelle für Retourenwerte */}
            <Box>
                <VariableTable
                    columns={retourenColumns}
                    data={retourenData}
                    onRowClick={(row) => console.log('Clicked row:', row)}
                />
            </Box>
        </Card>
    </Grid>
</Grid>

        </Layout>
    );
};

export default Cockpit;
