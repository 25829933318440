import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import VariableTable from '../table/VariableTable';
import TagesreportMainArtikelPopup from './TagesreportMainArtikelPopup'; // Import the new popup
import axiosInstance from '../../services/axiosInstance';
import dayjs from "dayjs";
import { formatPercentage, formatPercentageWithoutMultiplier } from "../../utils/mathStuff";
import CloseIcon from '@mui/icons-material/Close';

const TagesreportDetailsPopup = ({ open, handleClose, filialeData }) => {
    console.log("Datum", filialeData);
    if (!filialeData) {
        return null; // Nichts rendern, wenn filialeData null ist
    }

    const { Datum, Filiale } = filialeData;
    const [articleData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filialnummer, setFilialnummer] = useState("");
    const [selectedWarengruppe, setSelectedWarengruppe] = useState(null);  // State for selected Warengruppe
    const [artikelPopupOpen, setArtikelPopupOpen] = useState(false);  // State for the Artikel Popup

    const formatDate = (dateString) => {
        return dayjs(dateString).format('dddd, D. MMMM YYYY');
    };

    console.log("Datum", Datum);

    useEffect(() => {
        const fetchFilialnummer = async () => {
            if (filialeData && Filiale) {
                try {
                    const response = await axiosInstance.get("/stammdaten/unique-filialnummer/", {
                        params: { filiale: Filiale },
                    });
                    if (response.data.filialnummer) {
                        setFilialnummer(response.data.filialnummer);
                    }
                } catch (error) {
                    console.error("Fehler beim Laden der Filialnummer:", error);
                }
            }
        };
    
        fetchFilialnummer();
    }, [Filiale]);
    

    useEffect(() => {
        if (open && Datum && Filiale) {
            setLoading(true);
            fetchTableData().finally(() => setLoading(false));
        }
    }, [open, Datum, Filiale]);
    
    

    const articleColumns = [
        { id: 'Filiale', accessorKey: 'Filiale', header: 'Filiale' },
        { id: 'Datum', accessorKey: 'Datum', header: 'Datum' },
        { id: 'Warengruppe', accessorKey: 'Warengruppe', header: 'Warengruppe' },
        { id: 'Verkaufsmenge', accessorKey: 'Verkaufsmenge', header: 'Verkaufsmenge' },
        { id: 'Retourmenge', accessorKey: 'Retourmenge', header: 'Retourmenge' },
        { id: 'Retourquote', accessorKey: 'Retourquote', header: 'Retourquote' },
    ];

    const fetchTableData = async () => {

        if (!Datum || !Filiale) {
            console.error('Datum oder Filiale fehlt');
            return;
        }
        try {
            const response = await axiosInstance.get('/fact/facts/', {
                params: {
                    filiale: Filiale,
                    datum: Datum,
                }
            });
            
            if (Array.isArray(response.data.results)) {
                const aggregatedData = response.data.results.reduce((acc, item) => {
                    const existingGroup = acc.find(group => group.Warengruppe === item.warengruppe);
                    
                    const verkaufsmenge = parseFloat(item.verkaufsmenge) || 0;
                    const retourmenge = parseFloat(item.retourmenge) || 0;

                    if (existingGroup) {
                        existingGroup.Verkaufsmenge += verkaufsmenge;
                        existingGroup.Retourmenge += retourmenge;
                    } else {
                        acc.push({
                            Filiale: item.filiale,
                            Datum: dayjs(item.datum).format('YYYY-MM-DD'),
                            Warengruppe: item.warengruppe,
                            Verkaufsmenge: verkaufsmenge,
                            Retourmenge: retourmenge,
                        });
                    }
                    
                    return acc;
                }, []);

                const aggregatedDataWithQuote = aggregatedData.map(group => ({
                    ...group,
                    Retourquote: `${formatPercentage(group.Retourmenge / (group.Verkaufsmenge + group.Retourmenge))} %`
                }));

                setTableData(aggregatedDataWithQuote); 
            } else {
                console.error('No data received from the backend');
            }
        } catch (error) {
            console.error('Error while fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleRowClick = (row) => {
        if (row && row.Warengruppe) {
            setSelectedWarengruppe(row.Warengruppe);  // Save the selected Warengruppe
            setArtikelPopupOpen(true);  // Open the Artikel popup
        }
    };
    

    return (
        <>
            <Dialog open={Boolean(open)} onClose={handleClose} maxWidth="xl" fullWidth>
                <DialogTitle>Details für Filiale {Filiale}

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                </DialogTitle>
                <DialogContent dividers={true} sx={{ maxHeight: '70vh'}}>
                    <VariableTable
                        data={articleData}
                        columns={articleColumns}
                        onRowClick={handleRowClick}  // Add row click handler
                    />
                </DialogContent>
                
            </Dialog>

            {/* Artikel Popup */}
            <TagesreportMainArtikelPopup
                open={artikelPopupOpen}
                handleClose={() => setArtikelPopupOpen(false)}
                warengruppe={selectedWarengruppe}
                filiale={Filiale}
                datum={Datum}
            />
        </>
    );
};

export default TagesreportDetailsPopup;
