import React, { useEffect, useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { useTheme } from '@mui/material/styles';

const BarChartHorizontal = ({ title, labels, data }) => {
    const theme = useTheme();
    const [leftMargin, setLeftMargin] = useState(100); // Standardwert für den linken Rand

    useEffect(() => {
        // Temporäres Element zur Messung der Breite der Labels erstellen
        const tempDiv = document.createElement('div');
        document.body.appendChild(tempDiv);
        tempDiv.style.position = 'absolute';
        tempDiv.style.visibility = 'hidden';
        tempDiv.style.whiteSpace = 'nowrap';
        tempDiv.style.fontSize = '14px'; // Verwende die gleiche Schriftgröße wie im Chart

        // Maximalbreite der Labels berechnen
        let maxWidth = 0;
        labels.forEach(label => {
            tempDiv.textContent = label;
            maxWidth = Math.max(maxWidth, tempDiv.offsetWidth);
        });

        document.body.removeChild(tempDiv);

        // Setze den linken Rand basierend auf der maximalen Breite der Labels
        setLeftMargin(maxWidth + 10); // Füge 10px Puffer hinzu
    }, [labels]);

    return (
        <BarChart
            xAxis={[
                {
                    scaleType: 'linear',
                    show: true,
                    label: null,
                },
            ]}
            yAxis={[
                {
                    data: labels,
                    scaleType: 'band',
                    show: true,
                    label: null,
                },
            ]}
            series={[
                {
                    data: data,
                    color: theme.palette.primary.main,
                    
                },
            ]}
            barLabel="value"
            layout="horizontal"
            height={500}
            width={800}
            margin={{ top: 10, bottom: 20, left: leftMargin, right: 10 }} // Dynamischer linker Rand
            isAnimationActive={false}
        />
    );
};

export default BarChartHorizontal;
