import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Typography, List, ListItem, ListItemText, IconButton, Collapse, Alert } from '@mui/material';
import axiosInstance from '../../services/axiosInstance';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const FilialcheckKonfiguration = ({ open, handleClose, checkId, checkName }) => {
    const [categoryName, setCategoryName] = useState('');
    const [checkpointName, setCheckpointName] = useState('');
    const [categories, setCategories] = useState([]);
    const [checkpoints, setCheckpoints] = useState([]);
    const [error, setError] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
    const [openCheckpointDialog, setOpenCheckpointDialog] = useState(false);

    useEffect(() => {
        if (open) {
            console.log(`Fetching categories and checkpoints for checkId: ${checkId}`);

            axiosInstance.get(`/filialcheck/categories/`)
                .then(response => {
                    console.log('Categories fetched:', response.data.results);
                    const filteredCategories = response.data.results.filter(category => category.check_related === checkId);
                    setCategories(filteredCategories);
                })
                .catch(error => {
                    console.error('Error fetching categories:', error);
                });

            axiosInstance.get(`/filialcheck/checkpoints/`)
                .then(response => {
                    console.log('Checkpoints fetched:', response.data.results);
                    setCheckpoints(response.data.results);
                })
                .catch(error => {
                    console.error('Error fetching checkpoints:', error);
                });
        }
    }, [open, checkId]);

    const handleSaveCategory = () => {
        if (categoryName.trim() === '') {
            setError('Kategoriename darf nicht leer sein.');
            return;
        }

        console.log('Saving new category:', { name: categoryName, check_related: checkId });

        axiosInstance.post('/filialcheck/categories/', { name: categoryName, check_related: checkId })
            .then((response) => {
                console.log('Category saved:', response.data);
                setCategories([...categories, response.data]);
                setCategoryName('');
                setError(null);
                setOpenCategoryDialog(false);
            })
            .catch((error) => {
                setError('Fehler beim Speichern der Kategorie.');
                console.error('Error saving category:', error);
            });
    };

    const handleSaveCheckpoint = () => {
        if (checkpointName.trim() === '') {
            setError('Überprüfungspunktname darf nicht leer sein.');
            return;
        }

        console.log('Saving new checkpoint:', { name: checkpointName, category: selectedCategory });

        axiosInstance.post('/filialcheck/checkpoints/', { name: checkpointName, category: selectedCategory })
            .then((response) => {
                console.log('Checkpoint saved:', response.data);
                setCheckpoints([...checkpoints, response.data]);
                setCheckpointName('');
                setError(null);
                setOpenCheckpointDialog(false);
            })
            .catch((error) => {
                setError('Fehler beim Speichern des Überprüfungspunkts.');
                console.error('Error saving checkpoint:', error);
            });
    };

    const handleDeleteCategory = (id) => {
        console.log('Deleting category with id:', id);

        axiosInstance.delete(`/filialcheck/categories/${id}`)
            .then(() => {
                console.log('Category deleted:', id);
                setCategories(categories.filter(category => category.id !== id));
            })
            .catch(error => {
                console.error('Error deleting category:', error);
            });
    };

    const handleDeleteCheckpoint = (id) => {
        console.log('Deleting checkpoint with id:', id);

        axiosInstance.delete(`/filialcheck/checkpoints/${id}`)
            .then(() => {
                console.log('Checkpoint deleted:', id);
                setCheckpoints(checkpoints.filter(checkpoint => checkpoint.id !== id));
            })
            .catch(error => {
                console.error('Error deleting checkpoint:', error);
            });
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>Filialcheck Konfiguration - {checkName}</DialogTitle>
                <DialogContent>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}
                    <Box component="form" sx={{ mb: 2 }}>
                        <List>
                            {categories.length > 0 ? categories.map((category) => (
                                <CategoryItem
                                    key={category.id}
                                    category={category}
                                    selectedCategory={selectedCategory}
                                    setSelectedCategory={setSelectedCategory}
                                    checkpoints={checkpoints}
                                    handleDeleteCategory={handleDeleteCategory}
                                    handleDeleteCheckpoint={handleDeleteCheckpoint}
                                    setOpenCheckpointDialog={setOpenCheckpointDialog}
                                />
                            )) : (
                                <Typography variant="body2" color="textSecondary">Keine Kategorien vorhanden.</Typography>
                            )}
                        </List>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setOpenCategoryDialog(true)}
                            sx={{ mt: 2 }}
                        >
                            Neue Kategorie anlegen
                        </Button>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>

            <CategoryDialog
                open={openCategoryDialog}
                onClose={() => setOpenCategoryDialog(false)}
                categoryName={categoryName}
                setCategoryName={setCategoryName}
                handleSaveCategory={handleSaveCategory}
            />

            <CheckpointDialog
                open={openCheckpointDialog}
                onClose={() => setOpenCheckpointDialog(false)}
                checkpointName={checkpointName}
                setCheckpointName={setCheckpointName}
                handleSaveCheckpoint={handleSaveCheckpoint}
            />
        </>
    );
};

const CategoryItem = ({ category, selectedCategory, setSelectedCategory, checkpoints, handleDeleteCategory, handleDeleteCheckpoint, setOpenCheckpointDialog }) => (
    <Box sx={{ border: '1px solid #ddd', borderRadius: '8px', p: 2, mb: 2 }}>
        <ListItem
            secondaryAction={
                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCategory(category.id)}>
                    <DeleteIcon />
                </IconButton>
            }
            onClick={() => setSelectedCategory(selectedCategory === category.id ? null : category.id)}
            selected={selectedCategory === category.id}
        >
            <ListItemText primary={category.name} />
        </ListItem>
        <Collapse in={selectedCategory === category.id} timeout="auto" unmountOnExit>
            <Box sx={{ ml: 4, mt: 2, mb: 2 }}>
                <CheckpointList
                    checkpoints={checkpoints.filter(checkpoint => checkpoint.category === category.id)}
                    handleDeleteCheckpoint={handleDeleteCheckpoint}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenCheckpointDialog(true)}
                    sx={{ mt: 2 }}
                >
                    Überprüfungspunkt hinzufügen
                </Button>
            </Box>
        </Collapse>
    </Box>
);

const CheckpointList = ({ checkpoints, handleDeleteCheckpoint }) => (
    <List sx={{ pl: 4 }}>
        {checkpoints.length > 0 ? (
            checkpoints.map((checkpoint) => (
                <Box key={checkpoint.id} sx={{ border: '1px solid #ddd', borderRadius: '8px', p: 2, mb: 1 }}>
                    <ListItem
                        secondaryAction={
                            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCheckpoint(checkpoint.id)}>
                                <DeleteIcon />
                            </IconButton>
                        }
                    >
                        <ListItemText primary={checkpoint.name} />
                    </ListItem>
                </Box>
            ))
        ) : (
            <Typography variant="body2" color="textSecondary" sx={{ pl: 4 }}>
                Keine Überprüfungspunkte vorhanden.
            </Typography>
        )}
    </List>
);

const CategoryDialog = ({ open, onClose, categoryName, setCategoryName, handleSaveCategory }) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Neue Kategorie anlegen</DialogTitle>
        <DialogContent>
            <TextField
                label="Kategoriename"
                fullWidth
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                margin="normal"
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSaveCategory} variant="contained" color="primary">
                Speichern
            </Button>
            <Button onClick={onClose} variant="outlined">
                Abbrechen
            </Button>
        </DialogActions>
    </Dialog>
);

const CheckpointDialog = ({ open, onClose, checkpointName, setCheckpointName, handleSaveCheckpoint }) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Neuen Überprüfungspunkt anlegen</DialogTitle>
        <DialogContent>
            <TextField
                label="Überprüfungspunkt Name"
                fullWidth
                value={checkpointName}
                onChange={(e) => setCheckpointName(e.target.value)}
                margin="normal"
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSaveCheckpoint} variant="contained" color="primary">
                Speichern
            </Button>
            <Button onClick={onClose} variant="outlined">
                Abbrechen
            </Button>
        </DialogActions>
    </Dialog>
);

export default FilialcheckKonfiguration;
