import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import Documentation from "./Documentation";
import { Fab, Box } from "@mui/material";

const ScorecardsNotes = () => {
  const [docOpen, setDocOpen] = useState(false);

  const handleFabClick = () => {
    setDocOpen(true);
  };

  const handleDocClose = () => {
    setDocOpen(false);
  };

  return (
    <Box sx={{ display: "flex", transition: "margin 0.3s ease-in-out" }}>
      {/* Main content area */}
      <Box
        sx={{
          flexGrow: 1,
          marginRight: docOpen ? "350px" : "0px", // Adjust the margin when sidebar is open
          transition: "margin 0.3s ease-in-out",
        }}
      >
        {/* Your main content goes here */}
        <Fab
          color="primary"
          aria-label="add"
          sx={{
            position: "fixed",
            bottom: 50,
            right: 50,
            width: 80,
            height: 80,
            fontSize: "2rem",
          }}
          onClick={handleFabClick}
        >
          <AddIcon fontSize="inherit" />
        </Fab>
      </Box>

      {/* Sidebar */}
      <Documentation open={docOpen} handleClose={handleDocClose} />
    </Box>
  );
};

export default ScorecardsNotes;
