import { Box, Typography } from "@mui/material";
import ThekeSection from "./ThekeSection";

const ThekeGroup = ({ title, sectionCount, onDrop, selectedArtikel }) => {
    // console.log("group selectedArtikel", selectedArtikel);
    return (
        <Box
            sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)", // 4 columns
                gap: 2,
                padding: 2,
                border: "2px solid",
                borderColor: "secondary.main",
                borderRadius: 6,
                width: "100%", // Set width to 100%
                maxWidth: "662px", // Keep maxWidth to limit size
                margin: "0",
            }}
        >
            <Typography variant="h5" sx={{ textAlign: "center", gridColumn: "span 4" }}>
                {title}
            </Typography>
            <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 2 }}>
                {[...Array(sectionCount)].map((_, index) => (
                    <ThekeSection
                        key={index}
                        onDrop={onDrop}
                        selectedArtikel={selectedArtikel.slice(index, index + 1)}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default ThekeGroup;
