import React, {createContext, useState} from 'react';

export const FilterContext = createContext();

export const FilterProvider = ({children}) => {
    const [filterState, setFilterState] = useState({filiale: null, datum: null});

    const handleFilialeChange = (newFiliale) => {
        setFilterState((prevState) => ({...prevState, filiale: newFiliale}));
    };

    const handleDatumChange = (newDatum) => {
        setFilterState((prevState) => ({...prevState, datum: newDatum}));
    };

    return (
        <FilterContext.Provider value={{filterState, handleFilialeChange, handleDatumChange}}>
            {children}
        </FilterContext.Provider>
    );
};
