import * as React from 'react';
import {LineChart} from '@mui/x-charts/LineChart';
import {Typography} from '@mui/material';

export default function BasicLineChart({data, numDataPoints = 7}) {
    if (data.length === 0) {
        return <Typography>Keine Daten Verfügbar für das Linien-Diagramm</Typography>;
    }

    /* check length of data array */
    if (data.length > numDataPoints) {
        return (
            <Typography>Zu viele Daten für das Linien-Diagramm</Typography>
        );
    } else {
        return (
            <>
                <LineChart
                    series={[
                        {
                            data: data,
                            showMark: false,
                            color: '#C3D98C',
                        },
                    ]}
                    margin={{top: 20, right: 5, bottom: 5, left: 5}}
                    height={100}
                    leftAxis={null}
                    bottomAxis={null}
                />
            </>
        );
    }

}
