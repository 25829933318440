import * as React from 'react';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


export default function Score({score}) {
    const circularProgressValue = score * 10;
    const theme = useTheme();
    const color = score < 5 ? theme.palette.error.main : theme.palette.success.main;
    return (
        <Box className="db_score">
            <Typography>
                {score}
            </Typography>
            <CircularProgress size="30px" className="db_score_circle" variant="determinate" value={100} style={{color: theme.palette.grey[300]}}/>
            <CircularProgress size="30px" className="db_score_circle" variant="determinate" value={circularProgressValue} style={{color: color}}/>
        </Box>        
    );
}
