import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import BasicLines from "../../charts/LineChartVJ";

const BigChartCard = ({ data, title, infoContent, height, width }) => {
  return (
    <Paper>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          height: height || "100%",
          width: width || "100%",
          padding: "0px",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          backdropFilter: "blur(10px)",
        }}
      >
        <CardHeader
          title={
            <Typography variant="h6" component="div">
              {title}
            </Typography>
          }
          action={
            infoContent && (
              <Tooltip title={infoContent} arrow placement="top">
                <IconButton>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            )
          }
        />
        <CardContent sx={{ flexGrow: 1 }}>
          {data && data.length > 0 && <BasicLines data={data} height={height ? height - 100 : undefined} />}
        </CardContent>
      </Card>
    </Paper>
  );
};

export default BigChartCard;
