import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box,
} from "@mui/material";
import dayjs from "dayjs";
import axiosInstance from "../../../services/axiosInstance";

const Summary = ({
  open,
  handleClose,
  currentMonth, // Dies wird von der Documentation-Komponente übergeben
  handlePreviousMonth,
  handleNextMonth,
  filiale,
}) => {
  const [reportData, setReportData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  useEffect(() => {
    if (open) {
      setSelectedMonth(currentMonth);
    }
  }, [open, currentMonth]);

  useEffect(() => {
    console.log("Fetching report data for:", {
      filialnummer: filiale,
      datum: selectedMonth.format("YYYY-MM-DD"),
    });

    const fetchReportData = async () => {
      try {
        const response = await axiosInstance.get("/scorecard_documentation/documentation/", {
          params: {
            filialnummer: filiale,
            datum: selectedMonth.format("YYYY-MM-DD"),
          },
        });

        console.log("API response:", response);

        if (response.data.results) {
          setReportData(response.data.results);
        }
      } catch (error) {
        console.error("Fehler beim Abrufen der Berichtsdaten:", error);
        if (error.response) {
          console.error("Serverantwort:", error.response.data);
        }
      }
    };

    fetchReportData();
  }, [selectedMonth, filiale]);

  const handlePrevious = () => {
    const newMonth = selectedMonth.subtract(1, 'month').startOf('month');
    setSelectedMonth(newMonth);
  };

  const handleNext = () => {
    const newMonth = selectedMonth.add(1, 'month').startOf('month');
    setSelectedMonth(newMonth);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Bericht für {selectedMonth.format("MMMM YYYY")} - Filiale: {filiale}
      </DialogTitle>
      <DialogContent>
        <Box>
          {reportData.length > 0 ? (
            reportData.map((entry) => (
              <Box key={entry.id} sx={{ marginBottom: 2 }}>
                <Typography variant="h6">Umsatz: {entry.scoreUmsatz}</Typography>
                <Typography variant="body1">{entry.bemerkungUmsatz}</Typography>

                <Typography variant="h6">Kunden: {entry.scoreKunden}</Typography>
                <Typography variant="body1">{entry.bemerkungKunden}</Typography>

                <Typography variant="h6">Kundenbon: {entry.scoreKundenbon}</Typography>
                <Typography variant="body1">{entry.bemerkungKundenbon}</Typography>

                <Typography variant="h6">Leistung: {entry.scoreLeistung}</Typography>
                <Typography variant="body1">{entry.bemerkungLeistung}</Typography>

                <Typography variant="h6">Fluktuation: {entry.scoreFluktuation}</Typography>
                <Typography variant="body1">{entry.bemerkungFluktuation}</Typography>

                <Typography variant="h6">Krankenquote: {entry.scoreKrankenquote}</Typography>
                <Typography variant="body1">{entry.bemerkungKrankenquote}</Typography>

                <Typography variant="h6">Bedarf: {entry.scoreBedarf}</Typography>
                <Typography variant="body1">{entry.bemerkungBedarf}</Typography>

                <Typography variant="h6">Auslastung: {entry.scoreAuslastung}</Typography>
                <Typography variant="body1">{entry.bemerkungAuslastung}</Typography>

                <Typography variant="h6">Wachstum: {entry.scoreWachstum}</Typography>
                <Typography variant="body1">{entry.bemerkungWachstum}</Typography>

                <Typography variant="h6">Verfügbarkeit: {entry.scoreVerfügbarkeit}</Typography>
                <Typography variant="body1">{entry.bemerkungVerfügbarkeit}</Typography>

                <Typography variant="h6">Retoure A: {entry.scoreRetoureA}</Typography>
                <Typography variant="body1">{entry.bemerkungRetoureA}</Typography>

                <Typography variant="h6">Retoure B/C: {entry.scoreRetoureBC}</Typography>
                <Typography variant="body1">{entry.bemerkungRetoureBC}</Typography>

                {entry.bemerkungSonstiges && (
                  <>
                    <Typography variant="h6">Sonstiges</Typography>
                    <Typography variant="body1">{entry.bemerkungSonstiges}</Typography>
                  </>
                )}
              </Box>
            ))
          ) : (
            <Typography variant="body1">
              Keine Daten für diesen Monat verfügbar.
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handlePrevious} color="primary">
          Vorheriger Monat
        </Button>
        <Button onClick={handleNext} color="primary">
          Nächster Monat
        </Button>
        <Button onClick={handleClose} color="primary">
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Summary;
