import React, { useEffect, useState, useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Grid, Typography, Card, CardHeader, CardContent,Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import axiosInstance from '../../../services/axiosInstance';
import { FilterContext } from '../../../provider/FilterProvider';
import BasicLines from '../../charts/LineChart';  // Importiere die BasicLines Komponente
import TableBasic from "../../table/TableBasic";  // Importiere die TableBasic Komponente
import {formatPercentage, roundToDecimals} from "../../../utils/mathStuff";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';



const TAPerformanceLeistung = ({ open, setOpen }) => {
    const { filterState } = useContext(FilterContext);
    const [tableData, setTableData] = useState([]);
    const [chartData, setChartData] = useState([]);  // State für die Diagrammdaten
    const [loading, setLoading] = useState(false); // Loading state

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (filterState.filiale && filterState.datum) {
            fetchTableData();
        }
    }, [filterState.filiale, filterState.datum]);

    const fetchTableData = async () => {
        setLoading(true);
        try {
            const endDate = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
            const startDate = endDate.subtract(6, 'month');

            // API-Aufruf für die Filiale
            const responseFiliale = await axiosInstance.get('scorecards_month/performance-kennzahl/', {
                params: {
                    startdatum: startDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD'),
                    filiale: filterState.filiale?.value,
                }
            });

            // API-Aufruf für das Gesamtunternehmen
            const responseUnternehmen = await axiosInstance.get('scorecards_month/performance-kennzahl-unternehmen/', {
                params: {
                    startdatum: startDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD'),
                }
            });

            if (Array.isArray(responseFiliale.data.results) && Array.isArray(responseUnternehmen.data.results)) {
                const mappedData = responseFiliale.data.results.map((item, index) => {
                    const filialeLeistung = item.leistung;
                    const unternehmenLeistung = responseUnternehmen.data.results[index]?.leistung || 0;

                    // Berechnung der prozentualen Differenz
                    const differenz = unternehmenLeistung 
                        ? ((filialeLeistung - unternehmenLeistung) / unternehmenLeistung * 100).toFixed(2)
                        : '-';

                    return {
                        datum: dayjs(item.datum).format('YYYY.MM'),
                        leistung: roundToDecimals(filialeLeistung, 0),
                        unternehmenLeistung: roundToDecimals(unternehmenLeistung !== 0 ? unternehmenLeistung : '-',0),
                        differenz: differenz !== '-' ? `${differenz}%` : '-',
                    };
                });

                // Setze die Daten für die Tabelle
                setTableData(mappedData);

                // Bereite die Daten für das Diagramm vor
                const chartDataFormatted = responseFiliale.data.results.map((item, index) => ({
                    x: item.datum,
                    y: item.leistung,
                    label: 'Filiale'
                })).concat(responseUnternehmen.data.results.map((item, index) => ({
                    x: item.datum,
                    y: item.leistung,
                    label: 'Unternehmen'
                })));

                // Setze die Daten für das Diagramm
                setChartData(chartDataFormatted);
            }
        } catch (error) {
            console.error('Error fetching table data:', error);
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {field: 'datum', headerName: 'Datum'},
        {field: 'leistung', headerName: 'Leistung Filiale (€)'},
        {field: 'unternehmenLeistung', headerName: 'Leistung Unternehmen (€)'},
        {field: 'differenz', headerName: '% Differenz'},
    ];

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>
                Tiefenanalyse Leistung
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                padding: '0px',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                backdropFilter: 'blur(10px)',
                            }}
                        >
                            <CardHeader
                                title={
                                    <Typography variant="h6" component="div">
Verlauf der  Stundenleistung in den letzen 6 Monaten im Vergleich zum Unternehmensdurchschnit                                    </Typography>
                                }
                                action={
                                    <Tooltip
                                        title={
                                            <>
                                                Leistungsverlauf - Informationen<br /><br />
                                                Dieses Diagramm zeigt den Verlauf der Leistung der ausgewählten Filiale im Vergleich zum Gesamtunternehmen.<br /><br />
                                                <strong>Filiale:</strong> Die dunklere Linie zeigt den Leistung der Filiale über die Zeit.<br />
                                                <strong>Unternehmen:</strong> Die hellere Linie repräsentiert den Leistung des gesamten Unternehmens.<br /><br />
                                                So kannst du Trends und Unterschiede zwischen der Filiale und dem Unternehmen erkennen.
                                            </>
                                        }
                                    >
                                        <IconButton>
                                            <InfoOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    
                                                                    }
                            />
                            <CardContent sx={{ flexGrow: 1 }}>
                                    <BasicLines data={chartData} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                padding: '0px',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                backdropFilter: 'blur(10px)',
                            }}
                        >
                            <CardHeader
                                title={
                                    <Typography variant="h6" component="div">
                                        Letzten 6. Monate im Unternehmensvergleich
                                    </Typography>
                                }
                            />
                            <CardContent sx={{ flexGrow: 1 }}>
                                {loading ? <div>Loading...</div> :
                                    <TableBasic
                                        columns={columns}
                                        data={tableData}
                                    />}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default TAPerformanceLeistung;
