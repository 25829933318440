import React from 'react';
import { Typography, Box, Grid } from '@mui/material';

const PageHeader = ({ title, subtitle, Icon }) => {
    return (
        <Box sx={{ mb: 2 }}>
            <Grid container spacing={2} alignItems="center">
                {Icon && (
                    <Grid item xs={12} sx={{ textAlign: 'left' }}> 
                        <Icon fontSize="large" sx={{ color: 'primary.main' }} />
                        <Typography variant="h1" sx={{ mb: 0, lineHeight: 1 }}>  {/* Reduziere den Abstand hier */}
                            {title}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={8}> 
                    <Typography variant="subtitle1" sx={{ mt: '-5px' }}>  {/* Negativer Margin oben */}
                        {subtitle}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default PageHeader;
