const flattenRoutes = (routes) => {
    const result = [];

    const traverse = (routesArray, parentPath = '') => {
        routesArray.forEach((route) => {
            const fullPath = `${parentPath}${route.path}`;
            result.push(fullPath);
            if (route.children) {
                traverse(route.children, fullPath);
            }
        });
    };

    traverse(routes);
    return result;
};

export default flattenRoutes;
