import React, { useEffect, useState, useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Grid, Typography, Card, CardHeader, CardContent, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import axiosInstance from '../../../services/axiosInstance';
import { FilterContext } from '../../../provider/FilterProvider';
import BasicLines from '../../charts/LineChart';  // Importiere die BasicLines Komponente
import VariableTable from "../../table/VariableTable";  // Importiere die VariableTable-Komponente
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const TAPerformanceKundenbon = ({ open, setOpen }) => {
    const { filterState } = useContext(FilterContext);
    const [tableData, setTableData] = useState([]);
    const [chartData, setChartData] = useState([]);  // State für die Diagrammdaten
    const [loading, setLoading] = useState(false); // Loading state

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (filterState.filiale && filterState.datum) {
            fetchTableData();
        }
    }, [filterState.filiale, filterState.datum]);

    const fetchTableData = async () => {
        setLoading(true);
        try {
            const endDate = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
            const startDate = endDate.subtract(6, 'month');

            // API-Aufruf für die Filiale
            const responseFiliale = await axiosInstance.get('scorecards_month/performance-kennzahl/', {
                params: {
                    startdatum: startDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD'),
                    filiale: filterState.filiale?.value,
                }
            });

            // API-Aufruf für das Gesamtunternehmen
            const responseUnternehmen = await axiosInstance.get('scorecards_month/performance-kennzahl-unternehmen/', {
                params: {
                    startdatum: startDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD'),
                }
            });

            if (Array.isArray(responseFiliale.data.results) && Array.isArray(responseUnternehmen.data.results)) {
                const mappedData = responseFiliale.data.results.map((item, index) => {
                    const filialeKundenbon = item.kundenbon;
                    const unternehmenKundenbon = responseUnternehmen.data.results[index]?.kundenbon || 0;

                    // Berechnung der prozentualen Differenz
                    const differenz = unternehmenKundenbon 
                        ? ((filialeKundenbon - unternehmenKundenbon) / unternehmenKundenbon * 100).toFixed(2)
                        : '-';

                    return {
                        datum: dayjs(item.datum).format('YYYY.MM'),
                        kundenbon: filialeKundenbon.toFixed(2),
                        unternehmenKundenbon: unternehmenKundenbon !== 0 ? unternehmenKundenbon.toFixed(2) : '-',
                        differenz: differenz !== '-' ? `${differenz}%` : '-',
                    };
                });

                // Setze die Daten für die Tabelle
                setTableData(mappedData);

                // Bereite die Daten für das Diagramm vor
                const chartDataFormatted = responseFiliale.data.results.map((item, index) => ({
                    x: item.datum,
                    y: item.kundenbon,
                    label: 'Filiale'
                })).concat(responseUnternehmen.data.results.map((item, index) => ({
                    x: item.datum,
                    y: item.kundenbon,
                    label: 'Unternehmen'
                })));

                // Setze die Daten für das Diagramm
                setChartData(chartDataFormatted);
            }
        } catch (error) {
            console.error('Error fetching table data:', error);
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        { accessorKey: 'datum', header: 'Datum' },
        { accessorKey: 'kundenbon', header: 'Kundenbon Filiale (€)' },
        { accessorKey: 'unternehmenKundenbon', header: 'Kundenbon Unternehmen (€)' },
        { accessorKey: 'differenz', header: '% Differenz' },
    ];

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>
                Tiefenanalyse Kundenbon
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                padding: '0px',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                backdropFilter: 'blur(10px)',
                            }}
                        >
                            <CardHeader
                                title={
                                    <Typography variant="h6" component="div">
                                        Verlauf des Kundenbons in den letzen 6 Monaten im Vergleich zum Unternehmensdurchschnit
                                    </Typography>
                                }
                                action={
<Tooltip
    title={
        <>
            Kundenbonverlauf - Informationen<br /><br />
            Dieses Diagramm zeigt den Verlauf des Kundenbons der ausgewählten Filiale im Vergleich zum Gesamtunternehmen.<br /><br />
            <strong>Filiale:</strong> Die dunklere Linie zeigt den Kundenbon der Filiale über die Zeit.<br />
            <strong>Unternehmen:</strong> Die hellere Linie repräsentiert den Kundenbon des gesamten Unternehmens.<br /><br />
            So kannst du Trends und Unterschiede zwischen der Filiale und dem Unternehmen erkennen.
        </>
    }
>
    <IconButton>
        <InfoOutlinedIcon />
    </IconButton>
</Tooltip>

                                }
                            />
                            <CardContent sx={{ flexGrow: 1 }}>
                                <BasicLines data={chartData} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                padding: '0px',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                backdropFilter: 'blur(10px)',
                            }}
                        >
                            {/* <CardHeader
                                title={
                                    <Typography variant="h6" component="div">
                                        Letzten 6. Monate im Unternehmensvergleich
                                    </Typography>
                                }
                            /> */}
                            <CardContent sx={{ flexGrow: 1 }}>
                                {loading ? <div>Loading...</div> :
                                    <VariableTable
                                        columns={columns}
                                        data={tableData}
                                    />}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default TAPerformanceKundenbon;
