import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { format } from 'date-fns';

const BasicLines = ({ data, title, height }) => {
    const theme = useTheme();  // Zugriff auf das Theme für die Farbauswahl

    // Prepare data for the chart dynamically based on labels
    const series = data.reduce((acc, item) => {
        const xValue = format(new Date(item.x), 'MMMM yyyy');
        if (!acc[item.label]) {
            acc[item.label] = {
                label: item.label,
                data: [],
                color: item.label === 'Vorjahr' ? theme.palette.chart.pink : theme.palette.primary.main,  // Dynamische Farbauswahl
                yAxis: 'y1',  // Beide Werte der gleichen Y-Achse zuweisen
                stack: 'total',  // Beide Werte übereinander stapeln
                area: true  // Optional: Um das Flächen-Diagramm zu erstellen
            };
        }
        acc[item.label].data.push({ x: xValue, y: item.y });
        return acc;
    }, {});

    const uniqueLabels = Object.keys(series);

    // Define a single Y-Axis for both values
    const yAxes = [{
        id: 'y1',
        show: true,
        title: "Wert",
        position: 'left',  // Y-Achse auf der linken Seite
    }];

    return (
        <Box>
            {/* Entfernen Sie den Titel hier, da er bereits in der CardHeader-Komponente angezeigt wird */}
            <LineChart
                xAxis={[{
                    scaleType: 'band',
                    data: Array.from(new Set(data.map(item => format(new Date(item.x), 'MM.yyyy')))),
                    show: true,
                    title: "Monat",
                    tickFormatter: (value) => format(new Date(value), 'MMMM')
                }]}
                yAxis={yAxes}
                series={Object.values(series).map((s) => ({
                    data: s.data.map(d => d.y),
                    color: s.color,
                    yAxisKey: 'y1',
                }))}
                height={height || 400}  // Verwenden Sie das übergebene height-Prop
                margin={{ top: 10, bottom: 30, left: 80, right: 10 }}
                tooltip
            />

            {/* Legende */}
            <Box display="flex" justifyContent="center" mt={2}>
                {uniqueLabels.map((label) => (
                    <Typography key={label} variant="body2" sx={{ marginX: 1, display: 'flex', alignItems: 'center' }}>
                        <span style={{
                            display: 'inline-block',
                            width: 12,
                            height: 12,
                            borderRadius: '50%',
                            backgroundColor: series[label].color,
                            marginRight: 4,
                        }} />
                        {label}
                    </Typography>
                ))}
            </Box>
        </Box>
    );
};

export default BasicLines;
