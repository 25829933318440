import React, { useState, useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { TextField, Box, Dialog, DialogTitle, IconButton } from '@mui/material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';

const VariableTableTree = ({ data, columns, initialGrouping }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [isFullscreen, setIsFullscreen] = useState(false);

    const filteredData = useMemo(() => {
        return data.filter(row =>
            columns.some(column =>
                String(row[column.accessorKey]).toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [data, searchTerm, columns]);

    const toggleFullscreen = () => {
        setIsFullscreen(prev => !prev);
    };

    return (
        <div className="db_styled_table">
            <Box>
                {/* Search Field */}
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <TextField
                        label="Suchen"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        size="small"
                        sx={{
                            '& .MuiInputBase-root': { height: '30px' },
                            '& .MuiInputLabel-root': { fontSize: '0.875rem', top: '-6px' },
                        }}
                    />
                    <IconButton onClick={toggleFullscreen}>
                        <FullscreenIcon />
                    </IconButton>
                </Box>

                {/* Table */}
                <MaterialReactTable
                    columns={columns.filter(col => col.isVisible)}
                    data={filteredData}
                    enableColumnResizing
                    enableStickyHeader
                    enableStickyFooter
                    layoutMode="grid-no-grow"
                    enableGrouping // Aktiviert die Gruppierungsfunktion
                    initialState={{
                        density: 'compact',
                        pagination: { pageIndex: 0, pageSize: 75 },
                        grouping: initialGrouping, // Gruppierung über Props gesteuert
                    }}
                    manualPagination={filteredData.length > 75}
                    enablePagination={filteredData.length > 75}
                    muiTableBodyCellProps={{
                        sx: {
                            '& > tr:first-of-type': {
                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                fontWeight: 'bold',
                            },
                        },
                    }}
                    muiTableBodyRowProps={({ row }) => ({
                        onClick: () => console.log(row.original), // Replace with your row click handler
                        sx: { cursor: 'pointer' },
                    })}
                    enableToolbarInternalActions={false} // Disable internal toolbar actions like column visibility, density, etc.
                    enableGlobalFilter={false} // Disable global search filter
                    enableSorting // Enable sorting toolbar
                    enableColumnActions={false} // Disable column-related actions
                    enableColumnFilters={false} // Disable column filters
                    enableTopToolbar={false} // Disable the top toolbar entirely
                    enableBottomToolbar={false} // Disable the bottom toolbar entirely
                />

                {/* Fullscreen Dialog */}
                <Dialog open={isFullscreen} onClose={toggleFullscreen} maxWidth="xl" fullWidth>
                    <DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={toggleFullscreen}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <MaterialReactTable
                        columns={columns.filter(col => col.isVisible)}
                        data={filteredData}
                        enableColumnResizing
                        enableStickyHeader
                        enableStickyFooter
                        layoutMode="grid-no-grow"
                        enableGrouping // Gruppierung auch im Vollbildmodus aktivieren
                        initialState={{
                            density: 'compact',
                            pagination: { pageIndex: 0, pageSize: 75 },
                            grouping: initialGrouping, // Gruppierung über Props gesteuert
                        }}
                        enableToolbarInternalActions={false} // Disable internal toolbar actions like column visibility, density, etc.
                        enableGlobalFilter={false} // Disable global search filter
                        enableSorting // Enable sorting toolbar
                        enableColumnActions={false} // Disable column-related actions
                        enableColumnFilters={false} // Disable column filters
                        enableTopToolbar={false} // Disable the top toolbar entirely
                        enableBottomToolbar={false} // Disable the bottom toolbar entirely
                    />
                </Dialog>
            </Box>
        </div>
    );
};

export default VariableTableTree;
