import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";


const ThekeSettingsPopup = ({ open, onClose, warengruppen, sectionCounts, onSectionCountChange }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Settings</DialogTitle>
            <DialogContent>
                {warengruppen.map((wg) => (
                    <TextField
                        key={wg.warengruppennummer}
                        label={wg.warengruppe}
                        type="number"
                        value={sectionCounts[wg.warengruppennummer] || 3}
                        onChange={(e) => onSectionCountChange(wg.warengruppennummer, parseInt(e.target.value, 10))}
                        fullWidth
                        margin="normal"
                    />
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ThekeSettingsPopup;
