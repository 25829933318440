import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton } from '@mui/material';
import axiosInstance from '../../services/axiosInstance';
import BigTableCard from '../../components/card/BigTableCard';
import CloseIcon from '@mui/icons-material/Close';

const WarengruppeDetailsPopup = ({ open, onClose, warengruppe, filiale, jahrMonat, ABC }) => {
    const [articleData, setArticleData] = useState([]);
    const [articleColumns, setArticleColumns] = useState([]);

    useEffect(() => {
        if (open && warengruppe) {
            fetchArticleData();
        }
    }, [open, warengruppe]);

    const fetchArticleData = async () => {
        try {
            const response = await axiosInstance.get('scorecard_month_article/produkte-artikel/', {
                params: {
                    filiale: filiale,
                    jahrMonat: jahrMonat,
                    warengruppe: warengruppe,
                    ABC: ABC
                }
            });

            const data = response.data.results.map(item => {
                const verkaufsmenge = parseFloat(item.verkaufsmenge) || 0;
                const retourmenge = parseFloat(item.retourmenge) || 0;
                const retourquote = (retourmenge + verkaufsmenge) > 0
                    ? (retourmenge / (retourmenge + verkaufsmenge)) * 100
                    : 0;

                return {
                    Artikel: item.artikelnummer.artikel,
                    Verkaufsmenge: verkaufsmenge.toFixed(2),
                    Retourmenge: retourmenge.toFixed(2),
                    Retourquote: retourquote.toFixed(2) + "%"
                };
            });

            setArticleData(data);

            setArticleColumns([
                { id: 'Artikel', accessorKey: 'Artikel', header: 'Artikel' },
                { id: 'Verkaufsmenge', accessorKey: 'Verkaufsmenge', header: 'Verkaufsmenge' },
                { id: 'Retourmenge', accessorKey: 'Retourmenge', header: 'Retourmenge' },
                { id: 'Retourquote', accessorKey: 'Retourquote', header: 'Retourquote' },
            ]);

        } catch (error) {
            console.error('Fehler beim Abrufen der Artikeldaten:', error);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Details zur Warengruppe: {warengruppe}

            <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {articleData.length > 0 ? (
                    <BigTableCard
                        title="Artikeldetails"
                        data={articleData}
                        columns={articleColumns}
                    />
                ) : (
                    <Typography variant="body1">Keine Daten verfügbar.</Typography>
                )}
            </DialogContent>
            
        </Dialog>
    );
};

export default WarengruppeDetailsPopup;
