// BigPieChartCardUnternehmen.js
import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Box,
  Typography,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import PieChartFilialtyp from '../../charts/PieChartFilialtyp'; 
import CloseIcon from '@mui/icons-material/Close';

const BigPieChartCardUnternehmen = ({ data, title, metric }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  // Definiere die Farben aus dem Theme
  const COLORS = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.chart.yellow,
    theme.palette.chart.orange,
    theme.palette.chart.red,
    theme.palette.chart.pink,
    theme.palette.chart.purpleLight,
    theme.palette.chart.purpleDark,
  ];

  const sumField = `${metric}Summe`;
  const abweichungField = `${metric}Abweichung`;

  // Berechne den Gesamtwert, um den prozentualen Anteil zu berechnen
  const totalValue = data.reduce((acc, item) => acc + item[sumField], 0);

  // Sortiere die Daten nach dem Summe-Feld in absteigender Reihenfolge
  const sortedData = [...data].sort((a, b) => b[sumField] - a[sumField]);

  // Zeige nur die Top 3 Kategorien an
  const top3Data = sortedData.slice(0, 3);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid item xs={12} md={12}>
      <Paper>
        <Card
          sx={{
            padding: '0px',
          }}
        >
          <CardHeader
            title={
              <Typography variant="h6" component="div">
                {title || `Top 3 Filialtypen: Anteil und Abweichung VJ von ${metric}`}
              </Typography>
            }
          />
          <CardContent sx={{ flexGrow: 1 }}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              {/* PieChart mit allen Kategorien links */}
              {data && data.length > 0 && (
                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                  <PieChartFilialtyp data={sortedData} metric={metric} /> {/* Zeigt alle Filialtypen */}
                </Box>
              )}

              {/* Filialtypen-Liste rechts mit Wachstumswerten und Anteilen */}
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  ml: { md: 3 },
                  width: '100%',
                  maxWidth: '100%',
                  boxSizing: 'border-box',
                  paddingRight: 2
                }}
              >

                {/* Top 3 Liste der Filialtypen und Wachstumswerte */}
                {top3Data.map((item, index) => {
                  const valueAnteil = ((item[sumField] / totalValue) * 100).toFixed(2);
                  return (
                    <Box key={item.filialtyp} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <Box
                        sx={{
                          width: 32,
                          height: 16,
                          backgroundColor: COLORS[index % COLORS.length],
                          mr: 1,
                          borderRadius: '50%',
                        }}
                      />
                      <Typography variant="body2">
                        {item.filialtyp}
                      </Typography>
                      <Typography variant="body2" sx={{ ml: 2, fontWeight: 'normal' }}>
                        Anteil: {valueAnteil}%
                      </Typography>
                      <Typography variant="body2" sx={{ ml: 2, fontWeight: 'normal' }}>
                        VJ: {item[abweichungField].toFixed(2)}%
                      </Typography>
                    </Box>
                  );
                })}

                {/* Drei Punkte zum Öffnen des Dialogs */}
                <Box sx={{ display: 'flex', justifyContent: 'left', mt: 0 }}>
                  <Typography 
                    onClick={handleClickOpen} 
                    sx={{ cursor: 'pointer', color: theme.palette.primary.main, fontWeight: 'bold' }}
                  >
                    ...
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Paper>

      {/* Dialog zum Anzeigen aller Kategorien */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          Tiefenanalyse {metric.charAt(0).toUpperCase() + metric.slice(1)}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            {/* PieChart mit allen Daten */}
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
              <PieChartFilialtyp data={sortedData} metric={metric} /> {/* Alle Daten anzeigen */}
            </Box>

            {/* Tabelle rechts mit Wachstumswerten und Anteilen */}
            <Box sx={{ flex: 1 }}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Filialtyp</TableCell>
                      <TableCell align="right">Anteil (%)</TableCell>
                      <TableCell align="right">Abweichung VJ (%)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedData.map((item, index) => {
                      const valueAnteil = ((item[sumField] / totalValue) * 100).toFixed(2);
                      return (
                        <TableRow key={item.filialtyp}>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Box
                                sx={{
                                  width: 16,
                                  height: 16,
                                  backgroundColor: COLORS[index % COLORS.length],
                                  mr: 1,
                                  borderRadius: '50%',
                                }}
                              />
                              {item.filialtyp}
                            </Box>
                          </TableCell>
                          <TableCell align="right">{valueAnteil}%</TableCell>
                          <TableCell align="right">{item[abweichungField].toFixed(2)}%</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default BigPieChartCardUnternehmen;
